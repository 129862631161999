import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, tap } from 'rxjs';
import { Dpo } from 'src/app/api/models/onboarding/dpo';
import { Company } from './models/company.interface';
import { LoggerService } from '../logger/logger.service';
import { ApiClientOnboardingService } from 'src/app/api/api-client-onboarding.service';

@Injectable({
	providedIn: 'root'
})
export class OnboardingService {
	private readonly loggerName: string = 'OnboardingService';

	private companyName = new BehaviorSubject<string>(null);
	companyName$ = this.companyName.asObservable();

	private companyDpo = new BehaviorSubject<Dpo>(null);
	companyDpo$ = this.companyDpo.asObservable();

	constructor(private apiClientOnboarding: ApiClientOnboardingService,
				private logger: LoggerService) { }

	getDpo(): Observable<Dpo> {
		if (this.companyDpo.value) {
			return this.companyDpo$;
		}
		else {
			return this.apiClientOnboarding.getDpoFromDomain().pipe(
				tap(() => this.logger.info(this.loggerName, 'getDpo() succeeded')),
				tap(res => this.companyDpo.next(res)));
		}
	}
	
	sendDomainCompanyName(companyName?: string): Observable<void> {
		const company: Company = { 'companyName': companyName ?? this.companyName.value };

		return this.apiClientOnboarding.setDomainData(company).pipe(
			tap(() => this.logger.info(this.loggerName, 'setDomainData() succeeded'))
		);
	}
}
