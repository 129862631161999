<p class="mine-title-m member-dialog__title">{{'profile.memberTitle' | content}}</p>

<form class="member-dialog" [formGroup]="addMemberFormGroup">

    <mine-input 
        class="member-dialog__invitee"
        formControlName="inviteeEmail"
        [placeholder]="'profile.memberEmailPlaceholder' | content" 
        [required]="true"
        [error]="addMemberFormGroup.get('inviteeEmail')?.errors?.required ? '' : addMemberFormGroup.get('inviteeEmail')?.errors?.error | content">
        <span mine-input-label>{{'profile.memberEmail' | content}}</span>
    </mine-input>

    <mine-dropdown 
        formControlName="department"
        class="member-dialog__dropdown" 
        [id]="'dropdownMemberDepartment'" [required]="true"
        [placeholder]="'profile.memberDepartmentPlaceholder' | content" 
        [height]="'193px'"
        [options]="options">
        <span mine-dropdown-label>{{'profile.memberDepartment' | content}}</span>
    </mine-dropdown>

    <div class="member-dialog__permission-role-container">
        <p class="member-dialog__permission-role-label">{{'profile.choosePermissionRole' | content}}</p>
        
        @for (role of roleOptions; track $index) {
        <fieldset class="member-dialog__permission-role-fieldset">
            <mine-radio-button
                class="member-dialog__permission-role-btn" 
                [id]="role"
                name="permission-role" 
                formControlName="role"
                [value]="role">
                {{role | userRoleToText}}
                @if (devTicketGroupAssignmentFF && (('company-settings.roleSubText'+role) | content); as subtext) {
                    <span class="member-dialog__sub-text" >{{subtext}}</span>
                }
                    <mine-info-icon class="member-dialog__permission-role-info"
                        [heliText]="role | userRoleToTooltip">
                    </mine-info-icon>
            </mine-radio-button>
        </fieldset>
    }
    </div>

    @if (devTicketGroupAssignmentFF) {
        <mine-info-box class="member-dialog__box" [bgColor]="infoBoxBgColor">
            <svg class="member-dialog__warning-icon" box-icon>
                <use xlink:href="../../../assets/img/sprite.svg#icon-info-outline"></use>
            </svg>
            <p box-content>
                {{'company-settings.dialogInfoBox' | content}}
            </p>
        </mine-info-box>
    }
    
    
    <div class="member-dialog__button-container">

        <mine-button-tertiary 
            (onClick)="cancelClicked()" 
            class="member-dialog__btn-cancel mine-button-primary-lg"
            [label]="'profile.memberBtn1Label' | content">
            <span>{{'profile.memberBtn1' | content}}</span>
        </mine-button-tertiary>

        <mine-button-primary 
            (onClick)="onInviteMember()" 
            class="mine-button-primary-lg"
            [text]="'profile.memberBtn2' | content"
            [disabled]="addMemberFormGroup.invalid"
            [spinner]="spinner">
        </mine-button-primary>
        
    </div>
    
</form>