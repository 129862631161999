import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientHelperService } from './http-client-helper.service';
import { AiAssessmentCustomLabel } from '../ai-assessments/models/ai-assessments.interface';

@Injectable({
  providedIn: 'root'
})
export class BaseApiClientLabelsService {
	protected readonly controller: string = 'assessment/v2/tags';

	constructor(protected httpClientHelper: HttpClientHelperService) { }
  
  getLabels(): Observable<AiAssessmentCustomLabel[]> {
    return this.httpClientHelper.invokeGetAuth<AiAssessmentCustomLabel[]>(`${this.controller}`);
  }

  createLabel(label: AiAssessmentCustomLabel): Observable<AiAssessmentCustomLabel> {
    return this.httpClientHelper.invokePostAuth<AiAssessmentCustomLabel>(`${this.controller}`, label);
  }

  updateLabel(id: number, label: AiAssessmentCustomLabel): Observable<AiAssessmentCustomLabel> {
    return this.httpClientHelper.invokePatchAuth<AiAssessmentCustomLabel>(`${this.controller}/${id}`, label);
  }

  deleteLabel(id: number): Observable<void> {
    return this.httpClientHelper.invokeDeleteAuth<void>(`${this.controller}/${id}`);
  }
}
