import {Injectable} from '@angular/core';
import {PiiSchemaItem} from "../../../../api/models/pii-classifier/pii-classifier.interface";
import {PiiSystemSchemaColumnsEnum} from "../schema-table-header/schema-table-header.enum";
import {SystemSchemaService} from "../../states/system-schema/system-schema.service";
import {DataTypeMatchesFormatPipe} from "../../../../data-types/pipes/data-type-matches-format.pipe";
import { PiiSystemService } from '../../states/pii-system/pii-system.service';

@Injectable()
export class PiiSchemasTableCsvHelper {

  constructor(
    private dataTypeMatchesFormatPipe: DataTypeMatchesFormatPipe,
    private systemSchemaService: SystemSchemaService,
    private piiSystemService: PiiSystemService
  ) { }

  dynamicColumns: string[];

  getValueByKey(schema: PiiSchemaItem, key: PiiSystemSchemaColumnsEnum | string): string {
    const currInstance = this.piiSystemService.getActiveInstance();
    const values: Record<PiiSystemSchemaColumnsEnum, string> = {
      dataType: schema.dataType?.name ?? '',
      matches: `${this.dataTypeMatchesFormatPipe.transform(schema.totalMatches, currInstance?.lastScan?.scanType)}` ?? '0',
      frameworks: schema.frameworks?.map(f => f.text).join(", ") ?? '-'
    };

    if (this.isPredefinedColumn(key)) {
      this.initDynamicColumns();
      const schemaNameIndex = this.dynamicColumns.indexOf(key);
      if (schemaNameIndex !== -1) {
        values[key] = schema.schemaValues[schemaNameIndex]?.toString() ?? '-';
      }
    }

    return values[key];
  }

  initDynamicColumns(): void {
    if (!this.dynamicColumns) {
      this.dynamicColumns = this.systemSchemaService.schemaColumnsValue || [];
    }
  }

  isPredefinedColumn(key: string): boolean {
    return !Object.values(PiiSystemSchemaColumnsEnum).includes(key as PiiSystemSchemaColumnsEnum);
  }
}
