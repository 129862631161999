import { CustomFieldsQuery } from 'src/app/company-settings/state/custom-fields/custom-fields.query';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { map, switchMap, iif, Observable, of } from 'rxjs';

import {
  CustomFieldValue,
  ExtendedDataType,
  IntegrationSystem,
  SystemFunction,
  SystemReviewStatus
} from 'src/app/api/models/systems/systems.interface';
import { SystemsFiltersEnum, SystemStatusEnum } from 'src/app/systems/systems-home/systems-table/systems-filtering/systems-filtering.enum';
import { SystemsColumnKeys } from 'src/app/systems/models/systems-column.enum';
import { FilterCategory } from 'src/app/core/models/filtering.interface';
import { SortDirectionEnum } from 'src/app/shared/sort-direction.enum';
import { SystemsState, SystemsStore } from './systems.store';
import { SystemInstance } from '../models/systems.interface';
import { ConvertPiiTypeToDataTypePipe } from 'src/app/shared/pipes/convert-pii-type-to-data-type.pipe';
import { AssetStateEnum, AssetTypeEnum, DataTypeSource, ReviewStatus, SuggestedStateEnum } from 'src/app/api/models/systems/systems.enum';
import { FeedbackStateEnum } from 'src/app/api/models/feedback/feedback.enum';
import { IntegrationInstanceType } from 'src/app/api/models/integrations/integration-type.enum';
import { DataTypesQuery } from 'src/app/data-types/state/data-types.query';
import { TableCustomView } from 'src/app/api/models/custom-views/custom-views.interface';
import { SystemItemAdditionalData } from '../systems-home/systems-table/systems-table.interface';
import { FieldInputType, FieldUsedInEnum } from 'src/app/api/models/company-settings/custom-fields.enum';
import { CustomFieldV2 } from 'src/app/api/models/company-settings/custom-fields.interface';
import { FeatureFlagQuery } from "../../feature-flag/state/feature-flag.query";
import { FeatureFlags } from "../../api/models/profile/profile-feature-flags.enum";
import { RiskCalulationPipe } from 'src/app/ai-assessments/pipes/risk-calulation.pipe';

@Injectable({ 
	providedIn: 'root' 
})
export class SystemsQuery extends QueryEntity<SystemsState> {

	constructor(
		protected store: SystemsStore,
		private convertPiiTypeToDataTypePipe: ConvertPiiTypeToDataTypePipe,
		private dataTypesQuery: DataTypesQuery,
		private riskCalulation: RiskCalulationPipe,
		private customFieldsQuery: CustomFieldsQuery,
    	private featureFlagQuery: FeatureFlagQuery
	) {
		super(store);
	}

	getSystemsByIds(ids: string[]): SystemInstance[] {
		return this.getAll().filter(system => ids.includes(system.systemId));
	}

	/* eslint-disable */ 
	selectSortedSystems(colKey: SystemsColumnKeys = null, direction: SortDirectionEnum, filters: FilterCategory[] = [], search: string, systemAdditionalData?: Map<string, SystemItemAdditionalData>): Observable<SystemInstance[]> {
		return this.selectAll({
				filterBy: [
					entity => ((
						this.isArchive(filters, entity.isArchived) // filter by isArchived
						&&
						this.compareFrameworks(entity.extendedDataTypes.filter(e => e.state === SuggestedStateEnum.Accepted), filters) // filter by frameworks
						&& 
						this.hasFeedback(filters, entity.generalFeedbackState)
						&&
						this.reviewStatus(filters, entity.reviewStatus)
						&&
						this.filterBySearch(search, entity.name)
						&&
						this.filterByCategories(filters, entity.category)
						&&
						this.filterByCustomFields(filters, entity.customFields)
						&&
						this.filterByFunctions(filters, entity.functions)
						&&
						this.filterByBusinessOwners(filters, entity.owner)
						&&
						this.filterByStakeholders(filters, entity.stakeHolders)
					))
				],
			})
			.pipe(
				map(res => this.filterRequestHandling(res, filters, colKey, direction, systemAdditionalData)) // filter by request handling
			);
	}

	private isArchive(filter: FilterCategory[], isArchived: boolean): boolean {
		const statusFilter = filter.find(f => f.id === SystemsFiltersEnum.Status);
		return !!statusFilter?.options.find(o => o.id === SystemStatusEnum.Archived) ? isArchived : !isArchived;
	}

	private hasFeedback(filter: FilterCategory[], feedback: FeedbackStateEnum): boolean {
		const statusFilter = filter.find(f => f.id === SystemsFiltersEnum.Feedback);
		const statusFeedback = !!statusFilter?.options.find(o => o.id  === feedback);
		return statusFilter?.options?.length > 0 ? statusFeedback : true;
	}

	private reviewStatus(filter: FilterCategory[], reviewStatus: SystemReviewStatus): boolean {
		const statusFilter = filter.find(f => f.id === SystemsFiltersEnum.ReviewStatus);
		const statusReview = !!statusFilter?.options.find(o => o.id  === reviewStatus.status);
		return statusFilter?.options?.length > 0 ? statusReview : true;
	}

	private filterByCategories(filter: FilterCategory[], category: string): boolean {
		const categories = filter.find(f => f.id === SystemsFiltersEnum.Category);
		const hasCategory = !!categories?.options?.find(c => c.id === category);
		return categories?.options?.length > 0 ? hasCategory : true;
	}

	private filterByCustomFields(filter: FilterCategory[], systemCustomFields: CustomFieldValue[]): boolean {
		const customFields = this.customFieldsQuery.getCustomFieldsByType(FieldUsedInEnum.DataSource).filter(field => filter.map(f => f.id).includes(field.id));
		if (!customFields.length) return true;
		
		for (let customField of systemCustomFields) {
			const selectedField = customFields.find(c => c.id === customField.id);
			if (!!selectedField) {
				if (customFields.find(c => c.id === customField.id).inputType === FieldInputType.Text) {
					if (filter.find(f => f.id === customField.id)?.options?.find(o => o.label === customField.values.text && o.selected)?.label === customField.values?.text) {
						return true;
					}
				} else {
					if (filter.find(f => f.id === customField.id)?.options?.find(o => customField.values.optionIds.find(c => c === o.id) && o.selected)) {
						return true;
					}
				}
			}
		}
		return false;
	}

	private filterByFunctions(filter: FilterCategory[], functions: SystemFunction[]): boolean {
		const index = filter?.findIndex(f => f.id == SystemsFiltersEnum.Functions && f.options?.length > 0);
		if (index > -1) {
			// indexOf(f.id.slice(0, f.id.length - '-functions'.length))
			const found = filter[index].options.some(f => functions?.map(f => f.id)?.indexOf(f.id.trim()) >= 0);
			return !!found;
		}
		return true;
	}

	private filterByBusinessOwners(filter: FilterCategory[], owner: string): boolean {
		const owners = filter.find(f => f.id === SystemsFiltersEnum.BusinessOwner);
		const hasOwner = !!owners?.options?.find(c => c.id === owner);
		return owners?.options?.length > 0 ? hasOwner : true;
	}

	private filterByStakeholders(filter: FilterCategory[], stakeHolders: string[]): boolean {
		const stakeholders = filter.find(f => f.id === SystemsFiltersEnum.Stakeholders);
		const hasStakeHolders = !!stakeholders?.options?.find(c => stakeHolders?.includes(c.id));
		return stakeholders?.options?.length > 0 ? hasStakeHolders : true;
	}

	private filterBySearch(search: string, name: string): boolean {
		if (!search) return true;
		if (!name) return false;
		return this.compareStrArrays(search.split(' '), name.split(' '));
	}

	private compareStrArrays(str1: string[], str2: string[]): boolean {
		return (str1.some(str => str2.some(s => !!str && !!s && str.toLowerCase().indexOf(s.toLowerCase()) > -1))) ||
			(str2.some(str => str1.some(s => !!str && !!s && str.toLowerCase().indexOf(s.toLowerCase()) > -1)));
	}

	private compareFrameworks(dataTypes: ExtendedDataType[], filter: FilterCategory[]): boolean {
		const statusFilter = filter.find(f => f.id === SystemsFiltersEnum.Frameworks);
		const userSelectedFrameworks = filter[0]?.options;
		if (!statusFilter || !userSelectedFrameworks || userSelectedFrameworks?.length === 0) {
			return true;
		}
		
		const selectedFrameworks = new Set<string>(userSelectedFrameworks.map(f => f.id));

		const mappedDataTypes = dataTypes.map(d => this.getDataTypeId(d));
		const dataTypesIdsSet = new Set<string>(mappedDataTypes);
		
		const enrichedDataTypes = this.dataTypesQuery.getAll().filter(d => dataTypesIdsSet.has(d.id));
		return !!enrichedDataTypes.some(d => d.frameworks.some(f => selectedFrameworks.has(f.id)));
	}

	private getDataTypeId(d: ExtendedDataType): string {
		if (d.source === DataTypeSource.Manual || d.source === DataTypeSource.Custom) {
			return d.id;
		}
		return this.convertPiiTypeToDataTypePipe.transform(d.id);
	}

	private filterRequestHandling(systems: SystemInstance[], filters: FilterCategory[], colKey: SystemsColumnKeys = null, direction: SortDirectionEnum, systemAdditionalData: Map<string, SystemItemAdditionalData>): SystemInstance[] {
		const flags = this.featureFlagQuery.getMultipleFlags([FeatureFlags.JiraWorkflow]);
		const jiraWorkflowFF = flags.jiraWorkflow;

		let systemsNew = [];
		const rquestHandlingFilter = filters.find(f => f.id === SystemsFiltersEnum.RequestHandling);

		if (!!rquestHandlingFilter && rquestHandlingFilter.options?.length) {
			const filterOptions = rquestHandlingFilter.options.map(options => options.id);

			if (jiraWorkflowFF && filterOptions.includes(IntegrationInstanceType.Workflow)) {
				filterOptions.push(IntegrationInstanceType.Jira);
			}

			const notUsedInDsrId = 'notUsedInDsr';
			systems.forEach(s => {
				let integrationInstances = s.integrationInstances.filter(i => {
					return (filterOptions.includes(notUsedInDsrId) && !i.associatedPrivacyRights?.length) ||
						(filterOptions.includes(i.instanceType) && !!i.associatedPrivacyRights?.length);
				});
				systemsNew.push({ ...s, integrationInstances });
			});
		}
		else {
			systemsNew = systems;
		}

		return systemsNew.sort((a, b) => this.sortSystems(a, b, colKey, direction, systemAdditionalData));
	}

	selectSortedIntegrations(
		colKey: SystemsColumnKeys = null, 
		direction: SortDirectionEnum, 
		filters: FilterCategory[] = [],
		search: string = '',
		systemAdditionalData?: Map<string, SystemItemAdditionalData>
	): Observable<IntegrationSystem[]> {
		return this.selectSortedSystems(colKey, direction, filters, search, systemAdditionalData).pipe(
			map(systems => systems.reduce((arr, system) =>
				system.integrationInstances ? arr.concat(system.integrationInstances) 
				: arr.concat({
					integrationId: system.name,
					systemId: system.systemId
				} as IntegrationSystem), [])
				.filter(Boolean)
			),
			switchMap(integrations => iif(() =>
				(!direction || colKey === null || colKey !== SystemsColumnKeys.InstanceType),
				of(integrations),
				of(this.sortSystemIntegrations(integrations, direction)
			)))
		);
	}

	sortSystemIntegrations(integrations: IntegrationSystem[], direction: SortDirectionEnum): IntegrationSystem[] {
		//keep original sort
		if(!direction){
			return integrations;
		}
		const notEnabled = integrations.filter(i => !i.enabled).sort();
		const enabledSorted = integrations.filter(i=> i.enabled).sort((a, b) => this.sortIntegrationsByInstanceType(a,b,direction));
		return direction === SortDirectionEnum.Asc ? enabledSorted.concat(notEnabled) : notEnabled.concat(enabledSorted);
	}

	getSystemByIntegrationId(integrationId: string): SystemInstance {
		return this.getAll()?.find(system => system.integrationInstances?.map(instance => instance.integrationId?.toLowerCase())?.includes(integrationId?.toLowerCase()));
	}

	getIntegrationInstanceById(integrationId: string): IntegrationSystem {
		const system = this.getSystemByIntegrationId(integrationId);
		return system?.integrationInstances?.find(instance => instance.integrationId === integrationId);
	}
	
	private sortSystems(a: SystemInstance, b: SystemInstance, colKey: SystemsColumnKeys, direction: SortDirectionEnum, systemAdditionalData: Map<string, SystemItemAdditionalData>): number {
		switch (colKey) {
			case SystemsColumnKeys.Category:
				return this.sortSystemsByCategory(a, b, direction);
			case SystemsColumnKeys.System:
				return this.sortSystemsBySystemType(a, b, direction);
			case SystemsColumnKeys.Counter:
				return this.sortSystemsByCounter(a, b, direction);
			case SystemsColumnKeys.Ropa:
				return this.sortSystemsByNumber(a, b, direction);
			case SystemsColumnKeys.Review:
				return this.sortSystemsByReview(a, b, direction);
			case SystemsColumnKeys.DataTypes:
				return this.sortByDataTypesNumber(a, b, direction); 
			case SystemsColumnKeys.Feedback:
				return this.sortSystemsByFeedback(a, b, direction); 
			case SystemsColumnKeys.DataSourceOrigin:
				return this.sortSystemsByDataSourceOrigin(a, b, direction); 
			case SystemsColumnKeys.Locations:
				return this.sortSystemsByLocations(a, b, direction); 
			case SystemsColumnKeys.BusinessOwner:
				return this.sortSystemsByBusinessOwner(a, b, direction); 
			case SystemsColumnKeys.StakeHolders:
				return this.sortSystemsByStakeHolders(a, b, direction); 
			case SystemsColumnKeys.Mitigations:
				return this.sortSystemsByHasMitigations(a, b, direction);
			case SystemsColumnKeys.RiskCalc:
				return this.sortSystemsByRiskCalc(a, b, direction);
			case SystemsColumnKeys.CyberRating:
				return this.sortSystemsByCyberRating(systemAdditionalData.get(a.systemId), systemAdditionalData.get(b.systemId), direction); 
			// default sort for columns without spesific order functions
			case SystemsColumnKeys.InstanceType:
			case SystemsColumnKeys.Frameworks:
			case null: // when colkey provided as null we also want the default sort
				return this.sortSystemsBySystemType(a, b, SortDirectionEnum.Asc);
			case SystemsColumnKeys.Functions:
				return this.sortSystemsByFunctions(a, b, direction);
			case SystemsColumnKeys.ReviewDate:
				return this.sortSystemsByReviewDate(a, b, direction);
			default:
				// sort for custom fields [not in the column keys]
				return this.sortByCustomField(a, b, direction, colKey);
			}
	}

  private sortSystemsByFunctions(a: SystemInstance, b: SystemInstance, direction: SortDirectionEnum): number {
    return direction === SortDirectionEnum.Asc ?
      (a.functions?.length ?? 0) - (b.functions?.length ?? 0) :
      (b.functions?.length ?? 0) - (a.functions?.length ?? 0);
  }

	private sortByCustomField(a: SystemInstance, b: SystemInstance, direction: SortDirectionEnum, customFieldId: string): number {
		const fields = this.customFieldsQuery.getEntity(customFieldId);
		if (fields?.inputType === FieldInputType.Text) {
			return this.sortByCustomFieldText(a, b, direction, customFieldId);
		} else {
			return this.sortByCustomFieldSelect(a, b, direction, customFieldId);
		}
	}

	private sortByCustomFieldText(a: SystemInstance, b: SystemInstance, direction: SortDirectionEnum, customFieldId: string): number {
		const customFieldValueA = a.customFields.find(f => f.id === customFieldId)?.values?.text;
		const customFieldValueB = b.customFields.find(f => f.id === customFieldId)?.values?.text;
		return direction === SortDirectionEnum.Asc ? 
			customFieldValueA?.localeCompare(customFieldValueB) :
			customFieldValueB?.localeCompare(customFieldValueA);
	}

	private sortByCustomFieldSelect(a: SystemInstance, b: SystemInstance, direction: SortDirectionEnum, customFieldId: string): number {
		const customFieldValueA = a.customFields?.find(f => f.id === customFieldId)?.values?.optionIds?.length > 0 ? a.customFields?.find(f => f.id === customFieldId)?.values?.optionIds[0] : null;
		const customFieldValueB = b.customFields?.find(f => f.id === customFieldId)?.values?.optionIds?.length > 0 ? b.customFields?.find(f => f.id === customFieldId)?.values?.optionIds[0] : null;
		return direction === SortDirectionEnum.Asc ? 
			customFieldValueA?.localeCompare(customFieldValueB) :
			customFieldValueB?.localeCompare(customFieldValueA);
	}

	private sortByDataTypesNumber(a: SystemInstance, b: SystemInstance, direction: SortDirectionEnum): number {		
		let recordsA = a.extendedDataTypes.filter(e =>
			e.state === SuggestedStateEnum.Accepted).length;
		
		let recordsB = b.extendedDataTypes.filter(e =>
			e.state === SuggestedStateEnum.Accepted).length;

		return direction === SortDirectionEnum.Asc ?
			recordsA - recordsB :
			recordsB - recordsA;
	}

	private sortSystemsByReview(a: SystemInstance, b: SystemInstance, direction: SortDirectionEnum): number {
		const status = Object.values(ReviewStatus);
		return direction === SortDirectionEnum.Asc ? 
		status.indexOf(a.reviewStatus.status) - status.indexOf(b.reviewStatus.status) :
		status.indexOf(b.reviewStatus.status) - status.indexOf(a.reviewStatus.status);
	}

	private sortSystemsBySystemType(a: SystemInstance, b: SystemInstance, direction: SortDirectionEnum): number {
		const nameA = a.name ?? a.systemType;
		const nameB = b.name ?? b.systemType;
		return direction === SortDirectionEnum.Asc ? 
			nameA.localeCompare(nameB) :
			nameB.localeCompare(nameA);
	}

	private sortSystemsByCategory(a: SystemInstance, b: SystemInstance, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ? 
			a.category?.localeCompare(b.category) :
			b.category?.localeCompare(a.category);
	}

	private sortSystemsByCounter(a: SystemInstance, b: SystemInstance, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ?
			a.connectedAccounts - b.connectedAccounts : 
			b.connectedAccounts - a.connectedAccounts;
	}
	
	private sortSystemsByNumber(a: any, b: any, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ?
			a.processActivityCount - b.processActivityCount : 
			b.processActivityCount - a.processActivityCount;
	}

	private sortIntegrationsByInstanceType(a: any, b: any, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ? 
			a.instanceType?.localeCompare(b.instanceType) :
			b.instanceType?.localeCompare(a.instanceType);
	}

	private sortSystemsByFeedback(a: SystemInstance, b: SystemInstance, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ? 
			a.generalFeedbackState?.localeCompare(b.generalFeedbackState) :
			b.generalFeedbackState?.localeCompare(a.generalFeedbackState);
	}

	private sortSystemsByDataSourceOrigin(a: SystemInstance, b: SystemInstance, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ? 
			a.dataSourceOrigin?.toString().localeCompare(b.dataSourceOrigin.toString()) :
			b.dataSourceOrigin?.toString().localeCompare(a.dataSourceOrigin.toString());
	}

	private sortSystemsByLocations(a: SystemInstance, b: SystemInstance, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ? 
			a.serverLocations?.length - b.serverLocations.length :
			b.serverLocations?.length - a.serverLocations.length;
	}

	private sortSystemsByHasMitigations(a: SystemInstance, b: SystemInstance, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ?
				Number(!!a.vendorRisk?.mitigations) - Number(!!b.vendorRisk?.mitigations) :
				Number(!!b.vendorRisk?.mitigations) - Number(!!a.vendorRisk?.mitigations);
	}

	private sortSystemsByCyberRating(a: SystemItemAdditionalData, b: SystemItemAdditionalData, direction: SortDirectionEnum): number {
		const riskA = a?.riskRating === '-' ? 0 : +a.riskRating;
		const riskB = b?.riskRating === '-' ? 0 : +b.riskRating;
		return direction === SortDirectionEnum.Asc ? 
			(riskA - riskB) :
			(riskB - riskA); 
	}

	private sortSystemsByRiskCalc(a: SystemInstance, b: SystemInstance, direction: SortDirectionEnum): number {
		const riskA = a.vendorRisk?.severity && a.vendorRisk?.likelihood ? this.riskCalulation.transform(a.vendorRisk?.severity, a.vendorRisk?.likelihood) : null;
		const riskB = b.vendorRisk?.severity && b.vendorRisk?.likelihood ? this.riskCalulation.transform(b.vendorRisk?.severity, b.vendorRisk?.likelihood) : null;

		return direction === SortDirectionEnum.Asc ? 
		riskA?.localeCompare(riskB) :
		riskB?.localeCompare(riskA); 
	}

	private sortSystemsByBusinessOwner(a: SystemInstance, b: SystemInstance, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ? 
			a.owner?.localeCompare(b.owner) :
			b.owner?.localeCompare(a.owner);
	}

	private sortSystemsByStakeHolders(a: SystemInstance, b: SystemInstance, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ? 
			a.stakeHolders?.toString()?.localeCompare(b.stakeHolders?.toString()) :
			b.stakeHolders?.toString()?.localeCompare(a.stakeHolders?.toString());
	}

	private sortSystemsByReviewDate(a: SystemInstance, b: SystemInstance, direction: SortDirectionEnum): number {
		const dateA = a.reviewStatus.status === ReviewStatus.Approved && a.reviewStatus?.lastActionDate ? new Date(a.reviewStatus?.lastActionDate).getTime() : 0;
		const dateB = b.reviewStatus.status === ReviewStatus.Approved && b.reviewStatus?.lastActionDate ? new Date(b.reviewStatus?.lastActionDate).getTime() : 0;
		return direction === SortDirectionEnum.Asc ? dateA - dateB : dateB - dateA;
	}

	getSystemByServiceId(serviceId: string): SystemInstance {
		return this.getAll().find(system => system.serviceId === serviceId);
	}

	selectSystemsWithDomain(): Observable<SystemInstance[]> {
		return this.selectAll({
			filterBy: entity => !!entity.serviceId	
		});
	}

	getCountSystemWithDomain(): number {
		return this.getCount(({serviceId}) => !!serviceId);
	}
	
	getCountArchived(): number {
		return this.getAll()
			.filter(system => system.isArchived)
			.reduce((count, system) => count += (system.integrationInstances?.length || 1), 0);
	}

	selectCountNoArchived(): Observable<number> {
		return this.selectAll({
				filterBy: [
					system => !system.isArchived
				],
			}).pipe(
				map(res => res.reduce((count, system) => count += (system.integrationInstances?.length || 1), 0)
			));
	}

	getCountSystemsWithDataTypes(): number {
		return this.getCount(({ extendedDataTypes }) => extendedDataTypes.filter(type => type.state === SuggestedStateEnum.Accepted).length > 0);
	}

	getPrecentageSystemsWithDataTypes(): number {
		return Math.round(this.getCountSystemsWithDataTypes() / this.getCount() * 100);
	}
	
	getPrecentageSystemsWithPa(systemsWithPaCount: number): number {
		return Math.round(systemsWithPaCount / this.getCount() * 100);
	}

	selectSystemsWithDataType(dataTypeId: string): Observable<SystemInstance[]> {
		return this.selectAll({
			filterBy: entity => !entity.isArchived && 
				(!!entity.extendedDataTypes?.find(d => (d.source === DataTypeSource.Manual || d.source === DataTypeSource.Custom) && d.id === dataTypeId && d.state === SuggestedStateEnum.Accepted) ||
				!!entity.extendedDataTypes?.find(d => this.isPiiDiscovered(d, dataTypeId))),
		});
	}

	private isPiiDiscovered(d: ExtendedDataType, dataTypeId: string): boolean {
		return d.source === DataTypeSource.PIIDIscovered && this.convertPiiTypeToDataTypePipe.transform(d.id) === dataTypeId && d.state === SuggestedStateEnum.Accepted;
	}

	selectAllSystemsWithDataTypes(): Observable<SystemInstance[]> {
		return this.selectAll({
			filterBy: entity => !entity.isArchived && !!entity.extendedDataTypes?.find(dataType => dataType.state === SuggestedStateEnum.Accepted)
		});
	}

	getAllSystems(systemIds?: string[]): SystemInstance[] {
		return this.getAll().filter(entity => 
			!entity.isArchived && 
			(!systemIds || systemIds.includes(entity.systemId))
		);
	}

	selectSystemsWithoutDataTypes(): Observable<SystemInstance[]> {
		return this.selectAll({
			filterBy: entity => !entity.isArchived && !entity.extendedDataTypes?.find(dataType => dataType.state === SuggestedStateEnum.Accepted),
		});
	}

	selectSystemsByType(systemTypes: Set<string>): Observable<SystemInstance[]> {
		return this.selectAll({
			filterBy: entity => !entity.isArchived && systemTypes.has(entity.systemId),
		});
	}

	selectSystemsWithPiiEnabled(): Observable<SystemInstance[]> {
		return this.selectAll({
			filterBy: entity => !entity.isArchived && entity.integrationInstances.filter(i => i.piiEnabled)?.length > 0
		});
	}

	selectManyUnarchiveSystems(systemsIds: Set<string>): Observable<SystemInstance[]> {
		return this.selectAll({
			filterBy: entity => !entity.isArchived && systemsIds.has(entity.systemId)
		});
	}

	selectIntegrationsWithPiiEnabled(systemId: string): Observable<IntegrationSystem[]> {
		return this.selectEntity(systemId, system => system.integrationInstances.filter(i => i.piiEnabled));
	}

	selectIntegrationsUsedInDsr(systemId: string): Observable<IntegrationSystem[]> {
		return this.selectEntity(systemId, system => system.integrationInstances.filter(i => i.enabled));
	}

	selectSystemsWithProcessingActivities(): Observable<SystemInstance[]> {
		return this.selectAll({
			filterBy: entity => entity.processActivities?.length > 0
		});
	}

	selectAllSystems(includeArchived = false): Observable<SystemInstance[]> {
		return this.selectAll({
			filterBy: entity => includeArchived ? true : !entity.isArchived,
		});
	}

	getAccountsConnected(systemId: string): number {
		return this.getEntity(systemId)?.connectedAccounts;
	}

	selectFriendlyPath(systemId: string): Observable<string> {
		return this.selectEntity(systemId, ({ pathFriendlyId }) => pathFriendlyId);
	}

	getFriendlyPath(systemId: string): string {
		return this.getEntity(systemId)?.pathFriendlyId;
	}

	getDataTypeRecords(systemId: string, dataTypeId: string): number {
		return this.getEntity(systemId).extendedDataTypes.find(dataType => dataType.id === dataTypeId).numOfRecords;
	}

	getSystemDataTypes(systemId: string): ExtendedDataType[] {
		return this.getEntity(systemId).extendedDataTypes.filter(dataType => dataType.state === SuggestedStateEnum.Accepted);
	}

	selectCountStatusInstances(status: ReviewStatus): Observable<number> {
		return this.selectAll({filterBy: entity => entity.reviewStatus.status === status}).pipe(
			map(res => this.getInstancesCount(res))
		);
	}

	selectCountFeedbackInstances(): Observable<number> {
		return this.selectAll({filterBy: entity => entity.generalFeedbackState !== FeedbackStateEnum.None}).pipe(
			map(res => this.getInstancesCount(res))
		);
	}

	selectCountFeedbackState(state: FeedbackStateEnum): Observable<number> {
		return this.selectCount( ({ generalFeedbackState }) => generalFeedbackState === state);
	}

	selectCountAllInstances(): Observable<number> {
		return this.selectAll().pipe(
			map(res => this.getInstancesCount(res))
		);
	}

	private getInstancesCount(systems: SystemInstance[]): number {
		return systems.reduce((acc, curr) => {
			return acc + curr.integrationInstances?.length;
		}, 0);
	}

	selectCountDsrEnabled(): Observable<number> {
		return this.selectAll().pipe(
			map(systems => this.getDsrEnabledCount(systems))
		);
	}

	private getDsrEnabledCount(systems: SystemInstance[]): number {
		return systems.reduce((acc, curr) => {
			const enabledIntegrationInstances = curr.integrationInstances.filter(i => !!i.associatedPrivacyRights?.length);
			return acc + enabledIntegrationInstances.length;
		  }, 0);
	}

	getNameById(id: string): string {
		return this.getEntity(id).name;
	}

	checkForManualInstances(): boolean {
		const systems = this.getAll();
		return systems.some((system) => this.hasManualInstance(system));
	}

	private hasManualInstance(system: SystemInstance): boolean {
		return system.integrationInstances.some((instance) => instance.instanceType === IntegrationInstanceType.Manual && instance.enabled);
	}

	selectCountByCustomView(customView: TableCustomView): Observable<number> {
		const customViewFilters = customView.filters;
		return this.selectSortedSystems(null, SortDirectionEnum.Default, customViewFilters, '').pipe(
			map(res => this.getInstancesCount(res)),
		);
	}

	getUniqueValuesPerCustomFieldIdLegacy(customFieldId: string): string[] {
		const set = new Set(this.getAll().map(system => system.customFields?.find(cf => cf.id === customFieldId)?.value));
		return Array.from(set).filter(Boolean).sort((a, b) => a.localeCompare(b));
	}

	getUniqueValuesPerCustomFieldId(customField: CustomFieldV2): string[][] {
		let valuesArray: string[][];

		if (customField.inputType === FieldInputType.Text) {
			let valuesMap = new Map<string, string>(); // [id, value]
			this.getAll().forEach(system => {
				const value = system.customFields?.find(cf => cf.id === customField.id)?.values?.text;
				if (value) {
					valuesMap.set(value, value);
				}
			});
			valuesArray = Array.from(valuesMap);
		} else {
			const selectArray = this.getAll().map(system => system.customFields?.find(cf => cf.id === customField.id)?.values?.optionIds).flat().filter(Boolean);
			const set = new Set(selectArray);
			valuesArray = Array.from(set).map(id => {
				return [id, this.customFieldsQuery.getEntity(customField.id).inputData.inputOptions.find(o => o.id === id)?.name];
			});
		}

		return valuesArray.sort((a, b) => a[1]?.localeCompare(b[1]));
	}

	getUniqueSystemCategories(): string[] {
		const set = new Set(this.getAll().map(system => system.category));
		return Array.from(set).filter(Boolean).sort((a, b) => a.localeCompare(b));
	}

	selectAiSystems(systemIds: string[] = []): Observable<SystemInstance[]> {
		const flags = this.featureFlagQuery.getMultipleFlags([FeatureFlags.TaggingDataSourcesAsAI]);

		const taggingDataSourcesAsAIFF = flags.taggingDataSourcesAsAI;

		if(taggingDataSourcesAsAIFF) {
			return this.selectAll({
				filterBy: entity => systemIds.includes(entity.systemId) || entity.aiData?.assetType.some(at => 
					[AssetTypeEnum.Vendor, AssetTypeEnum.DeveloperTool].includes(at.type) && at.state === AssetStateEnum.InList)
			}); 
		}

		return this.selectAll({
			filterBy: entity => !!entity.aiData
		});
	}

	selectAiVendors(): Observable<SystemInstance[]> {
		return this.selectAll({
			filterBy: entity => entity.aiData?.assetType?.some(asset => asset.type === AssetTypeEnum.Vendor)
		});
	}

	selectAiDeveloperTools(): Observable<SystemInstance[]> {
		return this.selectAll({
			filterBy: entity => entity.aiData?.assetType?.some(asset => asset.type === AssetTypeEnum.DeveloperTool)
		});
	}

	getUniqueBusinessOwners(): string[] {
		const set = new Set(this.getAll().map(system => system.owner));
		return Array.from(set).filter(Boolean).sort((a, b) => a.localeCompare(b));
	}

	getUniqueStakeholders(): string[] {
		const set = new Set(this.getAll().reduce((stakeholders, system) => stakeholders.concat(system.stakeHolders ?? []), []));
		return Array.from(set).filter(Boolean).sort((a, b) => a.localeCompare(b));
	}

	filterSystemsByAssetType(assetType: AssetTypeEnum, search: string = ''): Observable<SystemInstance[]> {
		return this.selectAll().pipe(
			map(systems =>
				systems.filter(system =>
					// Case 1: No aiData exists
					!system.aiData ||
					// Case 2: aiData exists, but no assetType of the given type exists
					!system.aiData.assetType?.some(asset => asset.type === assetType) ||
					// Case 3: aiData exists and assetType exists but is NOT InList
					system.aiData.assetType.some(asset => asset.type === assetType && asset.state !== AssetStateEnum.InList)
				).filter(system =>
					// Apply search filter
					!search.trim() || system.name?.toLowerCase().includes(search.toLowerCase())
				)
			)
		);
	}

	searchSystems(search: string = ''): Observable<SystemInstance[]> {
		return this.selectAll().pipe(
			map(systems =>
				systems.filter(system =>
					!search.trim() || system.name?.toLowerCase().includes(search.toLowerCase())
				)
			)
		);
	}
}
