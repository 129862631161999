import { inject, InjectionToken } from '@angular/core';
import { PaginatorPlugin } from '@datorama/akita';
import { UnusedEmployeesQuery } from './unused-employees.query';

export const UNUSED_EMPLOYEES_PAGINATOR = 
new InjectionToken('UNUSED_EMPLOYEES_PAGINATOR', {
  providedIn: 'root',
  factory: () => {
    const unusedEmployeesQuery = inject(UnusedEmployeesQuery);
    return new PaginatorPlugin(unusedEmployeesQuery)
            .withControls()
            .withRange();
  }
});