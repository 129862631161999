import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest, EMPTY, Observable, catchError, filter, map, shareReplay, switchMap, iif, first, of } from 'rxjs';

import { LoggerService } from 'src/app/logger/logger.service';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { SystemsQuery } from 'src/app/systems/state/systems.query';
import { ProcessingActivity } from '../api/models/processing-activities/processing-activities.interface';
import { VendorManagementQuery } from '../vendor-management/vendor-managment.query';
import { VendorManagerService } from 'src/app/vendor-management/services/vendor-manager.service';
import { DashboardRequestHandlingSource, DashboardSourceRequiringReview } from './models/dashboard.interface';
import { IntegrationSystem } from '../api/models/systems/systems.interface';
import { IntegrationInstanceType } from '../api/models/integrations/integration-type.enum';
import { SystemInstance } from '../systems/models/systems.interface';
import { DataTypesService } from '../data-types/services/data-types.service';
import { FrameworksService } from '../data-types/services/frameworks.service';
import { DataTypesTableItem } from '../data-types/data-types-table/data-types-table.interface';
import { DashboardSourceRequiringReviewEnum } from './models/dashboard-source-requiring-review.enum';
import { Policy } from '../api/models/policies/policies.interface';
import { EmployeesQuery } from '../employees/state/employees.query';
import { PoliciesQuery } from '../policies/state/policies.query';
import { PoliciesService } from '../policies/state/policies.service';
import { ApiClientTicketService } from '../api/api-client-ticket.service';
import { RiskRatingEnum } from '../vendor-management/models/risk-rating.enum';
import { CountriesHelper } from '../requests/request-form/countries-helper';
import { SortDirectionEnum } from '../shared/sort-direction.enum';
import { SuggestedStateEnum } from '../api/models/systems/systems.enum';
import { RequestsStatsEnum } from '../api/models/requests/requests-stats.enum';
import { RequestsStatsCountOpenResponse, RequestsStatsDistributionResponse } from '../api/models/requests/requests.interface';
import { AiAssessmentsService } from '../ai-assessments/services/ai-assessments.service';
import { AiAssessmentInstance } from '../api/models/ai-assessments/ai-assessments.interface';
import { AiAssessmentTemplateEnum } from '../ai-assessments/models/ai-assessments.enum';
import { AiAssessmentEntityTypeEnum } from '../api/models/ai-assessments/ai-assessments.enum';
import { FeatureFlags } from '../api/models/profile/profile-feature-flags.enum';
import { FeatureFlagQuery } from '../feature-flag/state/feature-flag.query';

import * as dayjs from 'dayjs';
import { LookerDashboardTypeEnum } from './models/dashboard.enums';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiClientDashboardService } from '../api/api-client-dashboard.service';

@Injectable({
	providedIn: 'root'
})
export class DashboardService {
	private readonly loggerName: string = 'DashboardService';

	constructor(
		private destroyRef: DestroyRef,
		private systemsQuery: SystemsQuery,
		private vendorManager: VendorManagerService,
		private vendorManagementQuery: VendorManagementQuery,
		private apiClientTicketService: ApiClientTicketService,
		private employeesQuery: EmployeesQuery,
		private frameworksService: FrameworksService,
		private dataTypesService: DataTypesService,
		private policiesService: PoliciesService,
		private policiesQuery: PoliciesQuery,
		private contentPipe: ContentPipe,
		private logger: LoggerService,
		private aiAssessmentsService: AiAssessmentsService,
		private featureFlagQuery: FeatureFlagQuery,
		private apiClientDashboardService: ApiClientDashboardService,
		private sanitizer: DomSanitizer
	) { }

	generateDashboardUrl(type: LookerDashboardTypeEnum): Observable<SafeResourceUrl> {
		let url$: Observable<string>;
	
		switch(type) {
		  case LookerDashboardTypeEnum.DSR:
			url$ = this.apiClientDashboardService.generateDashboardUrl();
			break;
		  case LookerDashboardTypeEnum.Risk:
			url$ = this.apiClientDashboardService.generateRiskEmbedDashboardEmbedUrl();
			break;
		  case LookerDashboardTypeEnum.Compliance:
			url$ = this.apiClientDashboardService.generateAssessmentsDashboardUrl();
			break;
		  default:
			return EMPTY;
		}
	
		return url$.pipe(
		  map(url => this.sanitizer.bypassSecurityTrustResourceUrl(url))
		);
	}

	private get systems$(): Observable<SystemInstance[]> {
		return this.systemsQuery.selectLoading().pipe(
			filter(loading => !loading),
			switchMap(() => this.systemsQuery.selectAll()),
			map(systems => systems.filter(sys => !sys.isArchived)),
			shareReplay(),
			catchError(error => {
				this.logger.error(this.loggerName, error.message);
				return EMPTY;
			}),
			takeUntilDestroyed(this.destroyRef)
		);
	}

	private get systemInstances$(): Observable<IntegrationSystem[]> {
		return this.systemsQuery.selectLoading().pipe(
			filter(loading => !loading),
			switchMap(() => this.systemsQuery.selectSortedIntegrations(null, SortDirectionEnum.Asc, [])),
			shareReplay(),
			catchError(error => {
				this.logger.error(this.loggerName, error.message);
				return EMPTY;
			}),
			takeUntilDestroyed(this.destroyRef)
		);
	}

	private get employeesCount$(): Observable<number> {
		return this.employeesQuery.selectLoading().pipe(
			filter(loading => !loading),
			switchMap(() => this.employeesQuery.selectCount(emp => !emp.invitationPending)),
			shareReplay(),
			catchError(error => {
				this.logger.error(this.loggerName, error.message);
				return EMPTY;
			}),
			takeUntilDestroyed(this.destroyRef)
		);
	}

	private get policies$(): Observable<Policy[]> {
		return this.policiesQuery.selectLoading().pipe(
			filter(loading => !loading),
			switchMap(() => this.policiesQuery.selectAll()),
			shareReplay(),
			catchError(error => {
				this.logger.error(this.loggerName, error.message);
				return EMPTY;
			}),
			takeUntilDestroyed(this.destroyRef)
		);
	}

	selectTotalPoliciesCount(): Observable<number> {
		return this.policies$.pipe(
			map(policies => policies?.filter(p => !!p.enabled).length ?? 0)
		);
	}

	selectTotalDataSourcesCount(): Observable<number> {
		return this.systemInstances$.pipe(
			map(instances => instances.length ?? 0)
		);
	}

	selectDataSourcesWithDataTypesCount(): Observable<number> {
		return this.selectSystemInstancesWithDataTypes().pipe(
			map(instances => instances?.length ?? 0)
		);
	}

	selectSystemInstancesWithDataTypes(): Observable<IntegrationSystem[]> {
		return this.systemInstances$.pipe(
			map(instances => instances.filter(instance => !!this.systemsQuery.getEntity(instance.systemId)?.extendedDataTypes?.filter(e => e.state === SuggestedStateEnum.Accepted)?.length))
		);
	}

	selectDataSourcesUsedInRequestHandling(): Observable<IntegrationSystem[]> {
		return this.systemInstances$.pipe(
			map(instances => instances.filter(instance => instance.enabled))
		);
	}

	selectRequestsCounters(): Observable<number[]> {
		return this.apiClientTicketService.getStats(RequestsStatsEnum.OpenTickets).pipe(
			map((res: RequestsStatsCountOpenResponse) => ([res.openDeletion, res.openCopy, res.openOverThirtyDays])),
		);
	}

	selectTotalEmployeesCount(): Observable<number> {
		return this.employeesCount$;
	}

	selectRequestsByLocationCountMap(): Observable<Map<string, number>> {
		return this.apiClientTicketService.getStats(RequestsStatsEnum.CountryDistribution).pipe(
			map((res: RequestsStatsDistributionResponse) => this.getCountryCodeMap(res.distribution))
		);
	}

	private getCountryCodeMap(distribution: Object): Map<string, number> {
		const map = new Map<string, number>();
		const countryCodes = Object.keys(distribution);
		const continentsMap = CountriesHelper.getContinentsMap();

		for (let code of countryCodes) {
			let continent = 'Unknown';
			const country = CountriesHelper.getCountryNameByCode(code);
			for (let [k, v] of continentsMap.entries()) {
				if (v.find(c => c === country)) {
					continent = k;
					break;
				}
			}
			if (map.has(continent)) {
				map.set(continent, map.get(continent) + distribution[code]);
			}
			else {
				map.set(continent, distribution[code]);
			}
		}

		return map;
	}

	selectProcessingActivitiesCount(): Observable<number> {
		return this.featureFlagQuery.selectLoading().pipe(
			filter(loading => !loading),
			switchMap(() => this.featureFlagQuery.selectFlag(FeatureFlags.DevAIAssessmentsDashboard)),
			first(),
			switchMap(ff => iif(() => ff, this.aiAssessmentsService.selectCountByType(AiAssessmentTemplateEnum.PA),
				of(0),
			)),
			shareReplay(),
			catchError(error => {
				this.logger.error(this.loggerName, error.message);
				return EMPTY;
			})
		);
	}

	selectRequestHandlingUsage(): Observable<Map<DashboardRequestHandlingSource, number>> {
		return combineLatest([
			this.selectTotalDataSourcesCount(),
			this.selectDataSourcesUsedInRequestHandling()
		]).pipe(
			map(data => this.getRequestHandlingUsageMap(data[0], data[1])),
		);
	}

	private getRequestHandlingUsageMap(
		totalDataSourcesCount: number,
		dataSourcesUsedInRequestHandling: IntegrationSystem[]
	): Map<DashboardRequestHandlingSource, number> {
		let count = 0;
		const sourcesUsedInRequestHandling: DashboardRequestHandlingSource[] = this.contentPipe.transform('dashboard.sourcesUsedInRequestHandling');
		const map = new Map<DashboardRequestHandlingSource, number>();

		// Check if >0 data sources in inventory & no data sources are used in request handling
		if (totalDataSourcesCount > 0 && !dataSourcesUsedInRequestHandling?.length) {
			map.set({
				text: this.contentPipe.transform('dashboard.notUsedInDsr'),
				color: '#F4F4F4',
			} as DashboardRequestHandlingSource, totalDataSourcesCount ?? 1);
			return map;
		}

		for (let source of sourcesUsedInRequestHandling) {
			switch (source.type) {
				case IntegrationInstanceType.Manual:
				case IntegrationInstanceType.Automated:
				case IntegrationInstanceType.Workflow:
					count = dataSourcesUsedInRequestHandling.filter(s => s.instanceType === source.type)?.length ?? 0;
					if (count) {
						map.set(source, count);
					}
					break;

				default:
					count = totalDataSourcesCount - dataSourcesUsedInRequestHandling?.length;
					if (count) {
						map.set(source, count);
					}
					break;
			}
		}

		return map;
	}

	selectSystemsProcessingActivitiesMap(): Observable<Map<string, number>> {
		return this.featureFlagQuery.selectLoading().pipe(
			filter(loading => !loading),
			switchMap(() => this.featureFlagQuery.selectFlag(FeatureFlags.DevAIAssessmentsInventoryTable)),
			first(),
			switchMap(ff => iif(() => ff, this.aiAssessmentsService.selectAssessments().pipe(
				map((assessments: Map<string, AiAssessmentInstance>) => this.getSystemsPaAssessments(assessments)),
			),
				this.systems$.pipe(
					map(systems => this.getSystemsProcessingActivitiesMap(systems))
				)))
		);
	}

	private getSystemsProcessingActivitiesMap(systems: SystemInstance[]): Map<string, number> {
		const systemsWithPa = new Map<string, number>();
		systems.forEach(s => {
			if (s.processActivityCount) {
				systemsWithPa.set(s.systemId, s.processActivityCount);
			}
		});
		return systemsWithPa;
	}

	private getSystemsPaAssessments(assessments: Map<string, AiAssessmentInstance>): Map<string, number> {
		const systemsWithPa = new Map<string, number>();

		assessments.forEach(assessment => {
			if (assessment.templateType === AiAssessmentTemplateEnum.PA) {
				assessment.relatedEntities?.forEach(entity => {
					if (entity.type === AiAssessmentEntityTypeEnum.System) {
						const systemId = entity.id;
						systemsWithPa.set(systemId, (systemsWithPa.get(systemId) || 0) + 1);
					}
				});
			}
		});

		return systemsWithPa;
	}

	selectDataTypesRequiringReviewMap(): Observable<Map<DashboardSourceRequiringReview, SystemInstance[]>> {
		return this.aiAssessmentsService.selectLoading().pipe(
			filter(loading => !loading),
			switchMap(() => this.systems$),
			map(systems => this.initDataTypesRequiringReviewMap(this.aiAssessmentsService.getPaAssessmentsAsProcessingActivities()() as ProcessingActivity[], systems))
		);
	}

	private initDataTypesRequiringReviewMap(activities: ProcessingActivity[], systems: SystemInstance[]): Map<DashboardSourceRequiringReview, SystemInstance[]> {
		const systemsReqRev: DashboardSourceRequiringReview[] = this.contentPipe.transform('dashboard.dataTypesRequiringReview');
		const systemsReqRevMap = new Map<DashboardSourceRequiringReview, SystemInstance[]>();

		for (let item of systemsReqRev) {
			switch (item.name) {
				case DashboardSourceRequiringReviewEnum.MissingDataTypes:
					systemsReqRevMap.set(item, systems.filter(system => !system.extendedDataTypes?.filter(e => e.state === SuggestedStateEnum.Accepted)?.length));
					break;

				case DashboardSourceRequiringReviewEnum.TypeNotFoundInActivities:
					systemsReqRevMap.set(item, systems.filter(system => this.isSystemTypesNotFoundInActivities(system, activities)));
					break;

				default:
					break;
			}
		}

		return systemsReqRevMap;
	}

	private isSystemTypesNotFoundInActivities(system: SystemInstance, activities: ProcessingActivity[]): boolean {
		const systemDataTypesIds = this.dataTypesService.getExtendedDataTypes(system.extendedDataTypes?.filter(e => e.state === SuggestedStateEnum.Accepted)).map(d => d.id);
		activities = activities.filter(a => a.state === SuggestedStateEnum.Accepted);

		const filteredActivities = activities.filter(a =>
			Object.keys(a.systems).includes(system.systemId)
		);

		const dataTypeIds = filteredActivities.map(activity => {
			const extendedDataTypes = Object.values(activity.systems)
				.flatMap(s => s.extendedDataTypes?.filter(e => e.state === SuggestedStateEnum.Accepted) || []);
			return this.dataTypesService.getExtendedDataTypes(extendedDataTypes).map(d => d.id);
		});

		const uniqueDataTypeIds = Array.from(new Set(dataTypeIds.flat()));

		let systemActivitiesDataTypesIds = uniqueDataTypeIds;
		const systemActivitiesDataTypes = Array.from(new Set([].concat.apply([], systemActivitiesDataTypesIds)));

		return !systemActivitiesDataTypes?.length ? false : !systemDataTypesIds.every(dataType => systemActivitiesDataTypes?.includes(dataType));
	}

	selectSystemsRequiringReviewMap(): Observable<Map<DashboardSourceRequiringReview, SystemInstance[]>> {
		return this.systems$.pipe(
			map(systems => this.getSystemsRequiringReviewMap(systems))
		);
	}

	private getSystemsRequiringReviewMap(systems: SystemInstance[]): Map<DashboardSourceRequiringReview, SystemInstance[]> {
		const systemsReqRev: DashboardSourceRequiringReview[] = this.contentPipe.transform('dashboard.sourcesRequiringReview').filter(s => s.name !== 'missingCategories');
		const systemsReqRevMap = new Map<DashboardSourceRequiringReview, SystemInstance[]>();

		for (let item of systemsReqRev) {
			switch (item.name) {
				case DashboardSourceRequiringReviewEnum.MissingDataTypes:
					systemsReqRevMap.set(item, systems.filter(system => !system.extendedDataTypes?.filter(e => e.state === SuggestedStateEnum.Accepted)?.length));
					break;

				case DashboardSourceRequiringReviewEnum.MissingActivities:
					systemsReqRevMap.set(item, systems.filter(system => !system.processActivityCount));
					break;

				case DashboardSourceRequiringReviewEnum.MissingEmployees:
					const filteredSystems = systems.filter(system => {
						const systemUsage = system.systemUsage.manuallySet ? system.systemUsage.score : system.systemUsage.discoveryScore;
						return system.connectedAccounts && systemUsage < 0.35;
					});
					systemsReqRevMap.set(item, filteredSystems);
					break;

				case DashboardSourceRequiringReviewEnum.LowCyberPosture:
					systemsReqRevMap.set(item,
						systems.filter(system => [RiskRatingEnum.Bad, RiskRatingEnum.Poor].includes(
							this.vendorManager.getCyberPosture(+(this.vendorManagementQuery.getEntity(system.systemId)?.riskRating)))
						)
					);
					break;

				default:
					break;
			}
		}

		return systemsReqRevMap;
	}

	selectRequestsByStatusMap(): Observable<Map<string, number>> {
		return this.apiClientTicketService.getStats(RequestsStatsEnum.StateDistribution).pipe(
			map((res: RequestsStatsDistributionResponse) => this.getRequestsByStatusMap(res.distribution))
		);
	}

	private getRequestsByStatusMap(distribution: Object): Map<string, number> {
		const map = new Map<string, number>();
		for (let [status, count] of Object.entries(distribution)) {
			map.set(status, count);
		}
		return map;
	}

	selectIncomingRequestsOvertimeMap(): Observable<Map<string, number>> {
		return this.apiClientTicketService.getStats(RequestsStatsEnum.DateDistribution).pipe(
			map((res: RequestsStatsDistributionResponse) => this.getIncomingRequestsOvertimeyMap(res.distribution))
		);
	}

	private getIncomingRequestsOvertimeyMap(distribution: Object): Map<string, number> {
		// Convert object to array of key-value pairs
		const entries = Object.entries(distribution);

		// Sort the array by dates
		entries.sort(([dateA], [dateB]) => {
			const date1 = new Date(dateA);
			const date2 = new Date(dateB);
			return date1.getTime() - date2.getTime();
		});

		let response: Map<string, number> = new Map<string, number>();
		for (const [date, value] of entries) {
			response.set(`${dayjs(date).format('DD')} ${dayjs(date).format('MMM')}`, value);
		}
		return response;
	}

	selectDataTypesMap(): Observable<Map<string, DataTypesTableItem>> {
		return this.dataTypesService.selectDataTypesTableData(false);
	}

	selectTotalDataTypesCount(): Observable<number> {
		return this.systems$.pipe(
			map(systems => systems.reduce((sum, system) => sum + this.dataTypesService.getExtendedDataTypes(system.extendedDataTypes?.filter(e => e.state === SuggestedStateEnum.Accepted))?.length, 0))
		);
	}

	selectFrameworks(): Observable<Map<string, SystemInstance[]>> {
		return this.frameworksService.selectFrameworkSystemsMap();
	}

	selectPolicyViolationsMap(): Observable<Map<string, SystemInstance[]>> {
		return this.policiesService.selectPolicyViolationsMap();
	}
}