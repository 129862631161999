import { AppModule } from "src/app/app.module";
import { MineSort } from "../mine-sort/mine-sort.interface";
import { SortDirectionEnum } from "../sort-direction.enum";
import { TableState } from "./table-state.interface";
import { TableStateService } from "./table-state.service";
import { TableElement, TablesEnum } from "./table-state.enum";

import { EmployeesTableColumnKeys } from "src/app/employees/employees-managment/employees-table/employees-table.enum";
import { ImpactAssessmentTableHeaderEnum } from "src/app/impact-assessment/models/Impact-assessment.interface";
import { RadarColumnKeys } from "src/app/radar/radar-table/radar-table.enum";
import { RequestSortKey } from "src/app/requests/models/request-sort-key.enum";
import { SystemsColumnKeys } from "src/app/systems/models/systems-column.enum";
import { TrashSystemsColumnKeys } from "src/app/unused-assets/trash-systems/trash-systems.enum";
import { PiiClassifierColumnKeys } from "src/app/pii-classifier/models/pii-classifier-column.enum";
import { PiiSystemSchemaColumnsEnum } from "src/app/pii-classifier/pii-system-findings/pii-system-schema/schema-table-header/schema-table-header.enum";
import { PiiSystemDataTypeColumnsEnum } from "src/app/pii-classifier/pii-system-findings/pii-system-data-types/data-types-table-header/data-types.enum";
import { CustomValuesTableColumns } from "src/app/company-settings/customization/custom-values/custom-values.enum";
import { LocalizationColumns } from "src/app/dsr/dsr-setup/dsr-localization/models/localization-colums.enum";
import { TemplatesColumns } from "../../dsr/dsr-setup/dsr-templates/models/templates-columns.enum";
import { CompanyGroupsTableColumns } from "src/app/company-settings/groups/groups.enum";

export function PreserveTableState(table: TablesEnum, state: any): ClassDecorator {
    return function (constructor: any) {
        const tableStateService = AppModule.injector.get(TableStateService);
        const originalNgOnDestroy = constructor.prototype.ngOnDestroy;

        // Override ngOnDestory function with this custom function
        constructor.prototype.ngOnDestroy = function() {
            let tableState = this[state] as TableState;
            if (tableState.sort?.direction === SortDirectionEnum.Default || 
                JSON.stringify(getTableDefaultSort(table) ?? '') === JSON.stringify(tableState[TableElement.Sort] ?? '')) {
                
                // unset sort if it equals to the default sort OR direction is default
                tableState[TableElement.Sort] = undefined;
            }

            tableStateService.setTableState(table, tableState);
            originalNgOnDestroy?.apply(); // preserve the original ngOnDestory functionallity
        };
    };
}

export function getTableDefaultSort(table: TablesEnum): MineSort {
    switch (table) {
        case TablesEnum.Employees:
        case TablesEnum.UnusedEmployees:
            return { active: EmployeesTableColumnKeys.CoworkerDataSources, direction: SortDirectionEnum.Desc } as MineSort;

        case TablesEnum.Radar:
            return { active: RadarColumnKeys.Employees, direction: SortDirectionEnum.Desc } as MineSort;

        case TablesEnum.Requests:
            return { active: RequestSortKey.CreatedAt, direction: SortDirectionEnum.Desc } as MineSort;

        case TablesEnum.PiiClassifier:
            return { active: PiiClassifierColumnKeys.LastScan, direction: SortDirectionEnum.Desc } as MineSort;

        case TablesEnum.PiiClassifierSchema:
            return { active: PiiSystemSchemaColumnsEnum.MatchesFound, direction: SortDirectionEnum.Desc } as MineSort;

        case TablesEnum.PiiClassifierDataTypes:
            return { active: PiiSystemDataTypeColumnsEnum.MatchesFound, direction: SortDirectionEnum.Desc } as MineSort;
    
        case TablesEnum.Systems:
            return { active: SystemsColumnKeys.System, direction: SortDirectionEnum.Asc } as MineSort;

        case TablesEnum.ImpactAssessment:
            return { active: ImpactAssessmentTableHeaderEnum.EstimatedImpact, direction: SortDirectionEnum.Desc };

        case TablesEnum.TrashSystems:
            return { active: TrashSystemsColumnKeys.LastUpdate, direction: SortDirectionEnum.Desc };

        case TablesEnum.CustomValues:
            return { active: CustomValuesTableColumns.Field, direction: SortDirectionEnum.Asc };
            
        case TablesEnum.CustomFields:
            return { active: CustomValuesTableColumns.UsedIn, direction: SortDirectionEnum.Asc };

        case TablesEnum.DsrLocalization:
            return { active: LocalizationColumns.Language, direction: SortDirectionEnum.Asc };

        case TablesEnum.DsrTemplates:
            return { active: TemplatesColumns.Name, direction: SortDirectionEnum.Asc };

        case TablesEnum.CompanyGroups:
            return { active: CompanyGroupsTableColumns.Name, direction: SortDirectionEnum.Asc };

        default:
            return {} as MineSort;
    }
}