import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { filter, first, switchMap, tap } from 'rxjs';
import { DialogRef } from '@ngneat/dialog';

import { LoggerService } from 'src/app/logger/logger.service';
import { RoleEnum } from 'src/app/core/models/permission-role.enum';
import { AgentDepartments } from 'src/app/api/models/profile/profile-agent-departments.enum';
import { InviteMemberResponseMsg } from 'src/app/api/models/profile/profile-invite-member.enum';
import { InviteMemberResponse, ProfileInviteMember } from 'src/app/api/models/profile/profile-invite-member.interface';
import { OnboardingTasksService } from 'src/app/services/onboarding-tasks/onboarding-tasks.service';
import { OnboardingTaskEnum } from 'src/app/api/models/profile/profile-onboarding-tasks.enum';
import { DropdownOption } from 'src/app/shared/mine-dropdown/mine-dropdown.interface';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { ProfileService } from '../state/profile.service';
import { TeammatesService } from 'src/app/company-settings/teammates-v2/teammates.service';
import { MineSnackbarType } from 'src/app/shared/mine-snackbar/mine-snackbar-type';
import { MineSnackbarService } from 'src/app/shared/mine-snackbar/mine-snackbar.service';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';
import { BoxBgColor } from 'src/app/shared/mine-info-box/mine-info-box.enum';

// validation flow:
// 1. email not valid ---> return error ("not valid")
// 2. isAgent is true ---> return error ("already a agent")
// all checks passed, email is valid ---> return null (success)
function emailValidator(profileService: ProfileService): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(control.value)) {
			return { error: 'profile.memberEmailError' };
		}
		if (profileService.isAgent(control.value)) {
			return { error: 'profile.memberError1' };
		}
		return null;
	};
}

@Component({
	selector: 'profile-member-dialog',
	templateUrl: './profile-member-dialog.component.html',
	styleUrls: ['./profile-member-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileMemberDialogComponent implements OnInit {
	private readonly loggerName: string = 'ProfileMemberDialogComponent';
  	readonly infoBoxBgColor = BoxBgColor.Grey;

	roleOptions: RoleEnum[] = [];

	devTicketGroupAssignmentFF = false;
	portalGroups = false;

	options: DropdownOption[] = [];
	addMemberFormGroup: FormGroup;
	spinner: boolean;

	constructor(
		private dialogRef: DialogRef,
		private contentPipe: ContentPipe,
		private profileService: ProfileService,
		private loggerService: LoggerService,
		private featureFlagsQuery: FeatureFlagQuery,
		private snackbarService: MineSnackbarService,
		private onboardingTasksService: OnboardingTasksService,
		private teammatesService: TeammatesService,
		private cdr: ChangeDetectorRef,
	) { }

	ngOnInit(): void {
		this.initFlags();
		this.initOptions();
		this.initFormValues();
		this.initRolesOptions();
	}

	private initRolesOptions(): void {
		this.roleOptions = [];

		for (const key in RoleEnum) {
			const value = RoleEnum[key];

			if (value !== RoleEnum.Unknown && ((this.devTicketGroupAssignmentFF && this.portalGroups) || value !== RoleEnum.DsrAgent)) {
				this.roleOptions.push(value);
			}
		}
	}

	private initFlags(): void {
		const flags = this.featureFlagsQuery.getMultipleFlags([FeatureFlags.DevTicketGroupAssignment, FeatureFlags.PortalGroups]);
		this.devTicketGroupAssignmentFF = flags.devTicketGroupAssignment;
		this.portalGroups = flags.portalGroups;
	}

	private initOptions(): void {
		const departments = Object.keys(AgentDepartments).filter(value => value !== AgentDepartments.Unknown).sort((a, b) => a.localeCompare(b));
		departments.forEach(department => this.options.push({ id: department, value: AgentDepartments[department] }));
	}

	cancelClicked(): void {
		this.dialogRef.close();
	}

	onInviteMember(): void {
		this.spinner = true;
		const config: ProfileInviteMember = {
			inviteeEmail: this.addMemberFormGroup.get('inviteeEmail').value,
			department: this.addMemberFormGroup.get('department').value.id,
			role: this.addMemberFormGroup.get('role').value,
		};

		const api = this.teammatesService.sendInvite(config);
		api.pipe(
			first(),
			tap(() => this.loggerService.debug(this.loggerName, 'onInviteMember() invited member')),
			tap((res) => this.handleApiResponse(res)),
			filter((res) => res.statusCode === 200),
			switchMap(() => this.profileService.updateProfileAfterInvite(config)),
			first(),
			switchMap(() => this.onboardingTasksService.updateOnboardingTasks(OnboardingTaskEnum.inviteTeamMember)),
			first(),
		)
			.subscribe(
				() => { },
				() => this.dialogRef.close()
			);
	}

	private handleApiResponse(res: InviteMemberResponse): void {
		if (res.statusCode === 200) {
			this.dialogRef.close(this.addMemberFormGroup.value as ProfileInviteMember);
		}

		if (res.statusCode === 400) {
			const error = this.convertToErrorMsg(res.message);
			this.snackbarService.showTimed(MineSnackbarType.Error, this.contentPipe.transform(error));
			this.addMemberFormGroup.get('inviteeEmail').setErrors({ error });
			this.addMemberFormGroup.updateValueAndValidity();
			this.spinner = false;
			this.cdr.detectChanges();
		}
	}

	private convertToErrorMsg(responseMsg: InviteMemberResponseMsg): string {
		switch (responseMsg) {
			case InviteMemberResponseMsg.InvalidEmailAddress:
				return 'profile.memberEmailError';
			case InviteMemberResponseMsg.InviteeAlreadyAgent:
			case InviteMemberResponseMsg.NotAssociatedEmailAddress:
				return 'profile.memberError2';
			case InviteMemberResponseMsg.NotAllowedUnlimitedAgents:
				return 'profile.reachedMembersLimit';
			case InviteMemberResponseMsg.NotAllowedProviderForUser:
				return 'profile.notAllowedProviderForUser';
			default:
				return 'common.defaultError';
		}
	}

	private initFormValues(): void {
		this.addMemberFormGroup = new FormGroup({
			inviteeEmail: new FormControl('', [Validators.required, emailValidator(this.profileService)]),
			department: new FormControl('', [Validators.required]),
			role: new FormControl(RoleEnum.Reviewer, [Validators.required])
		});
	}

	originalOrder = (a, b) => a.key;
}