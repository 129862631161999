export const EXCLUDED_HTTP_CALLS: (string | { path: string, method: string[] })[] = [
	// IP API Is an external service used during onboarding, to geo-locate the user. Their API sometimes fail and we want to silently ignore that, as this operation is not mandatory
	'pro.ip-api.com',
	// TestWebhookEvent contains specipic error handaling (error can be from customer endpoint as well) so we don't need general error here
	'api/integrations/TestWebhookEvent',
	// Closing error - more specific message that notifies that the request hasn’t been closed
	'api/actions/closeRequestV2',
	// Privacy center URL error - contains a spesific error message
	'api/privacycenter/RequestFormConfig',
	// Invite member - contains a spesific error message
	'api/actions/InviteMember?',
	// SendReply has a special message to show on status 400
	'api/actions/SendReply',
	// Called from my billing button in plans page - we want to avoid the snackbar
	'api/profileportal/PlanManagementsPage',
	// File upload shows error under the button if there is an issue with file
	'api/settings/icon/validateAndSave',
	// Creating new assessment error is shown in an info-box
	{ path: '/api/assessment/v2/assessment-page', method: ['POST'] },
];