import { Pipe, PipeTransform } from '@angular/core';
import { AiAssessmentCustomLabel } from '../models/ai-assessments.interface';
import { AiAssessmentsLabelsService } from '../services/ai-assessments-labels.service';
import { filter, map, Observable, of } from 'rxjs';

@Pipe({
  standalone: true,
  name: 'aiAssessmentLabels',
  pure: false
})
export class AiAssessmentLabelsPipe implements PipeTransform {

  constructor(
    private aiAssessmentsLabelsService: AiAssessmentsLabelsService,
  ) {}

  transform(
    labels?: AiAssessmentCustomLabel[], 
    maxLabels: number = Infinity
  ): Observable<{ labels: string[], plus: { restValue: number, labels: string[] } }> {
    
    if (!labels?.length) {
      return of({ labels: [], plus: { restValue: 0, labels: [] } });
    }

    return this.aiAssessmentsLabelsService.selectLabels().pipe(
      filter(res => !!res),
      map((labelsMap) => {
        if (labelsMap instanceof Map) {
          const labelNames = labels
            .map(label => labelsMap.get(label.id)?.name)
            .filter((name): name is string => !!name);

          const visibleLabels = labelNames.slice(0, maxLabels);
          const restLabels = labelNames.slice(maxLabels);

          return {
            labels: visibleLabels,
            plus: {
              restValue: restLabels.length,
              labels: restLabels
            }
          };
        }

        return { labels: [], plus: { restValue: 0, labels: [] } };
      })
    );
  }
}
