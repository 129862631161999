import { Injectable } from '@angular/core';
import { EmployeesFilter } from 'src/app/api/models/employees/employees.intrface';
import { FilterCategory } from 'src/app/core/models/filtering.interface';
import { MineSort } from '../mine-sort/mine-sort.interface';
import { TableElement, TablesEnum } from './table-state.enum';
import { TableState } from './table-state.interface';

@Injectable({
  providedIn: 'root'
})
export class TableStateService {

  private _tablesStates: Map<TablesEnum, TableState> = new Map<TablesEnum, TableState>();

  setTableState(feature: TablesEnum, data: TableState): void {
    this._tablesStates.set(feature, data);
  }

  getTableSearch(feature: TablesEnum): string {
    return this.getTableElement(feature, TableElement.Search) as string;
  }

  getTableFilters(feature: TablesEnum): FilterCategory[] | EmployeesFilter {
    if (feature === TablesEnum.Employees) {
      return this.getTableElement(feature, TableElement.Filter) as EmployeesFilter;
    }
    return this.getTableElement(feature, TableElement.Filter) as FilterCategory[];
  }

  getTableSort(feature: TablesEnum): MineSort {
    return this.getTableElement(feature, TableElement.Sort) as MineSort;
  }

  getTableColumns(feature: TablesEnum): Set<string> | Map<string, string> {
    if (feature === TablesEnum.Employees) {
      return this.getTableElement(feature, TableElement.ColumnPicker) as Map<string, string>;
    }
    return this.getTableElement(feature, TableElement.ColumnPicker) as Set<string>;
  }

  getTableView(feature: TablesEnum): string {
    return this.getTableElement(feature, TableElement.View) as string;
  }

  getTableLength(feature: TablesEnum): number {
    return this.getTableElement(feature, TableElement.Length) as number;
  }

  private getTableElement(feature: TablesEnum, tableElement: TableElement) {
    try {
      return this._tablesStates.get(feature)[tableElement];
    } 
    catch (e) {
      return null;
    }
  }
}
