import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { TrashSystem } from 'src/app/api/models/systems/systems.interface';

import { produce } from 'immer';

export interface TrashSystemsState extends EntityState<TrashSystem> { }
@Injectable({ 
	providedIn: 'root' 
})
@StoreConfig({ 
	name: 'trashSystems',
	idKey: 'systemId',
	producerFn: produce
})
export class TrashSystemsStore extends EntityStore<TrashSystemsState> {
	
	constructor() {
		super();
	}
}