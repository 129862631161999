import { Component, OnInit, ChangeDetectionStrategy, HostBinding, ChangeDetectorRef, DestroyRef, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, first, map, switchMap, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { RouterEventsListenerService } from './services/router-events-listener.service';
import { LoggerService } from './logger/logger.service';
import { AnalyticsService } from './analytics/analytics.service';
import { MineSnackbarService } from './shared/mine-snackbar/mine-snackbar.service';
import { ChatService } from './chat/chat.service';
import { SeoService } from './services/seo.service';
import { ProfileQuery } from './profile/state/profile.query';
import { ElasticSidenavService } from './core/app-elastic-sidenav/app-elastic-sidenav.service';
import { ProfilePlanDetails } from './api/models/profile/profile-plan-details';
import { RoleEnum } from './core/models/permission-role.enum';
import { FeatureFlagQuery } from './feature-flag/state/feature-flag.query';
import { FeatureFlags } from './api/models/profile/profile-feature-flags.enum';
import { MineOSChatbotService } from './mineos-chatbot/services/mineos-chatbot.service';
import { Profile } from './profile/models/profile.interface';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
	private readonly loggerName: string = 'AppComponent';

	isVerified: boolean;
	showHeader: boolean;
	showSidenav: boolean;
	disablePadding: boolean;
	fullWidthMainContent: boolean;
	snackbarOpen: boolean = false;
	snackbarState: 'open' | 'closed';
	shrink = false;
	showMineChatbot = signal<boolean>(false);
	showOnboardingBackground = false;
	plan: ProfilePlanDetails;
	role: RoleEnum;
	googleCopilotFF = false;

	constructor(
		private routerEventListener: RouterEventsListenerService,
		private activatedRoute: ActivatedRoute,
		private snackbarService: MineSnackbarService,
		private featureFlagQuery: FeatureFlagQuery,
		private profileQuery: ProfileQuery,
		private analytics: AnalyticsService,
		private chat: ChatService,
		private logger: LoggerService,
		private cdr: ChangeDetectorRef,
		private destroyRef: DestroyRef,
		private seoService: SeoService,
		private mineosChatbotService: MineOSChatbotService,
		private elasticSidenavService: ElasticSidenavService,
	) {}

	ngOnInit() {
		this.logger.debug(this.loggerName, 'ngOnInit()');
		this.seoService.init();
		this.snackbarState = 'closed';

		this.initChatbot();
		this.handleProfileResponse();
		this.manageSnackbarDisplay();
		this.listenToSidenavEvents();

		this.routerEventListener.url$.pipe(
			takeUntilDestroyed(this.destroyRef))
			.subscribe((response) => this.handleRoute(response));
	}

	private initChatbot(): void {
		this.featureFlagQuery.selectLoading().pipe(
			filter(loading => loading === false),
			switchMap(() => this.featureFlagQuery.selectMultipleFlags([FeatureFlags.DevMineOSChatbot, FeatureFlags.GoogleCopilot])),
			first(),
			map(flags => {
				this.googleCopilotFF = flags.googleCopilot;
				this.showMineChatbot.set(flags.devMineOSChatbot && !flags.googleCopilot);
			})
		).subscribe();

		this.mineosChatbotService.onDisconnect().pipe(
			first(),
			map(() => this.showMineChatbot.set(false))
		).subscribe();
	}
	
	private listenToSidenavEvents(): void {
		this.elasticSidenavService.shrink$.pipe(
			tap(res => this.shrink = res),
			takeUntilDestroyed(this.destroyRef)
		).subscribe();
	}

	private handleProfileResponse(): void {
		this.profileQuery
			.select(['verifiedToCompany', 'planDetails', 'role'])
			.pipe(
				tap((res) => this.handleQueryResponse(res)),
				tap(() => this.cdr.detectChanges()),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe();
	}
	
	private handleQueryResponse(response: Pick<Profile, 'verifiedToCompany' | 'planDetails' | 'role'>){
		this.logger.info(this.loggerName, `getProfileVerified = ${response.verifiedToCompany}`);
		this.logger.info(this.loggerName, `role = ${response.role}`);
		
		this.plan = response.planDetails;
		this.isVerified = response.verifiedToCompany;
		this.role = response.role;
	}

	private handleRoute(route: string): void {
		if (route !== '') {
			this.analytics.pageView(route);
			// TODO: can we move this to the chat service?
			this.chat.updateUrl();
			let routeData = this.activatedRoute.firstChild.snapshot.data;
			this.disablePadding = routeData.disablePadding;
			this.showHeader = routeData.showHeader ?? false;
			this.showSidenav = 'showSidenav' in routeData ? routeData.showSidenav : true;
			this.fullWidthMainContent = routeData.fullWidthMainContent ?? false;
			this.showOnboardingBackground = routeData.changeBackground ?? false;
		}
	}

	@HostBinding('class.column')
	get header() {
		return this.showHeader;
	}

	private manageSnackbarDisplay(): void {
		this.snackbarService.visible$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(visible => {
			if (visible) {
				this.snackbarOpen = true;
				this.snackbarState = 'open';
				this.cdr.markForCheck();
			}
			else {
				this.snackbarOpen = false;
				this.snackbarState = 'closed';
				this.cdr.markForCheck();
			}
		});
	}
}
