import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClientHelperService } from './http-client-helper.service';
import { RedactResponse } from './models/requests/redact-reponse.interface';
import { InviteMemberResponse, ProfileInviteMember } from './models/profile/profile-invite-member.interface';
import { FileUrlResponse, RequestFormConfiguration } from './models/privacy-center/privacy-center.interface';
import { RequestFormResponse, SubmitRequestForm } from '../requests/request-form/models/interfaces';
import { TicketProcessingState } from './models/integrations/integrations.interface';
import { CloseRequest } from './models/requests/close-request.interface';
import { StateResponse } from './models/requests/request-state';
import { Note } from './models/requests/note.interface';

@Injectable({
	providedIn: 'root'
})
export class ApiClientRequestsService {
	private readonly actionsController = 'actions';

	constructor(
		private httpClientHelper: HttpClientHelperService
	) { }

	sendReply(ticketId: string, message: string, messageChanged?: boolean): Observable<void | Note> {
        const body = {
			messageChanged,
			htmlBody: message,
		};
		return this.httpClientHelper.invokePostAuth<void>(`${this.actionsController}/SendReply?ticketId=${ticketId}`, body);
	}

    addNote(ticketId: string, message: string): Observable<Note> {
		return this.httpClientHelper.invokePostAuth<Note>(`${this.actionsController}/AddNote?ticketId=${ticketId}`, { noteText: message });
	}

	setLastSeen(ticketId: string): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.actionsController}/LastSeen?ticketId=${ticketId}`);
	}

	redactTicket(ticketId: string): Observable<RedactResponse> {
		return this.httpClientHelper.invokePostAuth<RedactResponse>(`${this.actionsController}/RedactTicket/${ticketId}`);
	}

	redactMultipleTickets(ticketId: string, ticketIds: string[]): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.actionsController}/RedactMultipleTickets/${ticketId}`, ticketIds);
	}

	continueAfterEditTicket(ticket: SubmitRequestForm, ticketId: string): Observable<RequestFormResponse> {
		return this.httpClientHelper.invokePostAuth<RequestFormResponse>(`${this.actionsController}/ContinueWithEmailTicket/${ticketId}`, ticket);
	}

	inviteAMember(member: ProfileInviteMember): Observable<InviteMemberResponse> {
		return this.httpClientHelper.invokePostAuth<InviteMemberResponse>(`${this.actionsController}/InviteMember?`, member);
	}

	deleteInvitation(email: string): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.actionsController}/DeleteInvitation`, { InviteeEmail: email });
	}

	getRequestFormConfig(): Observable<RequestFormConfiguration> {
		return this.httpClientHelper.invokeGetAuth<RequestFormConfiguration>(`${this.actionsController}/RequestTicketForm`);
	}

	closeRequestV2(closeRequest: CloseRequest, ticketId: string): Observable<StateResponse> {
		return this.httpClientHelper.invokePostAuth<StateResponse>(`${this.actionsController}/Close/${ticketId}`, closeRequest);
	}

	createTicket(ticket: SubmitRequestForm): Observable<RequestFormResponse> {
		return this.httpClientHelper.invokePostAuth<RequestFormResponse>(`${this.actionsController}/CreateTicket`, ticket);
	}

	getFileUrl(ticketId: string): Observable<FileUrlResponse> {
		return this.httpClientHelper.invokeGetAuth<FileUrlResponse>(`${this.actionsController}/file/${ticketId}`);
	}

	processTicket(accept: boolean, ticketId: string): Observable<StateResponse> {
		return this.httpClientHelper.invokePostAuth<StateResponse>(`${this.actionsController}/ProcessTicket/${ticketId}`, {accept});
	}

	notifyTicket(DeletedWithoutIntegrations: boolean, ticketId: string): Observable<StateResponse> {
		return this.httpClientHelper.invokePostAuth<StateResponse>(`${this.actionsController}/Notify/${ticketId}`, {DeletedWithoutIntegrations});
	}

	getTicketProcessingState(ticketId: string): Observable<TicketProcessingState> {
		return this.httpClientHelper.invokeGetAuth<TicketProcessingState>(`${this.actionsController}/TicketProcessingState/${ticketId}`);
	}
}
