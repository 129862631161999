import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { EmployeeDataMapping } from 'src/app/api/models/data-mapping/data-mapping.interface';

import { produce } from 'immer';

export interface UnusedEmployeesState extends EntityState<EmployeeDataMapping, string> { }

@Injectable({
	providedIn: 'root'
})
@StoreConfig({
	name: 'unusedEmployees',
	idKey: 'id',
	producerFn: produce
})
export class UnusedEmployeesStore extends EntityStore<UnusedEmployeesState> {
	constructor() {
		super();
	}
}