import { Inject, Injectable } from "@angular/core";
import { BaseApiClientCompanySettingsService } from "src/app/api/base-api-client-company-settings.service";
import { API_CLIENT_COMPANY_SETTINGS_SERVICE } from "src/app/api/injectors/api-client-company-settings.token";
import { LoggerService } from "src/app/logger/logger.service";
import { CompanyGroupsQuery } from "./groups.query";
import { map, Observable, tap } from "rxjs";
import { CompanyGroup } from "src/app/api/models/company-settings/groups.interface";
import { ProfileService } from "src/app/profile/state/profile.service";

@Injectable({ 
	providedIn: 'root' 
})
export class CompanyGroupsService {
    private readonly loggerName = 'CompanyGroupsService';

    constructor(
        private loggerService: LoggerService,
        @Inject(API_CLIENT_COMPANY_SETTINGS_SERVICE) private apiClientCompanySettingsService: BaseApiClientCompanySettingsService,
        private groupsQuery: CompanyGroupsQuery,
        private profileService: ProfileService
    ) { }

	init(): Observable<void> {
		this.loggerService.debug(this.loggerName, 'init()');
		return this.getGroups();
	}

    getGroups(): Observable<void> {
        return this.apiClientCompanySettingsService.getGroups().pipe(
            tap(groups => this.groupsQuery.setGroups(groups.groups)),
            tap(() => this.groupsQuery.setLoading(false)),
            tap(() => this.loggerService.info(this.loggerName, 'set groups store')),
            map(() => void 0)
        );
    }

    createGroup(name: string): Observable<void> {
        return this.apiClientCompanySettingsService.createGroup(name).pipe(
            tap(group => this.groupsQuery.addGroup(group)),
            tap(group => this.groupsQuery.updateActiveGroupId(group.id)),
            tap(group => this.loggerService.info(this.loggerName, `set new group: ${group.id}`)),
            map(() => void 0)
        );
    }

    updateGroupName(name: string, id: string): Observable<void> {
        return this.apiClientCompanySettingsService.updateGroupName(name, id).pipe(
            tap(group => this.groupsQuery.updateGroup(group)),
            map(() => void 0)
        );
    }

    removeMemberFromGroup(userId: string, groupId: string): Observable<void> {
        return this.apiClientCompanySettingsService.removeMemberFromGroup(userId, groupId).pipe(
            tap(group => this.groupsQuery.updateGroup(group)),
            tap(() => this.removeGroupFromAgent(userId, groupId)),
            map(() => void 0)
        );
    }

    addMembersToGroup(members: string[], groupId: string): Observable<void> {
        return this.apiClientCompanySettingsService.addMembersToGroup(members, groupId).pipe(
            tap(group => this.groupsQuery.updateGroup(group)),
            tap(() => this.addGroupToAgent(members[0], groupId)),
            map(() => void 0)
        );
    }

    private addGroupToAgent(agentId: string, groupId: string): void {
        this.profileService.addGroupToAgent(agentId, groupId);
    }
    
    private removeGroupFromAgent(agentId: string, groupId: string): void {
        this.profileService.removeGroupFromAgent(agentId, groupId);
    }

    updateMemberInGroups(agentId: string, groupIds: string[]): void {

        const currentGroups = this.groupsQuery.getGroups()(); 
        const groupMap = new Map();
    
        // Add agent to specified groups
        groupIds.forEach(groupId => {
            if (!currentGroups.get(groupId)) {
                const updatedGroup = this.addMemberToGroup(groupId, agentId);
                groupMap.set(groupId, updatedGroup);
            }
        });
    
        // Remove the agent from groups not included in groupIds
        currentGroups.forEach(group => {
            if (!groupIds?.includes(group.id) && group.members?.includes(agentId)) {
                const updatedGroup = this.removeMemberFromGroupStore(group.id, agentId);
                groupMap.set(group.id, updatedGroup);
            } else {
                groupMap.set(group.id, group);
            }
            
        });
    
        this.groupsQuery.updateGroups(groupMap);
    }

    addMemberToGroup(groupId: string, userId: string): CompanyGroup {
        const currentGroup = this.groupsQuery.getGroup(groupId);
        currentGroup.members.push(userId);
        return currentGroup;
    }

    removeMemberFromGroupStore(groupId: string, userId: string): CompanyGroup {
        const currentGroup = this.groupsQuery.getGroup(groupId);
        currentGroup.members = currentGroup.members.filter(member => member !== userId);
        return currentGroup;
    }
}