import { Component, ChangeDetectionStrategy, OnInit, signal } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { map, tap, delay, of } from 'rxjs';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Animations } from 'src/app/animations/animations';
import { PreviewResult } from 'src/app/api/models/integrations/integration-type.enum';
import { IntegrationPreview } from 'src/app/api/models/integrations/integrations.interface';
import { IntegrationPreviewDialogSource } from './integration-preview-dialog-data.enum';
import { SystemsService } from 'src/app/systems/state/systems.service';
import { CommonModule } from '@angular/common';
import { MineServiceIconComponent } from 'src/app/shared/mine-service-icon/mine-service-icon.component';
import { TippyModule } from 'src/app/shared/tippy.module';
import { SkeletonDirective } from 'src/app/shared/skeletons/skeleton.directive';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { DataSourceIndication } from 'src/app/requests/models/data-source.inteface';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';
import { MineButtonDirective } from '@mineos/mine-ui';
import { CsvConvertorService } from 'src/app/csv-convertor/csv-convertor.service';
import { TablesEnum } from 'src/app/shared/table-state-service/table-state.enum';

@Component({
  standalone: true,
  imports: [
	CommonModule,
	MineServiceIconComponent,
	TippyModule,
	SkeletonDirective,
	ContentPipeModule,
	MineButtonDirective,
  ],
  selector: 'integrations-preview-dialog',
  templateUrl: './integrations-preview-dialog.component.html',
  styleUrls: ['./integrations-preview-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [Animations.fadeInOnEnter]

})
export class IntegrationsPreviewDialogComponent implements OnInit {
	assetsUrl = `${environment.assets.url}/Portal/`;
	PreviewResult = PreviewResult;

	integrationPreview$: Observable<IntegrationPreview>;
	integration: DataSourceIndication;

	icon: string;
	records: number;

	loaded = signal<boolean>(false);

	showSuccess = signal<boolean>(true);

	exportCsvFF = false;

	constructor(
		private dialogRef: DialogRef,
		private systemsService: SystemsService,
		private featureFlagQuery: FeatureFlagQuery,
		private csvConvertorService: CsvConvertorService,
	) { }

	ngOnInit(): void {
		this.exportCsvFF = this.featureFlagQuery.getFlag(FeatureFlags.DevIntegrationPreviewCSVExport);
		this.integration = this.dialogRef.data.integration;
		if (this.dialogRef.data.source === IntegrationPreviewDialogSource.Ticket) { //ticket page
			this.integrationPreview$ = of(this.dialogRef.data.integrationPreview);
			this.initHeader(this.dialogRef.data.integrationPreview.records?.length);
			this.loaded.set(true);
			this.showSuccess.set(true);
		} else { // user search page
			this.integrationPreview$ = this.getIntegrationPreviewUserSearch(500);
		}
	}

	private getIcon(): string {
		if (this.integration.icon) {
			return this.integration.icon;
		}
		return `${this.assetsUrl}integrations/data_source_logo_placeholder.png`;
	}

	closeDialog(): void {
		this.dialogRef.close();
	}

	private initHeader(records: number): void {
		this.icon = this.getIcon();
		this.records = records ?? 0;
	}

	private getIntegrationPreviewUserSearch(hold: number = 0): Observable<IntegrationPreview> {
		this.loaded.set(false);
		const identifier = this.dialogRef.data.source === IntegrationPreviewDialogSource.Ticket ? this.dialogRef.data.identifier : this.dialogRef.data.identifier.value;
		return this.systemsService.getIntegrationPreviewUserSearch(this.integration.id, identifier, this.dialogRef.data.source).pipe(
			delay(hold),
			map(response => response.integrations[0]),
			tap(response => this.initHeader(response.records?.length)),
			tap(response => this.showSuccess.set(response.result === PreviewResult.Success)),
			tap(() =>  this.loaded.set(true))
		);
	}

	export(): void {
		const columns = this.dialogRef.data.integrationPreview.records.length > 0 ? this.dialogRef.data.integrationPreview.records[0].integrationPreviewFields.map(field => field.name) : [];
		// normalize data for export
		let data = [];
		this.dialogRef.data.integrationPreview.records.forEach(record => {
			const row = {};
			record.integrationPreviewFields.forEach(field => {
				row[field.name] = field.value;
			});
			data.push(row);
		});

		const fileName = `Found records from ${this.dialogRef.data.integration.displayName} Request ${this.dialogRef.data.actionId}.csv`;
		this.csvConvertorService.generateCSVFromData(TablesEnum.IntegrationPreview, columns, data, null, fileName);
	}
}
