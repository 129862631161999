import { Directive, Input, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[mineTimer]',
  standalone: true,
})
export class MineTimerDirective implements OnInit, OnDestroy {
  @Input() timespan!: number;
  @Input() countdown: boolean = true;
  @Output() mineTimer = new EventEmitter<string>();
  @Output() timerEnd = new EventEmitter<void>();

  private currentTime!: number;
  private intervalId!: number;

  ngOnInit(): void {
    if (this.timespan === undefined || this.timespan === null) {
      console.error('MineTimerDirective: `timespan` is required.');
      return;
    }

    this.currentTime = this.countdown ? this.timespan : 0;

    this.emitTime();

    this.intervalId = window.setInterval(() => {
        if (this.countdown) {
          this.currentTime--;
          if (this.currentTime < 0) {
            this.clearInterval();
            this.timerEnd.emit();
            return;
          }
        } else {
          this.currentTime++;
          if (this.currentTime >= this.timespan) {
            this.clearInterval();
            this.timerEnd.emit();
            return;
          }
        }
  
        this.emitTime();
      }, 1000);
  }

  private emitTime(): void {
    this.mineTimer.emit(this.formatTime(this.currentTime));
  }

  private formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
    const secs = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${secs}`;
  }

  private clearInterval(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  ngOnDestroy(): void {
    this.clearInterval();
  }
}
