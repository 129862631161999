import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { AiAssessmentCustomLabel } from "src/app/ai-assessments/models/ai-assessments.interface";
import { BaseApiClientLabelsService } from "src/app/api/base-api-client-labels.service";
import { HttpClientHelperService } from "src/app/api/http-client-helper.service";

@Injectable({
  providedIn: 'root'
})
export class ApiClientCollaborationLabelsService extends BaseApiClientLabelsService {

  constructor(httpClientHelper: HttpClientHelperService) {
    super(httpClientHelper);
  }

  override getLabels(): Observable<AiAssessmentCustomLabel[]> {
    return of([]);
  }
}
