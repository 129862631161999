export enum RequestSortKey {
   UserEmail = 'userEmail',
   FullName = 'name',
   Domain = 'domain',
   CreatedAt = 'createdAt',
   DueDate = 'dueDate',
   ActionId = 'actionId',
   TicketSource = 'ticketSource',
   TicketState = 'ticketState',
   ClosingReason = 'closingReason',
   Country = 'country',
   Rights = 'privacyRightId',
   GroupIdAssignment = 'groupIdAssignment',
}