import { Observable, tap } from 'rxjs';
import { PaginationResponse, PaginatorPlugin } from '@datorama/akita';
import { Inject, Injectable } from '@angular/core';
import { UNUSED_EMPLOYEES_PAGINATOR } from './unused-employees-paginator';
import { UnusedEmployeesState } from './unused-employees.store';
import { EmployeeDataMapping } from 'src/app/api/models/data-mapping/data-mapping.interface';
import { EmployeesFilter, ManageEmployee } from 'src/app/api/models/employees/employees.intrface';
import { MineSort } from 'src/app/shared/mine-sort/mine-sort.interface';
import { ManageAsyncActionsEmployeesService } from 'src/app/employees/employees-managment/manage-async-actions-employees.service';
import { EmployeesAsyncActions } from 'src/app/heavy-action-dialog/heavy-action-dialog.enum';
import { API_CLIENT_EMPLOYEES_SERVICE } from 'src/app/api/injectors/api-client-employees.token';
import { BaseApiClientEmployeesService } from 'src/app/api/base-api-client-employees.service';

@Injectable({ 
	providedIn: 'root' 
})
export class UnusedEmployeesService  {

	constructor(
		@Inject(API_CLIENT_EMPLOYEES_SERVICE) private apiClientEmployeesService: BaseApiClientEmployeesService,
		@Inject(UNUSED_EMPLOYEES_PAGINATOR) public paginatorRef: PaginatorPlugin<UnusedEmployeesState>,
		private manageAsyncActionsEmployeesService: ManageAsyncActionsEmployeesService,
	) {	}

	getUnusedEmployeesPage(page: number, perPage: number, filterBy: EmployeesFilter, sort: MineSort): Observable<PaginationResponse<EmployeeDataMapping>> {
		return this.apiClientEmployeesService.getPaginatedUnusedEmployees(page, perPage, filterBy, sort);
	}

	restoreEmployee(employeeId: string): Observable<void> {
		return this.apiClientEmployeesService.manageEmployee({ id: employeeId, isArchived: false } as ManageEmployee).pipe(
			tap(() => this.afterRestoreEmployee()),
		);
	}

	private afterRestoreEmployee(): void {
		this.manageAsyncActionsEmployeesService.setEmployeesAsyncAction(EmployeesAsyncActions.UnArchiveEmployee);
		this.refreshEmployeesPaginatorData();
	}

	refreshEmployeesPaginatorData(): void {
		this.paginatorRef.clearCache();
		this.paginatorRef.refreshCurrentPage();
	}
}
