export enum TablesEnum {
  Radar = 'Radar',
  RadarSystemInfo = 'Radar System Info',
  Systems = 'Systems',
  Teammates = 'Teammates',
  Properties = 'Properties',
  ImpactAssessment = 'Impact Assessment',
  Requests = 'Requests',
  Employees = 'Employees',
  EmployeesViewPanel = 'Employees View Panel',
  TrashSystems = 'Trash Systems',
  UnusedEmployees = 'Unused Employees',
  CustomDataTypes = 'Custom Data Types',
  PiiClassifier = 'Pii Classifier',
  PiiClassifierDataTypes = 'PiiClassifierDataTypes',
  PiiClassifierSchema = 'Pii Classifier Schema',
  CustomValues = 'CustomValues',
  CustomFields = 'CustomFields',
  Assessments = 'Assessments',
  DsrLocalization = 'DsrLocalization',
  DsrTemplates = 'DsrTemplates',
  RiskRegistry = 'RiskRegistry',
  IntegrationPreview = 'IntegrationPreview',
  CompanyGroups = 'CompanyGroups',
}

export enum TableElement {
  Search = 'search',
  ColumnPicker = 'columnPicker',
  Sort = 'sort',
  Filter = 'filter',
  Status = 'status',
  View = 'view',
  Length = 'length'
}