import { Injectable } from '@angular/core';
import { HttpClientHelperService } from './http-client-helper.service';
import { PrivacyCenterConfiguration, PrivacyCenterRequestForm } from './models/privacy-center/privacy-center.interface';
import { Observable, of } from 'rxjs';
import { RoleEnum } from '../core/models/permission-role.enum';
import { PermissionsService } from '../auth/permissions.service';

@Injectable({
  	providedIn: 'root'
})
export class ApiClientPrivacyCenterService {

	private readonly controller = 'privacycenter';

	constructor(private httpClientHelper: HttpClientHelperService,
		private permissionsService: PermissionsService
	) { }

	getConfiguration(): Observable<PrivacyCenterConfiguration> {
		const permmitedRole = [RoleEnum.Admin, RoleEnum.Agent, RoleEnum.DsrManager, RoleEnum.InventoryManager];
		if (this.permissionsService.getIsAllowed(permmitedRole)) {
			return this.httpClientHelper.invokeGetAuth<PrivacyCenterConfiguration>(`${this.controller}/Configuration`);
		}
		return of(null);
	}

	setConfiguration(data: PrivacyCenterRequestForm): Observable<PrivacyCenterRequestForm> {
		return this.httpClientHelper.invokePostAuth<PrivacyCenterRequestForm>(`${this.controller}/RequestFormConfig`, data);
	}
}
