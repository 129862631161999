import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { Pipe, PipeTransform } from '@angular/core';
import { RoleEnum } from 'src/app/core/models/permission-role.enum';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';

@Pipe({
  standalone: true,
  name: 'userRoleToText'
})
export class UserRoleToTextPipe implements PipeTransform {
  private rolesMap = new Map<RoleEnum, string>(); 

  constructor(private content: ContentPipe, private featureFlagQuery: FeatureFlagQuery) {
    let roles;
    if (this.featureFlagQuery.getFlag(FeatureFlags.DevTicketGroupAssignment)) {
      roles = this.content.transform('profile.userRoles'); //new names for roles
    } else {
      roles = this.content.transform('profile.agentRoles');
    }
    
    roles.forEach(role => this.rolesMap.set(role.id, role.value));
  }
  
  transform(role: RoleEnum): string {
    return  this.rolesMap.get(role) ?? '-';
  }
}
