import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UnusedEmployeesState, UnusedEmployeesStore } from './unused-employees.store';

@Injectable({
	providedIn: 'root'
})
export class UnusedEmployeesQuery extends QueryEntity<UnusedEmployeesState> {

	constructor(protected store: UnusedEmployeesStore) {
		super(store);
	}
	
}