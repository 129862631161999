import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { catchError, delay, retryWhen, tap, mergeMap, first, switchMap, Observable, throwError, iif, of, EMPTY } from 'rxjs';
import { RoutesManager } from '../shared/models/routes.interfaces';
import { LoggerService } from '../logger/logger.service';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';
import { EXCLUDED_HTTP_CALLS } from "./excluded-http-calls";
import { MineSnackbarType } from "../shared/mine-snackbar/mine-snackbar-type";
import { MineSnackbarService } from "../shared/mine-snackbar/mine-snackbar.service";
import { ContentPipe } from "../services/content/content.pipe";
import { ProfileQuery } from "../profile/state/profile.query";
import { AuthProviders } from "./models/auth/auth-providers";
import { LocalStorageHelper } from "../services/localStorage-helper";
import { HotjarService } from '../analytics/hotjar.service';
import { DialogsManagerService } from '../services/dialogs-manager.service';
import { SessionExpiredDialogComponent } from '../auth/session-expired-dialog/session-expired-dialog.component';
import { DialogRef } from '@ngneat/dialog';

@Injectable({
    providedIn: "root"
})
export class HttpErrorsInterceptor implements HttpInterceptor {
	private readonly loggerName: string = "HttpErrorsInterceptor";

	private sessionExpiredDialogRef: DialogRef<SessionExpiredDialogComponent>;

	private sessionExpired = false;
	
	constructor(
		private logger: LoggerService,
		private authService: AuthService,
		private hotjar: HotjarService,
		private router: Router,
		private snackbarService: MineSnackbarService,
		private contentPipe: ContentPipe,
		private profileQuery: ProfileQuery,
		private dialogsManagerService: DialogsManagerService,
	) {}
   
   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status >= 400 && error.status < 500) {
					return this.handleClientErrors(error, req?.method);
				}
				else if (error.status >= 500 || error.status == 0) {
					return this.handleServerErrors(error, req, next);
				}
				else {
					this.handleErrorLogging(`General HTTP error. error code: ${error.status}, error message: ${error.message}`);
					return throwError(error);
				}
			})
		);
	}
	
	private handleServerErrors(error: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
		let retryDelay = 500;
		let retries = 1;
		
		return next.handle(req).pipe(
			retryWhen(errors => errors.pipe(
				mergeMap(err => 
					iif(() => retries === environment.api.errorHandling.maxNumOfRetries, 
						throwError(err).pipe(
							tap(() => this.handleErrorLogging(
								`Finish retries, HTTP server side error - error code: ${err.status}, error message: ${err.message}`
							))),
						of(err).pipe(
							tap(() => this.logger.error(this.loggerName, `retry number ${retries} for http call: ${err.url}`)),
							tap(() => retries++),
							delay(retryDelay *= 2))
						)
					)
				)
			)
		);
	}

	private handleClientErrors(error: HttpErrorResponse, method?: string): Observable<any> {
		if (!this.authService.isAuthenticated()) {
			this.logger.info(this.loggerName, `User not authenticated`);
			return throwError(error);
		}

		//401 - not authenticated
		//440 - session expired
		if (error.status === 440 || this.sessionExpired) {
			return this.handleSessionExpiredError();
		} else if (error.status === 401) {
			return this.handleAuthError(error);
		} else if (this.exludedHttpCall(error.url, method)) {
			return throwError(error);
		} else {
			this.handleErrorLogging(`HTTP client side error - error code: ${error.status}, error message: ${error.message}`);
			return throwError(error);
		}
	}

	private logoutUser(): Observable<any> {
		const userId = this.authService.getLoggedInUserId();
			
		if (userId) {
			LocalStorageHelper.removeUserIdAndConnectedCompany(userId);
		}
		return this.authService.logout();
	}

	private handleAuthError(error: HttpErrorResponse): Observable<any> {
		return this.logoutUser().pipe(
			first(),
			tap(() => this.handleErrorSnackbar()),
			tap(() => this.profileQuery.getValue()?.identityProvider?.toLowerCase() !== AuthProviders.auth0 ? this.router.navigate([RoutesManager.login]) : null),
			switchMap(() => throwError(error)),
		);
	}

	private handleSessionExpiredError(): Observable<any> {
		// when already expired - we don't want to open the dialog again	
		if (this.sessionExpired) {
			return EMPTY;
		}

		this.sessionExpired = true;
		this.sessionExpiredDialogRef = this.dialogsManagerService.openDialogCommon(SessionExpiredDialogComponent, null, '450px', false);
		return this.sessionExpiredDialogRef?.afterClosed$.pipe(
			switchMap(() => this.logoutUser()),
			tap(() => this.router.navigate([RoutesManager.login])),
			tap(() => this.resetSessionExpiredState()),
			switchMap(() => EMPTY),
		);
	}

	private resetSessionExpiredState(): void {
		this.sessionExpiredDialogRef = undefined;
		this.sessionExpired = false;
	}

	handleErrorSnackbar(): void {
		this.snackbarService.showTimed(MineSnackbarType.Error, this.contentPipe.transform('common.defaultError'));
		this.hotjar.event('error-snackbar');
	}

	private handleErrorLogging(logMsg: string): void {
		this.logger.error(this.loggerName, logMsg);
		this.handleErrorSnackbar();
	}

	private exludedHttpCall(url: string, method?: string): boolean {
		return EXCLUDED_HTTP_CALLS.some(entry => {
			if (typeof entry === 'string') {
				return url.includes(entry);
			} else {
				return url.includes(entry.path) && entry.method.includes(method);
			}
		});
	}
}
