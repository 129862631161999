
    <svg class="dialog__icon">
        <use xlink:href="../../../assets/img/sprite.svg#warning-triangle"></use>
    </svg>

    <h2 class="dialog__title mine-title-m">
        {{ 'ai-assessments-collaboration.IdleSessionTimeDialog.title' | content }}
    </h2>
    
    <div class="dialog__text" (timerEnd)="stopEditing()" (mineTimer)="time = $event" [timespan]="closeDialogCountdown" [countdown]="true">
        {{ 'ai-assessments-collaboration.IdleSessionTimeDialog.text' | content:{ params: { time } } }}
    </div>


<div class="dialog__actions">
    <button mine-button color="secondary"
        (click)="stopEditing()">
        {{ 'ai-assessments-collaboration.IdleSessionTimeDialog.cancelBtn' | content }}
    </button>

    <button mine-button color="primary"
        (click)="continueEditing()">
        {{ 'ai-assessments-collaboration.IdleSessionTimeDialog.okBtn' | content }}
    </button>
</div>