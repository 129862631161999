import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CollaborationTokenStoreService {
  private assessmentId: string | null = null; // Store assessmentId in memory

  private getAssessmentData(assessmentId: string): Record<string, string> {
    const data = localStorage.getItem(`assessment_${assessmentId}`);
    return data ? JSON.parse(data) : {};
  }

  private setAssessmentData(assessmentId: string, data: Record<string, string>): void {
    if(assessmentId) {
      localStorage.setItem(`assessment_${assessmentId}`, JSON.stringify(data));
    }
  }

  private updateAssessmentData(assessmentId: string, key: string, value: string): void {
    const id = this.getAssessmentId();
    const data = this.getAssessmentData(id);
    data[key] = value;
    this.setAssessmentData(id, data);
  }

  setAssessmentId(assessmentId: string): void {
    this.assessmentId = assessmentId; // Save in memory
  }

  getAssessmentId(): string | null {
    if (this.assessmentId) {
      return this.assessmentId; // Return from memory if set
    }
  
    const url = new URL(window.location.href);
  
    // Check if 'assessmentPageId' exists as a query parameter
    const assessmentPageId = url.searchParams.get('assessmentPageId');
    if (assessmentPageId) {
      this.setAssessmentId(assessmentPageId); // Set in memory
      return assessmentPageId;
    }
  
    // Match only the '/assessment-page/:id' pattern
    const match = url.pathname.match(/\/assessment-page\/([^/]+)/);
    if (match && match[1]) {
      this.setAssessmentId(match[1]); // Set in memory
      return match[1];
    }
  
    return null; // Return null if no ID is found
  }

  setEmail(email: string): void {
    const id = this.getAssessmentId();
    this.updateAssessmentData(id, 'email', email);
  }

  getEmail(): string | undefined {
    const id = this.getAssessmentId();
    return this.getAssessmentData(id)?.email;
  }

  setCompanyId(companyId: string): void {
    const id = this.getAssessmentId();
    this.updateAssessmentData(id, 'companyId', companyId);
  }

  getCompanyId(): string | undefined {
    const id = this.getAssessmentId();
    return this.getAssessmentData(id)?.companyId;
  }

  setCompanyName(companyName: string): void {
    const id = this.getAssessmentId();
    this.updateAssessmentData(id, 'companyName', companyName);
  }

  getCompanyName(): string | undefined {
    const id = this.getAssessmentId();
    return this.getAssessmentData(id)?.companyName;
  }

  setAgentEmail(agentEmail: string): void {
    const id = this.getAssessmentId();
    this.updateAssessmentData(id, 'agentEmail', agentEmail);
  }

  getAgentEmail(): string | undefined {
    const id = this.getAssessmentId();
    return this.getAssessmentData(id)?.agentEmail;
  }

  setAccessToken(accessToken: string): void {
    const id = this.getAssessmentId();
    this.updateAssessmentData(id, 'accessToken', accessToken);
  }

  getAccessToken(): string | undefined {
    const id = this.getAssessmentId();
    return this.getAssessmentData(id)?.accessToken;
  }

  setLogoutTitle(logoutTitle: string): void {
    const id = this.getAssessmentId();
    this.updateAssessmentData(id, 'logoutTitle', logoutTitle);
  }

  getLogoutTitle(): string | undefined {
    const id = this.getAssessmentId();
    return this.getAssessmentData(id)?.logoutTitle;
  }

  setLogoutText(logoutText: string): void {
    const id = this.getAssessmentId();
    this.updateAssessmentData(id, 'logoutText', logoutText);
  }

  getLogoutText(): string | undefined {
    const id = this.getAssessmentId();
    return this.getAssessmentData(id)?.logoutText;
  }

  clearAssessmentData(): void {
    const id = this.getAssessmentId();
    localStorage.removeItem(`assessment_${id}`);
  }
}