import { FeatureFlags } from "../api/models/profile/profile-feature-flags.enum";
import { RoutesManager } from "../shared/models/routes.interfaces";

export class FeatureFlagGuardHelper {
  
  // for generic URLs that we couldn't add the feature flag in the route - this function got URL and returning the FF name
  static getFeatureFlagByUrl(url: string): FeatureFlags | string {
    switch(url) {
      case `/${RoutesManager.data_sources}/${RoutesManager.integrations_webhook_events}`:
        return FeatureFlags.WebHookEvents;
      default: {
        //feature flags that controlled from cms
        if (url.includes(`/${RoutesManager.data_sources}/`)) {
          if (url.includes(`/${RoutesManager.oauth_callback}`)) {
            return url.split('state=').pop();
          }
          return url.split('?')[0].split('/').pop();
        }
        return null;
      };
    }
  }
}