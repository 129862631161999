import { Injectable } from '@angular/core';
import {Observable, tap, map, filter, switchMap} from 'rxjs';
import { DsrPrivacyRightsQuery } from '../state/dsr-privacy-rights.query';
import { ApiClientDsrRightsService } from 'src/app/api/dsr/api-client-dsr-rights.service';
import { NewPrivacyRightData, PrivacyRightDeepItem, PrivacyRightExtendedResponse, PrivacyRightItem } from 'src/app/api/models/dsr/dsr-rights.interface';
import { LoggerService } from 'src/app/logger/logger.service';
import { PrivacyRight } from 'src/app/company-settings/configuration/configuration.interface';
import { PredefinedRightIds, RightProcessingTypeEnum } from 'src/app/api/models/dsr/dsr-rights.enum';

@Injectable({
    providedIn: 'root'
})
export class DsrPrivacyRightsService {
    private readonly loggerName = 'DsrPrivacyRightsService';
    private predefinedRightIdsSet = new Set<string>(Object.values(PredefinedRightIds));

    constructor(
        private loggerService: LoggerService,
        private apiClientDsrRightsService: ApiClientDsrRightsService,
        private dsrRightsQuery: DsrPrivacyRightsQuery,
    ) { }

    initRights(): Observable<void> {
        return this.apiClientDsrRightsService.getPrivacyRightsShallow().pipe(
            tap(rights => this.dsrRightsQuery.setRights(rights.privacyRights)),
            tap(() => this.loggerService.info(this.loggerName, 'get rights data')),
            tap(() => this.dsrRightsQuery.setLoading(false)),
            map(() => void 0));
    }

    hasPrivacyRights(): Observable<boolean> {
        return this.dsrRightsQuery.selectLoading().pipe(
            filter(loading => !loading),
            switchMap(() => this.dsrRightsQuery.selectRights()),
            map(rightsMap => rightsMap?.size > 0)
        );
    }

    enablePrivacyRight(id: string, right: Partial<PrivacyRightDeepItem>): Observable<void> {
        return this.apiClientDsrRightsService.updatePrivacyRightDisplaySettings(id, right).pipe(
            tap(() => this.dsrRightsQuery.setRightEnabled(id, right.enabled))
        );
    }

    getPrivacyRightsMap(): Observable<Map<string, PrivacyRight[]>> {
        return this.getPrivacyRightsExtended().pipe(
            map(res => this.createPrivacyRightsMap(res)),
        );
    }

    private getPrivacyRightsExtended(): Observable<PrivacyRightExtendedResponse> {
        return this.apiClientDsrRightsService.getPrivacyRightsExtended();
    }
    
    createPrivacyRightsMap(response: PrivacyRightExtendedResponse): Map<string, PrivacyRight[]> {
        const map = new Map<string, PrivacyRight[]>();
    
        response.privacyRights
        .filter(privacyRight => !!privacyRight.enabled)
        .forEach(privacyRight => {
            privacyRight.eligibleRegions.forEach((region) => {
                let key = region.continent;
                if (region.country) {
                    key += `:${region.country}`;
                }
                if (region.state) {
                    key += `:${region.state}`;
                }
    
                if (!map.has(key)) {
                    map.set(key, []);
                }

                const transformedRight = this.getTransformedRight(privacyRight);
    
                map.get(key)?.push(transformedRight);
            });
        });
    
        return map;
    }
    
    private getTransformedRight(privacyRight: PrivacyRightDeepItem): PrivacyRight {
        return {
			formCtrlName: privacyRight.id,
			title: privacyRight.displayName,
			subtitle: privacyRight.description,
			iconId: this.predefinedRightIdsSet.has(privacyRight.id) ? `icon-${privacyRight.id}-request` : `icon-donotmail-request`, // icon for custom rights
		};
    }

    updateRightNameAndDescription(id: string, name: string, description: string): void {
        this.dsrRightsQuery.updateRightNameAndDescription(id, name, description);
    }

    createNewRight(formData: NewPrivacyRightData): Observable<PrivacyRightItem> {
        return this.apiClientDsrRightsService.createNewPrivacyRight(formData).pipe(
            tap(right => this.dsrRightsQuery.addRight(right)),
        );
    }

    getRightProcessingType(rightId: string): RightProcessingTypeEnum {
        return this.dsrRightsQuery.getRights()().get(rightId)?.rightProcessingType;
    }

}