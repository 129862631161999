import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClientHelperService } from "./http-client-helper.service";

@Injectable({
  providedIn: 'root'
})
export class ApiClientDashboardService {
  constructor(private httpClientHelper: HttpClientHelperService) { }

    private readonly controller = 'dashboard';

    generateDashboardUrl(): Observable<string> {
        return this.httpClientHelper.invokePostAuth<string>(`${this.controller}/GetMainEmbedUrl`);
    }

    generateAssessmentsDashboardUrl(): Observable<string> {
      return this.httpClientHelper.invokePostAuth<string>(`${this.controller}/GetAssessmentsEmbedUrl`);
    }

    generateRiskEmbedDashboardEmbedUrl(): Observable<string> {
      return this.httpClientHelper.invokePostAuth<string>(`${this.controller}/GetRiskEmbedUrl`);
    }
}
