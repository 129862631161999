import { Injectable } from '@angular/core';

import { IntegrationRecords } from 'src/app/api/models/integrations/integrations.interface';

@Injectable()
export class IntegrationPreviewCsvHelper {

  getValueByKey(recordRow: IntegrationRecords, key: string): string {
    return recordRow[key];
  }
}