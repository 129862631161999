import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { SeoService } from '../services/seo.service';
import { LoggerService } from '../logger/logger.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { MineSnackbarService } from '../shared/mine-snackbar/mine-snackbar.service';
import { RouterEventsListenerService } from '../services/router-events-listener.service';

@Component({
  selector: 'app-root',
  templateUrl: './collaboration.component.html',
  styleUrls: ['./collaboration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollaborationComponent {
  private readonly loggerName: string = 'CollaborationComponent';

	snackbarOpen: boolean = false;
	snackbarState: 'open' | 'closed';

	constructor(
		private routerEventListener: RouterEventsListenerService,
		private snackbarService: MineSnackbarService,
		private analytics: AnalyticsService,
		private logger: LoggerService,
		private cdr: ChangeDetectorRef,
		private destroyRef: DestroyRef,
		private seoService: SeoService,
	) {}

	ngOnInit() {
		this.logger.debug(this.loggerName, 'ngOnInit()');
		this.seoService.init();
		this.snackbarState = 'closed';
		this.manageSnackbarDisplay();

		this.routerEventListener.url$.pipe(
			takeUntilDestroyed(this.destroyRef))
			.subscribe((response) => this.handleRoute(response));
	}

	private handleRoute(route: string): void {
		if (route !== '') {
			this.analytics.pageView(route);
		}
	}

	private manageSnackbarDisplay(): void {
		this.snackbarService.visible$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(visible => {
			if (visible) {
				this.snackbarOpen = true;
				this.snackbarState = 'open';
				this.cdr.markForCheck();
			}
			else {
				this.snackbarOpen = false;
				this.snackbarState = 'closed';
				this.cdr.markForCheck();
			}
		});
	}
}
