import { Inject, Injectable } from "@angular/core";
import { RxState } from "@rx-angular/state";
import { catchError, delay, map, of, shareReplay } from "rxjs";

import { LoggerService } from "../../logger/logger.service";
import { LabelsState } from "../models/ai-assessments-labels.state.interface";
import { AiAssessmentCustomLabel } from "../models/ai-assessments.interface";
import { API_CLIENT_LABELS_SERVICE } from "src/app/api/injectors/api-client-labels.token";
import { BaseApiClientLabelsService } from "src/app/api/base-api-client-labels.service";

@Injectable({
    providedIn: 'root'
})
export class LabelsStore extends RxState<LabelsState> {

    private readonly loggerName: string = 'LabelsStore';

    private readonly initState = {
        empty: undefined,
        loading: true,
        labels: new Map<number, AiAssessmentCustomLabel>(),
    } as LabelsState;

    private readonly _labels$ = this.apiClientLabelsService.getLabels().pipe(
        shareReplay(),
        delay(100),
        map(labels => new Map(labels.map(label => ([label.id, label])))),
        catchError(err => {
            console.error(err);
            this.logger.error(this.loggerName, err);
            return of(new Map());
        })
    );

    constructor(
        private logger: LoggerService,
        @Inject(API_CLIENT_LABELS_SERVICE) private apiClientLabelsService: BaseApiClientLabelsService
    ) {
        super();
        this.set(this.initState);
        this.connect('labels', this._labels$);
        this.connect('loading', this._labels$.pipe(
            map(() => false)
        ));
        this.connect('empty', this._labels$.pipe(
            map(labels => !labels?.size)
        ));
    }

    addLabel(newLabel: AiAssessmentCustomLabel): void {
        this.set((state) => ({
            labels: new Map(state.labels).set(newLabel.id, newLabel),
        }));
    }

    updateLabel(updatedLabel: AiAssessmentCustomLabel): void {
        const labels = this.get('labels');
        labels.set(updatedLabel.id, updatedLabel);
        this.set('labels', () => new Map(labels.entries()));
    }
    
    deleteLabel(id: number): void {
        const updatedLabels = new Map(this.get('labels'));
        updatedLabels.delete(id);
        this.set('labels', () => updatedLabels);
    }
}
