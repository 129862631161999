import { Inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject, interval, Subject, switchMap, takeUntil, catchError, of, tap, startWith, first, map, finalize } from 'rxjs';
import { BaseApiClientAiAssessmentsService } from 'src/app/api/base-api-client-ai-assessments.service';
import { API_CLIENT_ASSESSMENTS_SERVICE } from 'src/app/api/injectors/api-client-ai-assessments.token';
import { AiAssessmentLockStatus } from 'src/app/api/models/ai-assessments/ai-assessments.interface';
import { AssessmentIdleModeDialogComponent } from 'src/app/collaboration/assessments/assessment-page/assessment-idle-mode-dialog/assessment-idle-mode-dialog.component';
import { DialogsManagerService } from 'src/app/services/dialogs-manager.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AiAssessmentsLockStatusService {
  private pollingInterval = 10_000;
  private stopPollingSubject = new Subject<void>();
  private lockStatusSubject = new BehaviorSubject<AiAssessmentLockStatus | null>(null);
  private pollingActive = false;
  private dialogRef = null;

  lockStatus$: Observable<AiAssessmentLockStatus | null> = this.lockStatusSubject.asObservable();

  constructor(
    @Inject(API_CLIENT_ASSESSMENTS_SERVICE) private apiClientAiAssessmentsService: BaseApiClientAiAssessmentsService,
    private dialogManager: DialogsManagerService
  ) {}

  startPollingLockStatus(instanceId: string): void {
    if (this.pollingActive) {
      console.warn(`Polling is already active for instance ${instanceId}`);
      return;
    }

    this.pollingActive = true;
    
    interval(this.pollingInterval).pipe(
      startWith(0),
      takeUntil(this.stopPollingSubject),
      switchMap(() =>
        this.apiClientAiAssessmentsService.getLockStatus(instanceId).pipe(
          catchError((error) => {
            console.error(`Polling error for instance ${instanceId}:`, error);
            return of(null);
          })
        )
      ),
      tap((lockStatus) => this.lockStatusSubject.next(lockStatus))
    ).subscribe();
  }

  handleIdleDialogLogic(lockStatus: AiAssessmentLockStatus, email: string): Observable<boolean> {
    if (!lockStatus || lockStatus.lockedBy !== email) {
      return of(null);
    }

    const lockedAtTime = new Date(lockStatus.lockedAt).getTime();
    const currentTime = Date.now();
    const timeElapsed = currentTime - lockedAtTime;

    if (timeElapsed < environment.collaboration.idleSessionDialog.openDialogCountdown) {
      return of(null);
    }

    if (this.dialogRef) {
      return this.dialogRef;
    }
    
    this.dialogRef = this.dialogManager
      .openDialogCommon(AssessmentIdleModeDialogComponent, null, '560px', false)
      .afterClosed$.pipe(
        first(),
        map(continueEditing => !!continueEditing),
        finalize(() => {
          this.dialogRef = null;
        })
      );

    return this.dialogRef;
  }

  stopPolling(): void {
    if (!this.pollingActive) {
      console.warn('Polling is not active.');
      return;
    }

    this.pollingActive = false;
    this.stopPollingSubject.next();
    this.stopPollingSubject.complete();
    this.stopPollingSubject = new Subject<void>();
  }

  updateLockStatus(lockStatus: AiAssessmentLockStatus): void {
    this.lockStatusSubject.next(lockStatus);
  }

  getLockStatus(instanceId: string): Observable<AiAssessmentLockStatus> {
    return this.apiClientAiAssessmentsService.getLockStatus(instanceId);
  }

  isPollingActive(): boolean {
    return this.pollingActive;
  }
}