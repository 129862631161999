import { PiiDataTypesQuery } from './pii-data-types.query';
import { PiiDataTypesStore } from './pii-data-types.store';
import { Injectable } from "@angular/core";
import { BehaviorSubject, EMPTY, Observable, catchError, filter, first, map, merge, switchMap, tap } from "rxjs";
import { ApiClientPiiClassifierService } from "src/app/api/api-client-pii-classifier.service";
import { ExportDataTypesResponse, PiiDataTypeItemResponse, PiiDataTypesDeepDiveResponse,PiiSystemDataTypesResponse } from 'src/app/api/models/pii-classifier/pii-classifier.interface';
import { LoggerService } from "src/app/logger/logger.service";
import { PiiDataTypeItem, PiiSystemDataTypes } from '../../../models/pii-classifier.interface';
import { SystemsQuery } from 'src/app/systems/state/systems.query';
import { FrameworksService } from 'src/app/data-types/services/frameworks.service';
import { DataTypesQuery } from 'src/app/data-types/state/data-types.query';
import { PoliciesService } from 'src/app/policies/state/policies.service';
import { ConvertPiiTypeToDataTypePipe } from 'src/app/shared/pipes/convert-pii-type-to-data-type.pipe';

@Injectable({
	providedIn: 'root',
})
export class PiiDataTypesService {
    private readonly loggerName: string = 'PiiDataTypesService';

    //get the page system id from routes in router-outlet
    private piiDataTypesSchemaNames: BehaviorSubject<string[]> = new BehaviorSubject([]);
	piiDataTypesSchemaNames$ = this.piiDataTypesSchemaNames.asObservable();

    constructor(private apiClientPiiClassifierService: ApiClientPiiClassifierService,
				private piiDataTypesQuery: PiiDataTypesQuery,
				private piiDataTypesStore: PiiDataTypesStore,
				private systemsQuery: SystemsQuery,
				private dataTypesQuery: DataTypesQuery,
				private convertPiiTypeToDataTypePipe: ConvertPiiTypeToDataTypePipe,
				private frameworksService: FrameworksService,
				private policiesService: PoliciesService,
                private logger: LoggerService) { }

    getPiiSystemDataTypes(systemId: string, integrationId: string): Observable<PiiSystemDataTypes> {
		const loading$ = this.piiDataTypesQuery.selectLoading();

      const set$ = loading$.pipe(
          filter(response => !response),
          switchMap(() => this.piiDataTypesQuery.selectAll())
      );

      const notSet$ = loading$.pipe(
          filter(response => response),
          switchMap(() => this.getPiiSystemDataTypesServer(systemId, integrationId)),
          switchMap(() => this.piiDataTypesQuery.selectAll()),
      );

      return merge(set$, notSet$).pipe(
		first(),
		map(data => ({schemaNames: this.piiDataTypesSchemaNames.getValue(), piiDataTypeItem: data})),
		tap(() => this.piiDataTypesStore.setLoading(false))
	  );
	}

	getPiiSystemDataTypesServer(systemId: string, integrationId: string): Observable<PiiSystemDataTypesResponse> {
		if (integrationId === 'all') integrationId = null;
		return this.apiClientPiiClassifierService.getPiiSystemDataTypes(systemId, integrationId).pipe(
			tap(res => this.piiDataTypesSchemaNames.next(res.schemaNames)),
			tap(res => this.piiDataTypesStore.set(this.prepareData(res.tableData, systemId))),
        	catchError(error => this.handleError('getPiiSystemDataTypes()', error))
		);
	}

	// update all relevant data for view before inserting to store (easier for filtering and viewing)
	prepareData(datatypeItemsResponse: PiiDataTypeItemResponse[], systemId: string): PiiDataTypeItem[] {
		const system = this.systemsQuery.getEntity(systemId);
		return datatypeItemsResponse?.map(s => {
		  const dataType = this.dataTypesQuery.getEntity(this.convertPiiTypeToDataTypePipe.transform(s.piiDataType));
	
		  return {
			...s,
			policyViolation: this.policiesService.getDataTypeInSystemViolated(dataType, system),
			frameworks: this.frameworksService.getFrameworksChips([dataType]),
			dataType,
		  } as PiiDataTypeItem;
		});
	  }

    getPiiSystemDataTypesDeepDive(id: string, dataTypes: string[]): Observable<PiiDataTypesDeepDiveResponse> {
		return this.apiClientPiiClassifierService.getPiiSystemDataTypesDeepDive(id, dataTypes).pipe(
			catchError(error => this.handleError('getPiiSystemDataTypesDeepDive()', error))
		);
	}

    handleError(functionName: string, error: Error): Observable<never> {
        this.logger.error(this.loggerName, `${functionName} Error: ${error.name} ,${error.message}`);
        return EMPTY;
    }

	resetPiiDataTypesData(): void {
		this.piiDataTypesSchemaNames.next([]);
		this.piiDataTypesStore.remove();
		this.piiDataTypesStore.setLoading(true);
	}

	exportDataType(systemId: string, piiDataTypes: string[], dataType: string): Observable<ExportDataTypesResponse> {
		return this.apiClientPiiClassifierService.exportByDataTypes(systemId, piiDataTypes, dataType);
	}

    get schemaNames(): string[] {
        return this.piiDataTypesSchemaNames.getValue();
    }

}
