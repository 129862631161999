import { Pipe, PipeTransform } from '@angular/core';

import { ProfileQuery } from 'src/app/profile/state/profile.query';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { CollaboratorsChip } from 'src/app/ai-assessments/dialogs/ai-assessment-share-dialog/interface/collaborator';
import { AiAssessmentInstance, AiAssessmentPage } from 'src/app/api/models/ai-assessments/ai-assessments.interface';
import { AiAssessmentCollaborationState } from '../models/ai-assessments.enum';
import { AiAssessmentsService } from '../services/ai-assessments.service';
import { firstValueFrom } from 'rxjs';

@Pipe({
    standalone: true,
    name: 'collaboratorsChip'
})
export class CollaboratorsChipPipe implements PipeTransform {

    constructor(
        private contentPipe: ContentPipe,
        private profileQuery: ProfileQuery,
        private aiAssessmentsService: AiAssessmentsService,
    ) {}

    async transform(instance: AiAssessmentInstance | Partial<AiAssessmentPage>): Promise<CollaboratorsChip> {
        switch (instance?.collabState) {
            case AiAssessmentCollaborationState.Shared:
                const email = this.profileQuery.getAgentEmail();
                const lockStatus = await firstValueFrom(this.aiAssessmentsService.getLockStatus(instance.id));    

                if (lockStatus.isLocked) {
                    if (lockStatus.lockedBy === email) {
                        return {
                            text: this.contentPipe.transform('ai-assessments-collaboration.youAreEditingBadge'),
                            backgroundColor: 'var(--mine-purple-light-2)',
                            textColor: 'var(--mine-blue-dark)',
                            iconColor: 'var(--mine-purple-dark)'
                        };
                    }
                    return {
                        text: this.contentPipe.transform('ai-assessments-collaboration.editingBadge'),
                        backgroundColor: 'var(--mine-light-yellow)',
                        textColor: 'var(--mine-blue-dark)',
                        iconColor: 'var(--avatar-orange)'
                    };
                }
                return {
                    text: this.contentPipe.transform('ai-assessments-collaboration.sharedBadge'),
                    backgroundColor: 'var(--mine-grey-light-2)',
                    textColor: 'var(--mine-blue-dark)',
                    iconColor: 'var(--mine-grey-normal)'
                };
                
            case AiAssessmentCollaborationState.Submitted:
                return {
                    text: this.contentPipe.transform('ai-assessments-collaboration.completedBadge'),
                    backgroundColor: 'var(--avatar-blue-dark)',
                    textColor: 'var(--mine-white)',
                    iconColor: 'var(--mine-white)'
                };

            default:
                return null;
        }
    }
}