<h1 class="mine-title-m">{{'dashboard.title' | content}}</h1>

<div class="looker__container">
    <div class="looker__loading" *rxIf="loading()">
        {{ 'common.loading' | content }}
    </div>

    <ng-container *rxIf="(url$ | async) as url">
        <iframe
            class="looker__iframe"
            [src]="url"
            width="100%"
            frameborder="0"
            (load)="onIframeLoad()">
        </iframe>
    </ng-container>
</div>