import { Injectable } from "@angular/core";
import { RxState } from "@rx-angular/state";
import { CompanyGroupsState } from "../models/groups.interface";

@Injectable({ 
	providedIn: 'root' 
})
export class CompanyGroupsStore extends RxState<CompanyGroupsState> {
    private readonly initState = {
        loading: true,
        groups: new Map(),
        activeGroupId: '' //selected group
    } as CompanyGroupsState;

    constructor() {
        super();
        this.set({ ...this.initState }); // set the initial state of the store
    }

    resetState(): void {
        this.set({...this.initState });
    }
}