import { DatePipe } from '@angular/common';
import { fromWorker } from 'observable-webworker';
import { ElementRef , Injectable, Injector} from '@angular/core';
import { catchError , EMPTY, expand, first, from, map, Observable, of, reduce, switchMap, tap} from 'rxjs';

import { ContentPipe } from '../services/content/content.pipe';
import { CSVFile } from './csv-convertor.interface';
import { LoggerService } from '../logger/logger.service';
import { FilterCategory } from 'src/app/core/models/filtering.interface';
import { MineSort } from 'src/app/shared/mine-sort/mine-sort.interface';
import { MineSnackbarService } from 'src/app/shared/mine-snackbar/mine-snackbar.service';
import { MineSnackbarType } from 'src/app/shared/mine-snackbar/mine-snackbar-type';
import { EmployeesService } from 'src/app/employees/state/employees.service';
import { EmployeeDataMapping } from 'src/app/api/models/data-mapping/data-mapping.interface';
import { EmployeesFilter } from 'src/app/api/models/employees/employees.intrface';
import {
  EmployeesTableCsvHelper
} from 'src/app/employees/employees-managment/employees-table/employees-table-csv-helper';
import { IntegrationSystem, TrashSystem, UnverifiedSystem } from 'src/app/api/models/systems/systems.interface';
import { RadarTableCsvHelper } from 'src/app/radar/radar-table/radar-table-csv-helper';
import { UnverifiedSystemsQuery } from 'src/app/radar/state/unverified-systems.query';
import { UnusedEmployeesService } from '../unused-assets/unused-employees/state/unused-employees.service';
import { SystemsColumnKeys } from 'src/app/systems/models/systems-column.enum';
import { SystemsTableCsvHelper } from 'src/app/systems/systems-home/systems-table/systems-table-csv-helper';
import { SystemsQuery } from 'src/app/systems/state/systems.query';
import { RequestsQuery } from '../requests/state/requests.query';
import { RequestsService } from '../requests/state/requests.service';
import { RequestItem } from '../api/models/requests/requests.interface';
import { RequestsStatusEnum } from 'src/app/requests/models/requests-list-type.enum';
import { RequestsTableCsvHelper } from 'src/app/requests/requests-table/requests-table-csv-helper';
import { RequestsListPayload, RequestsSearchPayload } from '../requests/models/requests.interface';
import { TrashSystemsQuery } from 'src/app/systems/state/trash/trash-systems.query';
import { TrashSystemsTableCsvHelper } from 'src/app/unused-assets/trash-systems/trash-systems-table-csv-helper';
import { TablesEnum } from 'src/app/shared/table-state-service/table-state.enum';
import { TableStateService } from 'src/app/shared/table-state-service/table-state.service';
import { PaginationCursor } from '../shared/mine-pagination/mine-pagination.interface';

import * as FileSaver from "file-saver";
import * as sanitizeHtml from 'sanitize-html';
import {PiiDataTypesQuery} from "../pii-classifier/pii-system-findings/states/pii-data-types-state/pii-data-types.query";
import {PiiDataTypeItem} from "../pii-classifier/models/pii-classifier.interface";
import {
  PiiDataTypesTableCsvHelper
} from "../pii-classifier/pii-system-findings/pii-system-data-types/data-types-table/data-types-table-csv-helper";
import {SystemSchemaQuery} from "../pii-classifier/pii-system-findings/states/system-schema/system-schema.query";
import {PiiSchemaItem} from "../api/models/pii-classifier/pii-classifier.interface";
import {
  PiiSchemasTableCsvHelper
} from "../pii-classifier/pii-system-findings/pii-system-schema/schema-table/schema-table-csv-helper";
import { AiAssessmentsService } from '../ai-assessments/services/ai-assessments.service';
import { AiAssessmentsTableCsvHelper } from '../ai-assessments/services/ai-assessments-table-csv-helper';
import { AiAssessmentInstance } from '../api/models/ai-assessments/ai-assessments.interface';
import { RiskRegistryTableService } from '../risks/services/risk-registry-table.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { Risk } from '../api/models/risks/risks.interface';
import { RiskRegistryTableCsvHelper } from '../risks/services/risk-registry-table-csv-helper';
import { IntegrationPreviewCsvHelper } from '../dsr/dsr-ticket/dsr-ticket-content/dsr-ticket-processing/dialogs/integrations-preview-dialog/integrations-preview-csv-helper';

@Injectable({
  providedIn: 'root'
})
export class CsvConvertorService {

  private readonly loggerName: string = 'CsvConvertorService';

  private readonly HTML_TAGS_TRIMMER = '###';

  private readonly HTML_DISALLOWED_TAGS = ['mine-button-primary', 'mine-button-secondary', 'mine-button-tertiary'];

  private readonly FILENAME_SUFIX = `Report - ${this.datePipe.transform(new Date(), 'dd MMM yyyy')}.csv`;

  private readonly RESULTS_PER_PAGE = 500;

  constructor(
    private injector: Injector,
    private datePipe: DatePipe,
		private logger: LoggerService,
    private contentPipe: ContentPipe,
    private requestsQuery: RequestsQuery,
    private requestsService: RequestsService,
    private employeesService: EmployeesService,
    private tableStateService: TableStateService,
    private snackBarService: MineSnackbarService,
    private systemsQuery: SystemsQuery,
    private trashSystemsQuery: TrashSystemsQuery,
    private aiAssessmentsTableCsvHelper: AiAssessmentsTableCsvHelper,
    private riskRegistryTableService: RiskRegistryTableService,
    private riskRegistryTableCsvHelper: RiskRegistryTableCsvHelper,
    private unverifiedSystemsQuery: UnverifiedSystemsQuery,
    private unusedEmployeesService: UnusedEmployeesService,
    private aiAssessmentsService: AiAssessmentsService,
    private radarTableCsvHelper: RadarTableCsvHelper,
    private piiDataTypesTableCsvHelper: PiiDataTypesTableCsvHelper,
    private piiSchemasTableCsvHelper: PiiSchemasTableCsvHelper,
    private systemsTableCsvHelper: SystemsTableCsvHelper,
    private requestsTableCsvHelper: RequestsTableCsvHelper,
    private employeesTableCsvHelper: EmployeesTableCsvHelper,
    private trashSystemsTableCsvHelper: TrashSystemsTableCsvHelper,
    private piiDataTypesQuery: PiiDataTypesQuery,
    private piiSchemaQuery: SystemSchemaQuery,
    private integrationPreviewTableCsvHelper: IntegrationPreviewCsvHelper,
	) { }

  generateCSV(table: TablesEnum, headerElement: ElementRef, companyName?: string): void {
    this.logger.debug(this.loggerName, `Received generateCSV request, table: ${table}, headerElement: ${headerElement?.nativeElement?.innerHTML}`);
    this.generateCSVFile(table, headerElement, undefined, companyName);
  }

  generateCSVFromData(table: TablesEnum, header: ElementRef | string[], dataArr?: any[], companyName?: string, alternativeFileName?: string): void {
    this.logger.debug(this.loggerName, `Received generateCSVFromData request, table: ${table}`);
    this.generateCSVFile(table, header, dataArr, companyName, alternativeFileName);
  }

  private generateCSVFile(table: TablesEnum, header: ElementRef | string[], dataArr?: any[], companyName?: string, alternativeFileName?: string): void {
    // Show permanent snackbar in case table is paginated
    if ([TablesEnum.Employees, TablesEnum.Requests, TablesEnum.UnusedEmployees].includes(table)) {
      this.snackBarService.showPermanent(MineSnackbarType.Notification, this.contentPipe.transform('common.csvProcessingText'), true);
    }
    else {
      this.snackBarService.showTimed(MineSnackbarType.Notification, this.contentPipe.transform('common.csvProcessingText'));
    }

    // header can be the table header DOM element Or columns as regular strings array
    const data = [
      this.getColumns(table, header),
    ];
    
    const worker = this.getWorker(true);
    const fileName = this.getFileName(table, companyName, alternativeFileName);

    if (worker) {
      fromWorker(() => worker, this.getRequest(table, dataArr)).pipe(
        first(),
        tap((response: string[][]) => response.forEach(row => data.push(row))),
        map(() => this.postCsvFileMessage({ fileName, data } as CSVFile)),
        catchError(error => {
          this.snackBarService.hide();
          this.snackBarService.showTimed(MineSnackbarType.Error, this.contentPipe.transform('common.csvError'));
          this.logger.error(this.loggerName, `Received error response from web worker: ${error}`);
          return EMPTY;
        }),
      ).subscribe();
    }
  }

  private getColumns(table: TablesEnum, header: ElementRef | string[]): string[] {
    if ([TablesEnum.IntegrationPreview].includes(table)) {
      return header as string[];
    }

    return this.sanitizeHtml((header as ElementRef)?.nativeElement?.innerHTML || '');
  }

  private getFileName(table: TablesEnum, companyName?: string, alternativeFileName?: string): string {
    if (alternativeFileName) {
      return alternativeFileName;
    }

    return companyName ? `${table} ${companyName} ${this.FILENAME_SUFIX}` : `${table} ${this.FILENAME_SUFIX}`;
  }

  sanitizeHtml(html: string): string[] {
    this.logger.debug(this.loggerName, `Sanitizing the following html payload: ${html}`);

    const that = this;
    const sanitized = sanitizeHtml(html, {
      exclusiveFilter: function(frame) {
        return !frame.text?.trim() || that.HTML_DISALLOWED_TAGS.includes(frame.tag);
      },
      transformTags: {
        '*': sanitizeHtml.simpleTransform('span', {})
      }
    });

    const response = sanitized
      .replaceAll('<span>', '')
      .replaceAll('</span></span>', this.HTML_TAGS_TRIMMER)
      .replaceAll('</span>', this.HTML_TAGS_TRIMMER)
      .split(this.HTML_TAGS_TRIMMER)
      .map(item => item?.trim())
      .filter(item => !!item); 

    this.logger.debug(this.loggerName, `Sanitize response: ${response}`);
    return response;
  }

  private postCsvFileMessage(file: CSVFile): void {
    const worker = this.getWorker();
    if (worker) {
      worker.onmessage = ({ data }) => {
        this.logger.debug(this.loggerName, `Received blob from web worker, fileName: ${file.fileName}`);
        FileSaver.saveAs(data, file.fileName);
      };
      worker.onmessageerror = (event) => {
        this.logger.error(this.loggerName, `Received error response from web worker: ${event}`);
      };
  
      this.logger.debug(this.loggerName, `Sending post message to web worker: data: ${file.data}`);
      worker.postMessage(file);
    }
  }

  private getWorker(async?: boolean): Worker {
    if (typeof Worker !== 'undefined') {
      return async ? 
        new Worker(new URL('./csv-convertor-async.worker', import.meta.url), { type: 'module' }) : 
        new Worker(new URL('./csv-convertor.worker', import.meta.url), { type: 'module' });
    }
    else {
      this.snackBarService.hide();
      this.snackBarService.showTimed(MineSnackbarType.Error, this.contentPipe.transform('common.csvError'));
      this.logger.error(this.loggerName, 'Web Workers are not supported in this environment');
      return;
    }
  }

  // If data arg is presented export the data array instead of fetching from the store
  private getRequest(table: TablesEnum, data: any[] | undefined): Observable<string[][]> {
    switch (table) {
      case TablesEnum.Employees:
        return this.getEmployeesRequest();

      case TablesEnum.Radar:
        return this.getRadarRequest();

      case TablesEnum.Systems:
        return this.getSystemsRequest();
        
      case TablesEnum.Requests:
        return this.getRequestsRequest();
        
      case TablesEnum.TrashSystems:
        return this.getTrashSystemsRequest();

      case TablesEnum.UnusedEmployees:
        return this.getUnusedEmployeesRequest();

      case TablesEnum.PiiClassifierDataTypes:
        return this.getPiiDataTypesRequest();

      case TablesEnum.PiiClassifierSchema:
        return this.getPiiSchemaRequest();

      case TablesEnum.Assessments:
        return this.getAssessmentsRequest(data);
      
      case TablesEnum.RiskRegistry:
        return this.getRiskRegistry();

      case TablesEnum.IntegrationPreview:
        return this.getIntegrationPreviewRequest(data);

      default: {
        const error = `table ${table} is not supported`;
        this.logger.error(this.loggerName, error);
        throw new Error(error);
      }
    }
  }

  private getEmployeesRequest(): Observable<any> {
    let page = 1;
    const sort = this.tableStateService.getTableSort(TablesEnum.Employees) as MineSort;
    const filter = this.tableStateService.getTableFilters(TablesEnum.Employees) as EmployeesFilter;
    const columns = this.tableStateService.getTableColumns(TablesEnum.Employees) as Map<string, string>;
    
    return this.employeesService.getEmployeesPage(page, this.RESULTS_PER_PAGE, filter, sort).pipe(
      expand(response => response.lastPage !== page ? this.employeesService.getEmployeesPage(++page, this.RESULTS_PER_PAGE, filter, sort) : EMPTY),
      reduce((acc, current) => acc.concat(current.data), [])
    ).pipe(
      map((employees: EmployeeDataMapping[]) => this.parseResponse(TablesEnum.Employees, employees, columns)),
    );
  }

  private getRadarRequest(): Observable<any> {
    const search = this.tableStateService.getTableSearch(TablesEnum.Radar) as string;
    const sort = this.tableStateService.getTableSort(TablesEnum.Radar) as MineSort;
    const filter = this.tableStateService.getTableFilters(TablesEnum.Radar) as FilterCategory[];
    const columns = this.tableStateService.getTableColumns(TablesEnum.Radar) as Set<string>;
    
    return this.unverifiedSystemsQuery.selectSystemsByFiltersAndSearchTerm(filter, search, sort).pipe(
      map((systems: UnverifiedSystem[]) => this.parseResponse(TablesEnum.Radar, systems, columns)),
    );
  }

  private getRiskRegistry(): Observable<any> {
    const columns = this.tableStateService.getTableColumns(TablesEnum.RiskRegistry) as Set<string>;
    
    return toObservable(this.riskRegistryTableService.getRiskRegistry(true), { injector: this.injector }).pipe(
      switchMap((risks: Risk[]) => from(this.parseResponseAsync(TablesEnum.RiskRegistry, risks, columns))),
    );
  }

  private getPiiDataTypesRequest(): Observable<any> {
    const search = this.tableStateService.getTableSearch(TablesEnum.PiiClassifierDataTypes) as string;
    const sort = this.tableStateService.getTableSort(TablesEnum.PiiClassifierDataTypes) as MineSort;
    const filter = this.tableStateService.getTableFilters(TablesEnum.PiiClassifierDataTypes) as FilterCategory[];
    const columns = this.tableStateService.getTableColumns(TablesEnum.PiiClassifierDataTypes) as Set<string>;

    return this.piiDataTypesQuery.selectByFiltersAndSearchTerm(filter, search, sort).pipe(
      map((dataTypeItems: PiiDataTypeItem[]) => this.parseResponse(TablesEnum.PiiClassifierDataTypes, dataTypeItems, columns)),
    );
  }

  private getPiiSchemaRequest(): Observable<any> {
    const search = this.tableStateService.getTableSearch(TablesEnum.PiiClassifierSchema) as string;
    const sort = this.tableStateService.getTableSort(TablesEnum.PiiClassifierSchema) as MineSort;
    const filter = this.tableStateService.getTableFilters(TablesEnum.PiiClassifierSchema) as FilterCategory[];
    const columns = this.tableStateService.getTableColumns(TablesEnum.PiiClassifierSchema) as Set<string>;

    return this.piiSchemaQuery.selectSchemasByFilters(filter, search, sort).pipe(
      map((schemaItems: PiiSchemaItem[]) => this.parseResponse(TablesEnum.PiiClassifierSchema, schemaItems, columns)),
    );
  }

  private getSystemsRequest(): Observable<any> {
    const search = this.tableStateService.getTableSearch(TablesEnum.Systems) as string;
    const sort = this.tableStateService.getTableSort(TablesEnum.Systems) as MineSort;
    const filter = this.tableStateService.getTableFilters(TablesEnum.Systems) as FilterCategory[];
    const columns = this.tableStateService.getTableColumns(TablesEnum.Systems) as Set<string>;

    return this.systemsQuery.selectSortedIntegrations(sort.active as SystemsColumnKeys, sort.direction, filter, search).pipe(
      map((systems: IntegrationSystem[]) => this.parseResponse(TablesEnum.Systems, systems, columns)),
    );
  }

  private getRequestsRequest(): Observable<any> {
    const search = this.tableStateService.getTableSearch(TablesEnum.Requests) as string;
    const filter = this.tableStateService.getTableFilters(TablesEnum.Requests) as FilterCategory[];
    const columns = this.tableStateService.getTableColumns(TablesEnum.Requests) as Set<string>;
    const sort = this.tableStateService.getTableSort(TablesEnum.Requests);

    let currentCursor = this.requestsService.getCursor(); // save the current cursor
    let cursor = { before: null, after: null } as PaginationCursor;
    
    return this.getPaginatedRequestsQuery(cursor, filter, status as RequestsStatusEnum, sort, search).pipe(
      expand(() => !!this.requestsService.getCursor()?.after ? this.getPaginatedRequestsQuery(this.requestsService.getCursor(), filter, status as RequestsStatusEnum, sort, search) : EMPTY),
      reduce((acc, current) => acc.concat(current), [])
    ).pipe(
      map((requests: RequestItem[]) => this.parseResponse(TablesEnum.Requests, requests, columns)),
      tap(() => this.requestsService.setCursor(currentCursor)) // reset cursor after finishing export to CSV
    );
  }

  private getPaginatedRequestsQuery(cursor: PaginationCursor, filters: FilterCategory[], status: RequestsStatusEnum, sort: MineSort, search?: string) {
		const payload = search ? 
      this.requestsQuery.getSearchPayload(search, sort) : 
      this.requestsQuery.getListPayload(filters, sort);

		if (cursor?.after) {
			payload.after = cursor.after;
		}

		return search ? 
      this.requestsService.search(<RequestsSearchPayload>payload) : 
      this.requestsService.list(<RequestsListPayload>payload);
  }

  private getTrashSystemsRequest(): Observable<any> {
    const search = this.tableStateService.getTableSearch(TablesEnum.TrashSystems) as string;
    const sort = this.tableStateService.getTableSort(TablesEnum.TrashSystems) as MineSort;
    const columns = this.tableStateService.getTableColumns(TablesEnum.TrashSystems) as Set<string>;
    
    return this.trashSystemsQuery.selectTrashedSystems(sort, search).pipe(
      map((systems: TrashSystem[]) => this.parseResponse(TablesEnum.TrashSystems, systems, columns)),
    );
  }

  private getUnusedEmployeesRequest(): Observable<any> {
    let page = 1;
    const sort = this.tableStateService.getTableSort(TablesEnum.UnusedEmployees) as MineSort;
    const filter = this.tableStateService.getTableFilters(TablesEnum.UnusedEmployees) as EmployeesFilter;
    const columns = this.tableStateService.getTableColumns(TablesEnum.UnusedEmployees) as Map<string, string>;

    return this.unusedEmployeesService.getUnusedEmployeesPage(page, this.RESULTS_PER_PAGE, filter, sort).pipe(
      expand(response => response.lastPage !== page ? this.unusedEmployeesService.getUnusedEmployeesPage(++page, this.RESULTS_PER_PAGE, filter, sort) : EMPTY),
      reduce((acc, current) => acc.concat(current.data), [])
    ).pipe(
      map((employees: EmployeeDataMapping[]) => this.parseResponse(TablesEnum.UnusedEmployees, employees, columns)),
    );
  }

  private getAssessmentsRequest(dataArr?: any[]): Observable<any> {
    const search = this.tableStateService.getTableSearch(TablesEnum.Assessments) as string;
    const sort = this.tableStateService.getTableSort(TablesEnum.Assessments) as MineSort;
    const filter = this.tableStateService.getTableFilters(TablesEnum.Assessments) as FilterCategory[];
    const columns = this.tableStateService.getTableColumns(TablesEnum.Assessments) as Set<string>;

    if (dataArr) {
      return from(
        this.parseResponseAsync(TablesEnum.Assessments, dataArr, columns)
      );
    }

    const data = this.aiAssessmentsService.getAssessments(true)();
    const searched = this.aiAssessmentsService.searchAssessments(data as AiAssessmentInstance[], search);
    const filtered = this.aiAssessmentsService.filterAssessments(searched, filter);
    const assessments = this.aiAssessmentsService.sortAssessments(filtered, sort);
    
    return from(
      this.parseResponseAsync(TablesEnum.Assessments, assessments, columns)
    );
  }

  private parseResponse(table: TablesEnum, response: any[], columns: Map<string, string> | Set<string>): string[][] {
    const helperFunction = this.getHelperFunction(table);
    return this.mapResponseData(response, columns, helperFunction);
  }

  private async parseResponseAsync(table: TablesEnum, response: any[], columns: Map<string, string> | Set<string>): Promise<string[][]> {
    const helperFunction = this.getHelperFunction(table);
    return this.mapResponseDataAsync(response, columns, helperFunction);
  }

  private getHelperFunction(table: TablesEnum): any {
    switch (table) {
      case TablesEnum.Employees:
      case TablesEnum.UnusedEmployees:
        return this.employeesTableCsvHelper;

      case TablesEnum.Radar:
        return this.radarTableCsvHelper;

      case TablesEnum.PiiClassifierDataTypes:
        return this.piiDataTypesTableCsvHelper;

      case TablesEnum.PiiClassifierSchema:
        return this.piiSchemasTableCsvHelper;

      case TablesEnum.Systems:
        return this.systemsTableCsvHelper; 

      case TablesEnum.Requests:
        return this.requestsTableCsvHelper;

      case TablesEnum.TrashSystems:
        return this.trashSystemsTableCsvHelper;
      
      case TablesEnum.Assessments:
        return this.aiAssessmentsTableCsvHelper;
      
      case TablesEnum.RiskRegistry:
        return this.riskRegistryTableCsvHelper;

      case TablesEnum.IntegrationPreview:
        return this.integrationPreviewTableCsvHelper;

      default: {
        this.logger.error(this.loggerName, `table ${table} is not supported`);
        return null;
      }
    }
  }

  private mapResponseData(response: any[], columns: Map<string, string> | Set<string>, helperFunction: any): string[][] {
    const data = [];
    response.forEach(item => {
      const itemData = [];
      if (columns instanceof Map) {
        for (let [key, _] of columns) {
          itemData.push(helperFunction.getValueByKey(item, key));
        }
      }
      else {
        columns.forEach(key => {
          itemData.push(helperFunction.getValueByKey(item, key));
        });
      }
      data.push(itemData);
    });
    return data;
  }

  private async mapResponseDataAsync(response: any[], columns: Map<string, string> | Set<string>, helperFunction: any): Promise<string[][]> {
    const rowPromises = response.map(async item => {
      const itemPromises: Promise<string>[] = [];
      columns.forEach(async key => {
        itemPromises.push(helperFunction.getValueByKey(item, key as any));
      });
      const itemData = await Promise.all(itemPromises);
      return itemData;
    });
    return Promise.all(rowPromises);
  }

  private getIntegrationPreviewRequest(dataArr: any[]): Observable<string[][]> {
    const columns = new Set<string>(Object.keys(dataArr[0]));

    if (dataArr) {
      return of(this.parseResponse(TablesEnum.IntegrationPreview, dataArr, columns));
    }
  }
}