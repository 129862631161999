import { RxState } from "@rx-angular/state";
import { rxActions } from "@rx-angular/state/actions";
import { DestroyRef, Inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { BehaviorSubject, catchError, combineLatest, debounceTime, delay, distinctUntilChanged, filter, finalize, first, map, merge, Observable, of, startWith, switchMap, tap } from "rxjs";

import { LoggerService } from "../../../../src/app/logger/logger.service";
import { Risk, RiskCatalog, RiskRateEnum, RiskRegistryColumnEnum, RiskRegistryFilteringGroupEnum, RiskRegistryFilters } from "src/app/api/models/risks/risks.interface";
import { RiskRegistryTableState } from "./risks-registry-table.state.interface";
import { TableColumn } from "src/app/shared/models/table-column.interface";
import { ContentPipe } from "src/app/services/content/content.pipe";
import { RiskRegistryActions } from "../models/risk-registry-actions.interface";
import { FilterCategory } from "src/app/core/models/filtering.interface";
import { SystemInstance } from "src/app/systems/models/systems.interface";
import { SentenceCasePipe } from "src/app/shared/pipes/sentence-case.pipe";
import { AiAssessmentsService, entityId } from "src/app/ai-assessments/services/ai-assessments.service";
import { RisksCatalogService } from "../services/risks-catalog.service";
import { CustomValuesQuery } from "src/app/company-settings/state/custom-values/custom-values.query";
import { CustomValueTypeEnum } from "src/app/api/models/company-settings/custom-values.enum";
import { CustomValue } from "src/app/api/models/company-settings/custom-values.interface";
import { AiAssessmentTemplateEnum } from "src/app/ai-assessments/models/ai-assessments.enum";
import { FeatureFlags } from "src/app/api/models/profile/profile-feature-flags.enum";
import { FeatureFlagQuery } from "src/app/feature-flag/state/feature-flag.query";
import { API_CLIENT_RISKS_SERVICE } from "src/app/api/injectors/api-client-risks.token";
import { BaseApiClientRisksService } from "src/app/api/base-api-client-risks.service";
import { AiAssessmentInstance } from "src/app/api/models/ai-assessments/ai-assessments.interface";
import { MineSort } from "src/app/shared/mine-sort/mine-sort.interface";

@Injectable({
    providedIn: 'root'
})
export class RiskRegistryTableStore extends RxState<RiskRegistryTableState> {

    private readonly loggerName: string = 'RiskRegistryTableStore';
    
    private readonly initState = {
        empty: undefined,
        loading: true,
        disabled: false,
        riskRegistry: undefined,
        columns: undefined,
        filters: undefined,
        search: undefined,
        sort: undefined,
        view: undefined,
    } as RiskRegistryTableState;

    public actions = rxActions<RiskRegistryActions>();

    private initial$ = new BehaviorSubject<Map<number, Risk>>(null);

    constructor(
        private logger: LoggerService,
        @Inject(API_CLIENT_RISKS_SERVICE) private apiClientRisksService: BaseApiClientRisksService,
        private contentPipe: ContentPipe,
        private destroyRef: DestroyRef,
        private sentenceCasePipe: SentenceCasePipe,
        private aiAssessmentsService: AiAssessmentsService,
        private risksCatalogService: RisksCatalogService,
        private customValuesQuery: CustomValuesQuery,
        private featureFlagQuery: FeatureFlagQuery
    ) {
        super();
        this.set(this.initState);
        this.set({ 'columns': this.getColumns() });
        
        this.getRisksFromServer().pipe(
            delay(300),
            first(),
            tap(risks => this.initial$.next(risks)),
            tap(risks => this.set({
                'riskRegistry': risks,
                'loading': false,
                'empty': !risks?.size
            })),
        ).subscribe();

        this.connect('filters', this.selectFilters());

        const search$ = this.actions.changeSearchValue$.pipe(
            startWith(''),
            debounceTime(300),
            distinctUntilChanged(),
        );

        const filters$ = this.actions.changeFiltersValue$.pipe(
            map(filters => this.getQueryParams(filters)),
            startWith({})
        );

        const sort$ = this.actions.changeSortValue$.pipe(
            startWith(null)
        );

        const riskDeleted$ = this.actions.riskDeleted$.pipe(
            startWith(null),
        );

        const assessmentsChange$ = this.actions.assessmentsChange$.pipe(
            startWith(null),
        );

        merge([assessmentsChange$, riskDeleted$]).pipe(
            switchMap(() => this.getRisksFromServer()),
            tap(risks => this.initial$.next(risks)),
            tap(risks => this.set({'riskRegistry': risks})),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe();

        this.connect(
            'riskRegistry',
            combineLatest([search$, filters$, sort$, riskDeleted$, assessmentsChange$]).pipe(
                switchMap(([search, filters, sort]) => this.getRisksFromServer(search, filters as RiskRegistryFilters, sort)),
            )
        );
    }

    getInitialData(): Observable<Map<number, Risk>> {
        return this.initial$.asObservable();
    }

    private getColumns(): Map<string, TableColumn> { 
        let columns: TableColumn[] = [...this.contentPipe.transform('risks.riskRegistry.tableColumns')];
        
        if (this.featureFlagQuery.getFlag(FeatureFlags.AssessmentCollaborationPhase1)) {
            columns.push(this.contentPipe.transform('risks.riskRegistry.riskRegistryCollaboratorsColumn'));
        }

        if (this.featureFlagQuery.getFlag(FeatureFlags.DevExportRiskRegistry)) {
            columns.push(...this.contentPipe.transform('risks.riskRegistry.riskRegistryAdditionalColumns'));
        }

        if(this.featureFlagQuery.getFlag(FeatureFlags.AssessmentCustomTags)) {
            const typeColumnIndex = columns.findIndex(column => column.key === RiskRegistryColumnEnum.AssessmentType);

            if (typeColumnIndex !== -1) {
                columns.splice(
                    typeColumnIndex + 1,
                    0,
                    this.contentPipe.transform('risks.riskRegistry.riskRegistryLabelsColumn')
                );
            }
        }

        const map = new Map<string, TableColumn>();
        for (let column of columns) {
            map.set(column.key, column);
        }

        return map;
    }

    private getRisksFromServer(search?: string, filters?: RiskRegistryFilters, sort?: MineSort): Observable<Map<number, Risk> | undefined> {
        return this.aiAssessmentsService.selectLoading().pipe(
            filter(loading => !loading),
            switchMap(() => this.apiClientRisksService.getRiskRegistry(search, filters, sort)),
            first(),
            map(risks => new Map(risks.map(risk => ([risk.id, risk])))),
            map(risks => this.riskRegistryMapper(risks)),
            catchError(err => {
                console.error(err);
                this.logger.error(this.loggerName, err);
                return of(new Map());
            }),
        );
    }

    private riskRegistryMapper(risks: Map<number, Risk>): Map<number, Risk> {
        const map = new Map<number, Risk>();
        const assessments = this.aiAssessmentsService.getAssessments()() as Map<entityId, AiAssessmentInstance>;
        
        Array.from(risks.keys()).forEach(key => {
            const risk = risks.get(key);
            const assessmentId = risk?.assessmentPage?.id;

            map.set(key, {
                ...risks.get(key),
                assessmentPage: assessments.get(assessmentId) ?? risk.assessmentPage,
            });
        });
        
        return map;
    }

    selectFilters(): Observable<FilterCategory[]> {
        return this.select('loading').pipe(
            filter(loading => !loading),
            switchMap(() => combineLatest([
                this.selectAssessmentTypesFilterGroup(),
                this.selectDataSourcesFilterGroup(),
                this.selectDataTypesFilterGroup(),
                this.selectMitigationsFilterGroup()
            ])),
            map(([assessmentTypesFilter, dataSourcesFilter, riskTypesFilter, mitigationsTypesFilter]) => {
                
                const residualRiskFilter = 
                    this.getRiskFilterGroup(RiskRegistryFilteringGroupEnum.ResidualRisk, 
                        this.contentPipe.transform('risks.riskRegistry.residualRiskFilterGroup'));
                        
                const inherentRiskFilter = 
                    this.getRiskFilterGroup(RiskRegistryFilteringGroupEnum.InherentRisk, 
                        this.contentPipe.transform('risks.riskRegistry.inherentRiskFilterGroup'));

                const filters = [inherentRiskFilter, residualRiskFilter];
   
                if(assessmentTypesFilter?.options?.length) {
                    filters.push(assessmentTypesFilter);
                }

                if (dataSourcesFilter?.options?.length) {
                    filters.push(dataSourcesFilter);
                }

                if(riskTypesFilter?.options?.length) {
                    filters.push(riskTypesFilter);
                }

                if(mitigationsTypesFilter?.options?.length) {
                    filters.push(mitigationsTypesFilter);
                }
                
                return filters;
            }),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    private getRiskFilterGroup(id: string, label: string): FilterCategory {
        return {
            id,
            label,
            options: Object.keys(RiskRateEnum)?.map(rate => ({
                id: rate,
                label: rate,
                selected: false
            }))
        } as FilterCategory;
    }

    private selectAssessmentTypesFilterGroup(): Observable<FilterCategory> {
        return this.aiAssessmentsService.selectUniqueAssessmentsTypesWithRisks().pipe(
            first(),
            map(assessmentTypes => this.getTypesFilterGroup(assessmentTypes)),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    private selectDataSourcesFilterGroup(): Observable<FilterCategory> {
        return this.aiAssessmentsService.selectUniqueDataSourcesWithRisks().pipe(
            first(),
            map(dataSources => this.getDataSourcesFilterGroup(dataSources)),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    private selectDataTypesFilterGroup(): Observable<FilterCategory> {
        return this.risksCatalogService.selectRisksCatalog(true).pipe(
            first(),
            map(risksCatalog => this.getRiskTypesFilterGroup(risksCatalog as RiskCatalog[])),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    private selectMitigationsFilterGroup(): Observable<FilterCategory> {
        return this.customValuesQuery.selectCustomValuesByType(CustomValueTypeEnum.Mitigation).pipe(
            first(),
            map(mitigations => this.getMitigationsFilterGroup(mitigations)),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    private getTypesFilterGroup(types: string[]): FilterCategory {
        const cmsTypes = this.contentPipe.transform('ai-assessments.types');
        const options = types.reduce((acc, curr) => {
            if (!acc.some(item => cmsTypes[AiAssessmentTemplateEnum[item]] === cmsTypes[AiAssessmentTemplateEnum[curr]])) {
                acc.push(curr);
            }
            return acc;
        }, []).sort((a, b) => a.localeCompare(b));

        return {
            id: RiskRegistryFilteringGroupEnum.AssessmentType,
            label: this.contentPipe.transform('ai-assessments.typeFilterGroup'),
            options: options.map(type => ({
                id: type,
                label: cmsTypes[type],
                selected: false
            }))
        } as FilterCategory;
    }

    private getMitigationsFilterGroup(customValues: CustomValue[]) {
        const predefinedOptions = this.contentPipe.transform('ropa.mitigations')
            .map(({ key, value }) => ({ id: key, label: value}));

        const customOptions = customValues?.map(customValue => ({
            id: customValue.id,
            label: this.sentenceCasePipe.transform(customValue.name),
            selected: false
        }));

        return {
            id: RiskRegistryFilteringGroupEnum.Mitigation,
            label: this.contentPipe.transform('risks.riskRegistry.mitigationFilterGroup'),
            options: [...predefinedOptions, ...customOptions].sort((a, b) => a.label?.localeCompare(b.label)) ?? []
        } as FilterCategory;
    }
    
    private getDataSourcesFilterGroup(dataSources: SystemInstance[]): FilterCategory {
        return {
            id: RiskRegistryFilteringGroupEnum.DataSource,
            label: this.contentPipe.transform('risks.riskRegistry.dataSourceFilterGroup'),
            options: dataSources?.map(dataSource => ({
                id: dataSource.systemId,
                label: this.sentenceCasePipe.transform(dataSource.name),
                selected: false
            })).sort((a, b) => a.label?.localeCompare(b.label)) ?? []
        } as FilterCategory;
    }

    private getRiskTypesFilterGroup(riskCatalogs: RiskCatalog[]): FilterCategory {
        return {
            id: RiskRegistryFilteringGroupEnum.RiskType,
            label: this.contentPipe.transform('risks.riskRegistry.riskTypeFilterGroup'),
            options: riskCatalogs?.map(riskCatalog => ({
                id: riskCatalog.type,
                label: this.sentenceCasePipe.transform(riskCatalog.name),
                selected: false
            })).sort((a, b) => a.label?.localeCompare(b.label)) ?? []
        } as FilterCategory;
    }

    private getQueryParams(filterCategories: FilterCategory[]): RiskRegistryFilters {
        if (!filterCategories) return null;

        let filters: RiskRegistryFilters;
        
        for (let filter of filterCategories) {
            if(filter.id === RiskRegistryFilteringGroupEnum.InherentRisk) {
                filters = { ...filters, inherentRisk: filter.options.map(o => o.id) };
            }

            if(filter.id === RiskRegistryFilteringGroupEnum.ResidualRisk) {
                filters = { ...filters, residualRisk: filter.options.map(o => o.id) };
            }

            if(filter.id === RiskRegistryFilteringGroupEnum.Mitigation) {
                filters = { ...filters, mitigations: filter.options.map(o => o.label)?.join(',') };
            }

            if(filter.id === RiskRegistryFilteringGroupEnum.DataSource) {
                filters = { ...filters, dataSources: filter.options.map(o => o.id).join(',') };
            }

            if(filter.id === RiskRegistryFilteringGroupEnum.RiskType) {
                filters = { ...filters, riskTypes: filter.options.map(o => o.id) };
            }

            if(filter.id === RiskRegistryFilteringGroupEnum.AssessmentType) {
                filters = { ...filters, assessmentTypes: filter.options.map(o => o.id).join(',') };
            }
        }

        return filters;
    }

    setActiveFilters(updatedFilters: FilterCategory[]): void {        
        this.selectFilters().pipe(
            first(),
            tap(filters => this.set({ filters })),
            finalize(() => {
                const reduceFn = (oldState: RiskRegistryTableState) => ({
                    filters: oldState.filters.map(filter => {
                        const id = filter.id;
                        const updatedFilter = updatedFilters.find(f => f.id === id);
                        for (let o of filter.options) {
                            o.selected = updatedFilter?.options.find(option => option.id === o.id)?.selected ?? false;
                        }
                        return filter;
                    })
                });
                this.set(reduceFn);
            })).subscribe();
    }

    updateRisk(updatedRisk: Risk): void {
        const risksCatalog = this.get('riskRegistry');
        risksCatalog.set(updatedRisk.id, updatedRisk);
        this.set('riskRegistry', () => this.riskRegistryMapper(new Map(risksCatalog.entries())));
    }
}