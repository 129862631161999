import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { AuthResolverService } from './auth-resolver.service';
import { LoggedInUserGuard } from './services/logged-in-user.guard';
import { RoutesManager } from '../shared/models/routes.interfaces';

const routes: Routes = [
	{ 
		path: RoutesManager.login, 
		data: { changeBackground: true },
		canMatch: [ LoggedInUserGuard ],
		component: LoginComponent,
	},
	{
		path: RoutesManager.gmailCallback,
		component: LoginComponent,
		data: { changeBackground: true },
		resolve: {
			login: AuthResolverService
		}
	},
	{
		path: RoutesManager.microsoftCallback,
		component: LoginComponent,
		data: { changeBackground: true },
		resolve: {
			callback: AuthResolverService
		}
	},
	{
		path: RoutesManager.auth0Callback,
		component: LoginComponent,
		data: { changeBackground: true },
		resolve: {
			callback: AuthResolverService
		}
	},
	{
		path: RoutesManager.ssoCallback,
		component: LoginComponent,
		data: { changeBackground: true },
		resolve: {
			callback: AuthResolverService
		}
	},
	{ 
		path: RoutesManager.signup, 
		redirectTo: RoutesManager.login,
	},
];

@NgModule({
	imports: [
		RouterModule.forChild(routes)
	],
	exports: [
		RouterModule
	]
})
export class AuthRoutingModule { }
