import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MineButtonDirective } from "@mineos/mine-ui";
import { DialogRef } from "@ngneat/dialog";
import { ContentPipeModule } from "src/app/services/content/content-pipe.module";
import { MineTimerDirective } from "src/app/shared/directives/mine-timer.directive";
import { environment } from "src/environments/environment";

@Component({
    selector: 'assessment-idle-mode-dialog',
    templateUrl: './assessment-idle-mode-dialog.component.html',
    styleUrls: ['./assessment-idle-mode-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ContentPipeModule,
        MineButtonDirective,
        MineTimerDirective
    ]
})
export class AssessmentIdleModeDialogComponent {

    constructor(private dialogRef: DialogRef) {}

    time: string = '';

    readonly closeDialogCountdown = environment.collaboration.idleSessionDialog.closeDialogCountdown;

    stopEditing(): void {
        this.dialogRef.close(false);
    }

    continueEditing(): void {
        this.dialogRef.close(true);
    }
}