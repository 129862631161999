import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RequestsStatsEnum } from './models/requests/requests-stats.enum';
import { HttpClientHelperService } from './http-client-helper.service';
import { IntegrationActions } from './models/requests/integration-actions.interface';
import { IntegrationsIdenntifiersResponse, SearchTriggerResponse, SubmitTicketForm, TaskSearchResponse, TicketMetadata, UpdateTicketDetails } from './models/ticket/ticket.interface';
import { RequestsListPayload, RequestsPaginationPayload, RequestsSearchPayload } from '../requests/models/requests.interface';
import { AutopilotAssignedResponse, CountryCodesResponse, ExportAuditLogResponse, RequestItemDeep, RequestsResponse, RequestsStatsResponse } from './models/requests/requests.interface';
import { RequestFormResponse } from '../requests/request-form/models/interfaces';
import { SortDirectionEnum } from '../shared/sort-direction.enum';
import { RequestHistoryResponse } from './models/requests/request-history';

@Injectable({
	providedIn: 'root'
})
export class ApiClientTicketService {

	private readonly controller = 'tickets';
	private readonly PAGINATION_MAX_SIZE = 1000;
	private readonly PAGINATION_DEFAULT_SIZE = 100;

	constructor(private httpClientHelper: HttpClientHelperService) { }

	list(payload?: RequestsListPayload): Observable<RequestsResponse> {
		let qParams = this.getPaginationQueryParams(payload);

		if (payload?.sources?.length) {
			qParams = qParams.set('source', payload.sources.toString());
		}
		if (payload?.states?.length) {
			qParams = qParams.set('state', payload.states.toString());
		}
		if (payload?.countryCode?.length) {
			qParams = qParams.set('country', payload.countryCode.toString());
		}
		if (payload?.domain?.length) {
			qParams = qParams.set('domain', payload.domain.toString());
		}
		if (payload?.unread) {
			qParams = qParams.set('unread', payload.unread.toString());
		}

		if (payload?.privacyRightIds?.length) {
			qParams = qParams.set('privacyRightIds', payload.privacyRightIds.toString());
		}
		if (payload?.direction) {
			qParams = qParams.set('direction', SortDirectionEnum[payload.direction].toLowerCase());
		}
		if (payload?.minCreatedAt) {
			qParams = qParams.set('minCreatedAt', payload.minCreatedAt.toString());
		}
		if (payload?.assignedGroups?.length) {
			qParams = qParams.set('assignedGroups', payload.assignedGroups.toString());
		}

		return this.httpClientHelper.invokeGetAuth<RequestsResponse>(`${this.controller}/list`, qParams);
	}

	search(payload: RequestsSearchPayload): Observable<RequestsResponse> {
		let qParams = this.getPaginationQueryParams(payload);
	
		qParams = qParams.set('term', payload.term);
		
		if (payload?.direction) {
			qParams = qParams.set('direction', SortDirectionEnum[payload.direction].toLowerCase());
		}

		return this.httpClientHelper.invokeGetAuth<RequestsResponse>(`${this.controller}/search`, qParams);
	}

	getTicketInfo(id: string): Observable<RequestItemDeep> {
		return this.httpClientHelper.invokeGetAuth<RequestItemDeep>(`${this.controller}/${id}/info`);
	}

	getStats(stats: RequestsStatsEnum): Observable<RequestsStatsResponse> {
		return this.httpClientHelper.invokeGetAuth<RequestsStatsResponse>(`${this.controller}/stats/${stats}`); 
	}

	getCountryCodes(): Observable<CountryCodesResponse> {
		return this.httpClientHelper.invokeGetAuth<CountryCodesResponse>(`${this.controller}/country-codes`);
	}

	updateMetadata(data: TicketMetadata): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/updateMetadata`, data);
	}

	markTicketsAsUnread(ticketIds: string[]): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/MarkUnread`, ticketIds);
	}

	manualIntegrationSearch(ticketId: string, integrationId: string): Observable<SearchTriggerResponse> {
		return this.httpClientHelper.invokePostAuth<SearchTriggerResponse>(`${this.controller}/${ticketId}/integrations/v3/${integrationId}/actions/search`);
	}

	searchAll(ticketId: string, ids: string[]): Observable<TaskSearchResponse[]> {
		return this.httpClientHelper.invokePostAuth<TaskSearchResponse[]>(`${this.controller}/${ticketId}/integrations/v3/actions/search`, { tasks: ids });
	}

	getIntegrationActions(ticketId: string, integrationId: string): Observable<IntegrationActions> {
		return this.httpClientHelper.invokeGet<IntegrationActions>(`${this.controller}/${ticketId}/integrations/v3/${integrationId}/actions`);
	}

	getIdentifiers(ticketId: string): Observable<IntegrationsIdenntifiersResponse> {
		return this.httpClientHelper.invokeGet<IntegrationsIdenntifiersResponse>(`${this.controller}/${ticketId}/integrations/v3/identifiers`);
	}

	updateTicketDetails(ticketId: string, details: UpdateTicketDetails): Observable<void> {
		return this.httpClientHelper.invokePatchAuth<void>(`${this.controller}/${ticketId}`, details);
	}

	assignAutopilot(ticketIds: string[]): Observable<AutopilotAssignedResponse> {
		return this.httpClientHelper.invokePostAuth<AutopilotAssignedResponse>(`${this.controller}/v1/assign-auto-pilot/`, {ticketIds});
	}

	exportAuditLog(): Observable<ExportAuditLogResponse> {
		return this.httpClientHelper.invokePostAuth<ExportAuditLogResponse>(`${this.controller}/AuditExport`);
	}

	private getPaginationQueryParams(payload?: RequestsPaginationPayload): HttpParams {
		let qParams = new HttpParams();
		if (payload?.before) {
			qParams = qParams.set('before', payload.before);
		}
		if (payload?.after) {
			qParams = qParams.set('after', payload.after);
		}
		if (payload?.size) {
			qParams = qParams.set('size', payload.size > this.PAGINATION_MAX_SIZE ? this.PAGINATION_MAX_SIZE : payload.size);
		}
		else {
			qParams = qParams.set('size', this.PAGINATION_DEFAULT_SIZE);
		}
		return qParams;
	}

	deleteTickets(ticketIds: string[]): Observable<void> {
		let qParams = new HttpParams();
		qParams = qParams.set('ids', ticketIds.toString());
		return this.httpClientHelper.invokeDeleteAuth<void>(`${this.controller}/tickets`, null, qParams);
	}

	continueAfterEditTicket(ticketForm: SubmitTicketForm, ticketId: string): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/${ticketId}`, ticketForm);
	}
	
	createTicket(ticketForm: SubmitTicketForm): Observable<RequestFormResponse> {
		return this.httpClientHelper.invokePostAuth<RequestFormResponse>(`${this.controller}/create`, ticketForm);
	}

	getTicketLog(ticketId: string): Observable<RequestHistoryResponse> {
		return this.httpClientHelper.invokeGetAuth<RequestHistoryResponse>(`${this.controller}/${ticketId}/log`);
	}

	updateGroupAsignee(ticketId: string, groupId: string): Observable<void> {
		return this.httpClientHelper.invokePost<void>(`${this.controller}/${ticketId}/group-assignment`, {groupId});
	}

	deleteGroupAsignee(ticketId: string): Observable<void> {
		return this.httpClientHelper.invokeDeleteAuth<void>(`${this.controller}/${ticketId}/group-assignment`);
	}
}
