import {Injectable} from '@angular/core';
import {PiiDataTypeItem} from "../../../models/pii-classifier.interface";
import {PiiSystemDataTypeColumnsEnum} from "../data-types-table-header/data-types.enum";
import {PiiDataTypesService} from "../../states/pii-data-types-state/pii-data-types.service";
import {DataTypeMatchesFormatPipe} from "../../../../data-types/pipes/data-type-matches-format.pipe";
import { PiiSystemService } from '../../states/pii-system/pii-system.service';

@Injectable()
export class PiiDataTypesTableCsvHelper {

    constructor(
        private piiDataTypesService: PiiDataTypesService,
        private dataTypeMatchesFormatPipe: DataTypeMatchesFormatPipe,
        private piiSystemService: PiiSystemService
    ) { }

    schemaNames: string[];

    getValueByKey(dataType: PiiDataTypeItem, key: PiiSystemDataTypeColumnsEnum | string): string {
        const currInstance = this.piiSystemService.getActiveInstance();
    
        const values: Record<PiiSystemDataTypeColumnsEnum, string> = {
            dataType: dataType.dataType?.name ?? '',
            matches: `${this.dataTypeMatchesFormatPipe.transform(dataType.totalMatches, currInstance?.lastScan?.scanType)}` ?? '0',
            frameworks: dataType.frameworks ? dataType.frameworks.map(f => f.text).join(", ") : '-'
        };

        if (this.isPredefinedColumn(key)) {
            this.initSchemaNames();
            const schemaNameIndex = this.schemaNames.indexOf(key);
            if (schemaNameIndex !== -1) {
                values[key] = dataType.schemaValuesCounts[schemaNameIndex]?.toString() ?? '-';
            }
        }

        return values[key];
    }

    initSchemaNames(): void {
        if (!this.schemaNames) {
            this.schemaNames = this.piiDataTypesService.schemaNames;
        }
    }

    isPredefinedColumn(key: string): boolean {
        return !Object.values(PiiSystemDataTypeColumnsEnum).includes(key as PiiSystemDataTypeColumnsEnum);
    }
}
