import { PoliciesResponse, Policy } from './models/policies/policies.interface';
import { Injectable } from '@angular/core';
import { HttpClientHelperService } from './http-client-helper.service';
import { Observable, of } from 'rxjs';
import { PermissionsService } from '../auth/permissions.service';
import { RoleEnum } from '../core/models/permission-role.enum';

@Injectable({
	providedIn: 'root'
})
export class ApiClientPoliciesService {

	private readonly controller = 'policies';

	constructor(private httpClientHelper: HttpClientHelperService,
		private permissionsService: PermissionsService
	) { }

	getPolicies(): Observable<PoliciesResponse> {
		const permmitedRole = [RoleEnum.Admin, RoleEnum.Agent, RoleEnum.DsrManager, RoleEnum.InventoryManager];
		if (this.permissionsService.getIsAllowed(permmitedRole)) {
			return this.httpClientHelper.invokeGetAuth<PoliciesResponse>(`${this.controller}/policies`);
		}
		return of(null);
	}

	setPolicy(policy: Policy): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/Policy`, policy);
	}
}
