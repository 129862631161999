
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { TrashSystemsState, TrashSystemsStore } from './trash-systems.store';
import { MineSort } from 'src/app/shared/mine-sort/mine-sort.interface';
import { Observable } from 'rxjs';
import { TrashSystem } from 'src/app/api/models/systems/systems.interface';
import { SortDirectionEnum } from 'src/app/shared/sort-direction.enum';

@Injectable({ 
	providedIn: 'root' 
})
export class TrashSystemsQuery extends QueryEntity<TrashSystemsState> {

	constructor(
		protected store: TrashSystemsStore) {
		super(store);
	}

	selectTrashedSystems(sort: MineSort, search: string): Observable<TrashSystem[]> {
		return this.selectAll({
			filterBy: [
				entity => this.filterBySearchTerm(entity.name, search),
			],
			sortBy: (s1, s2) => this.sortByRemoveDate(s1, s2, sort.direction),
		});
	}

	private isEmpty = (str: string) => (!str?.length);

	private filterBySearchTerm(entityName: string, searchTerm: string): boolean {
		if (this.isEmpty(searchTerm)) return true;
		if (this.isEmpty(entityName)) return false;

		return entityName.toLowerCase().startsWith(searchTerm.toLowerCase());
	}

	private sortByRemoveDate(systemA: TrashSystem, systemB: TrashSystem, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Default ? 0 : 
			   direction === SortDirectionEnum.Asc ? 
			systemA.removeDate ? new Date(systemA.removeDate).getTime() - new Date(systemB.removeDate).getTime() : -1 :
			systemB.removeDate ? new Date(systemB.removeDate).getTime() - new Date(systemA.removeDate).getTime() : -1;
	}
}