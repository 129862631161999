import { computed, Injectable, Signal } from '@angular/core';
import { RxActions } from '@rx-angular/state/actions';
import { map, Observable, filter } from 'rxjs';

import { Risk, RiskRegistryFilteringCategoryEnum } from 'src/app/api/models/risks/risks.interface';
import { RiskRegistryActions } from '../models/risk-registry-actions.interface';
import { RiskRegistryTableStore } from '../state/risk-registry-table.store';
import { TableColumn } from 'src/app/shared/models/table-column.interface';
import { FilterCategory } from 'src/app/core/models/filtering.interface';
import { MineSort } from 'src/app/shared/mine-sort/mine-sort.interface';
@Injectable({
     providedIn: 'root'
})
export class RiskRegistryTableService {

    constructor(
        private store: RiskRegistryTableStore,
    ) {}

    getActions(): RxActions<RiskRegistryActions, {}> {
        return this.store.actions;
    }

    getEmpty(): Signal<boolean | undefined> {
        return this.store.signal('empty');
    }

    getSort(): Signal<MineSort> {
        return this.store.signal('sort');
    }

    setLoading(loading: boolean): void {
        this.store.set({ 'loading': loading });
    }
        
    setDisabled(disabled: boolean): void {
        this.store.set({ 'disabled': disabled });
    }

    setSearchValue(value: string): void {
        this.store.set({ 'search': value });
    }

    setFilters(filters: FilterCategory[]): void {
        this.store.set({ 'filters': filters });
    }

    getLoading(): Signal<boolean | undefined> {
        return this.store.signal('loading');
    }
        
    selectLoading(): Observable<boolean | undefined> {
        return this.store.select('loading');
    }
        
    getDisabled(): Signal<boolean | undefined> {
        return this.store.signal('disabled');
    }
        
    selectDisabled(): Observable<boolean | undefined> {
        return this.store.select('disabled');
    }

    getColumns(): Map<string, TableColumn> {
        return this.store.get('columns');
    }

    getActiveView(): string {
        return this.store.get('view');
    }

    setActiveView(view: string): void {
        this.store.set({ 'view': view });
    }

    getActiveFilters(): Signal<FilterCategory[]> {
        return this.store.signal('filters');
    }

    getSearch(): Signal<string> {
        return this.store.signal('search');
    }

    getRiskRegistry(asArray: boolean = false): Signal<Map<number, Risk> | Risk[]> {
        const riskRegistrySignal = this.store.signal('riskRegistry');
        if (asArray) {
            return computed(() => {
                const registry = riskRegistrySignal() ?? new Map<number, Risk>(); // Provide an empty Map as a fallback
                return Array.from(new Set([...registry.values()]));
            }) as Signal<Risk[]>;
        }
        return computed(() => riskRegistrySignal() ?? new Map<number, Risk>()) as Signal<Map<number, Risk>>;
    }

    selectCountByType(type: string): Observable<number> {
        return this.store.getInitialData().pipe(
            filter((risks: Map<number, Risk>) => !!risks),
            map((risks: Map<number, Risk>) => 
                Array.from(risks.values()).filter(risk => risk.type === type).length
            )
        );
    }

    // Count risks by inherent risk level based on the unfiltered initial$ stream
    selectCountByInherentRisk(riskLevel: RiskRegistryFilteringCategoryEnum): Observable<number> {
        return this.store.getInitialData().pipe(
            filter((risks: Map<number, Risk>) => !!risks),
            map((risks: Map<number, Risk>) => {
                const risksArray = Array.from(risks.values());
                if (riskLevel === RiskRegistryFilteringCategoryEnum.All) {
                    return risksArray.length;
                }
                if (riskLevel === RiskRegistryFilteringCategoryEnum.Missing) {
                    return risksArray.filter(risk => !risk.inherentRisk).length;
                }
                return risksArray.filter(risk => risk.inherentRisk as string === riskLevel).length;
            })
        );
    }
}