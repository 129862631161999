import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

import { SystemsQuery } from 'src/app/systems/state/systems.query';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { HtmlDecodePipe } from 'src/app/shared/pipes/html-decode.pipe';
import { Risk, RiskRegistryColumnEnum } from 'src/app/api/models/risks/risks.interface';
import { CollaboratorsChipPipe } from 'src/app/ai-assessments/pipes/collaborators-chip.pipe';
import { AiAssessmentEntityTypeEnum } from 'src/app/api/models/ai-assessments/ai-assessments.enum';
import { AiAssessmentTemplateNamePipe } from 'src/app/ai-assessments/pipes/ai-assessment-template-name.pipe';
import { AiAssessmentLabelsPipe } from 'src/app/ai-assessments/pipes/ai-assessment-labels.pipe';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class RiskRegistryTableCsvHelper {

  constructor(
    private datePipe: DatePipe,
    private contentPipe: ContentPipe,
    private systemsQuery: SystemsQuery,
    private htmlDecodePipe: HtmlDecodePipe,
    private collaboratorsChipPipe: CollaboratorsChipPipe,
    private aiAssessmentTemplateNamePipe: AiAssessmentTemplateNamePipe,
    private aiAssessmentLabelsPipe: AiAssessmentLabelsPipe
  ) {}

  async getValueByKey(risk: Risk, key: RiskRegistryColumnEnum | string): Promise<string> {
    const values: Record<RiskRegistryColumnEnum, string> = {
      name: risk.name ? this.htmlDecodePipe.transform(risk.name) : '',
      createdDate: risk.createdDate ? this.datePipe.transform(risk.createdDate, 'dd MMM, yyyy') : '-',
      assessmentType: risk.assessmentPage ? this.aiAssessmentTemplateNamePipe.transform((risk?.assessmentPage?.['type'] ?? risk?.assessmentPage?.['templateType'])) : '-',
      collaborators: await this.getCollaborators(risk),
      inherentRisk: risk.inherentRisk ?? '-',
      residualRisk: risk.residualRisk ?? '-',
      likelihood: risk.likelihood ?? '-',
      severity: risk.severity ?? '-',
      comment: this.getComment(risk),
      accepted: this.contentPipe.transform(`common.${risk.accepted ? 'yes' : 'no'}`),
      mitigations: risk.mitigations?.length ? risk.mitigations.map(mitigation => this.htmlDecodePipe.transform(mitigation)).join(', ') : '-',
      relatedAssessmentId: risk.assessmentPage?.name ? this.htmlDecodePipe.transform(risk.assessmentPage.name) : '-',
      relatedEntityIds: this.getDataSources(risk),
      labels: await this.getLabels(risk),
    };
    if (key in values) {
      return values[key];
    }
    return '-';
  }

  private async getLabels(risk: Risk): Promise<string> {

    if(risk.assessmentPage?.tags) {
      const { labels } = await firstValueFrom(this.aiAssessmentLabelsPipe.transform(risk.assessmentPage?.tags));
      if (labels) {
        return labels?.join(', ');
      }
    }
    return '-';
  }
  
  private async getCollaborators(risk: Risk): Promise<string> {
    const collaborators = await this.collaboratorsChipPipe.transform(risk.assessmentPage);
    if (collaborators) {
      return collaborators.text ?? '-';
    }
    return '-';
  }
  
  private getDataSources(risk: Risk): string {
    const relatedEntities = (risk.assessmentPage?.['externalRelatedEntities'] ?? risk.assessmentPage?.['relatedEntities'] );
    const relatedSystems = relatedEntities?.filter(entity => entity.type === AiAssessmentEntityTypeEnum.System);

    if (relatedSystems?.length) {
      return relatedSystems.map(entity => {
        const name = this.systemsQuery.getEntity(entity.externalRelatedEntityId ?? entity.id)?.name;
        return name ? this.htmlDecodePipe.transform(name) : '-';
      }).filter(Boolean).join(', ') ?? '-';
    }
    return '-';
  }

  private getComment(risk: Risk): string {
    if (!risk.comment) return '-';
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = risk.comment;
    return tempDiv.textContent || tempDiv.innerText || '-';
  }
}