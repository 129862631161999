import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of, map, switchMap, filter } from 'rxjs';

import { ContentPipe } from 'src/app/services/content/content.pipe';
import { CustomField } from 'src/app/api/models/company-settings/company-settings.interface';
import { CompanySettingsQuery } from 'src/app/company-settings/state/company-settings.query';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';

@Pipe({
    standalone: true,
    name: 'dataSubjectIdToName',
})
export class DataSubjectIdToNamePipe implements PipeTransform {
    constructor(
        private contentPipe: ContentPipe,
        private featureFlagQuery: FeatureFlagQuery,
        private companySettingsQuery: CompanySettingsQuery,
    ) {}

    private customDataSubjects$ = this.companySettingsQuery.selectLoading().pipe(
        filter(loading => !loading),
        switchMap(() => this.companySettingsQuery.selectCustomDataSubjects())
    );

    transform(id: string | string[]): Observable<string> {
        return Array.isArray(id)
            ? this.transformIdsArray(id)
            : this.transformId(id);
    }

    private transformId(id: string): Observable<string> {
        if (!this.featureFlagQuery.getFlag(FeatureFlags.DevCustomDataSubject)) {
            return of(id);
        }
        return this.getDataSubjectNameById(id);
    }

    private transformIdsArray(ids: string[]): Observable<string> {
        if (!this.featureFlagQuery.getFlag(FeatureFlags.DevCustomDataSubject)) {
            return of(ids.join(', '));
        }
        return this.getDataSubjectNamesByIds(ids);
    }

    private getDataSubjectNameById(id: string): Observable<string> {
        return this.customDataSubjects$.pipe(
            map(subjects => this.convertSubjectIdToName(id, subjects))
        );
    }

    private getDataSubjectNamesByIds(ids: string[]): Observable<string> {
        return this.customDataSubjects$.pipe(
            map(subjects => ids.map(id => this.convertSubjectIdToName(id, subjects)).join(', '))
        );
    }
    
    private convertSubjectIdToName(id: string, subjects: CustomField[]): string {
        const name = subjects.find(m => m.id === id)?.name
            || this.contentPipe.transform('ropa.dataSubjectCategoriesList').find(m => m.key === id)?.value
            || id;
        return name?.trim();
    }
}