import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, signal } from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import { RxIf } from "@rx-angular/template/if";
import { first, Observable } from "rxjs";
import { Animations } from "src/app/animations/animations";
import { ContentPipeModule } from "src/app/services/content/content-pipe.module";
import { DashboardService } from "../dashboard.service";
import { LookerDashboardTypeEnum } from "../models/dashboard.enums";
import { ActivatedRoute } from "@angular/router";

@Component({
    standalone: true,
    selector: 'looker-dashboard',
    templateUrl: './looker-dashboard.component.html',
    styleUrl: './looker-dashboard.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        ...Animations.fadeInUpAll
    ],
    imports: [
        CommonModule,
        ContentPipeModule,
        RxIf
    ]
})
export class LookerDashboardComponent implements OnInit {
  @Input() type: LookerDashboardTypeEnum;

  url$: Observable<SafeResourceUrl>;
  loading = signal<boolean>(true);

  constructor(
    private dashboardService: DashboardService,
    private route: ActivatedRoute
  ) { }

  @HostBinding('@fadeInUpAllAnimation')
  get hostAnimation() {
    return true;
  }

  @HostBinding('class')
  get hostClass(): string {
    return this.type ? '' : 'page-padding u-display-block no-bottom-padding';
  }

  ngOnInit(): void {
    const type = this.type || this.route?.snapshot?.data['type'] as LookerDashboardTypeEnum;
    this.url$ = this.dashboardService.generateDashboardUrl(type)
        .pipe(
            first()
        );
  }

  onIframeLoad(): void {
    setTimeout(() => this.loading.set(false), 1500);
  }
}

