import { SystemsQuery } from 'src/app/systems/state/systems.query';
import { Injectable } from "@angular/core";
import { RxState } from "@rx-angular/state";
import { PiiSystem } from "src/app/pii-classifier/models/pii-classifier.interface";
import { PIISystemState } from "./pii-system.interface";
import { Router } from "@angular/router";
import { filter, first, tap, map, switchMap } from "rxjs";
import { SystemsService } from 'src/app/systems/state/systems.service';
import { IntegrationSystem } from 'src/app/api/models/systems/systems.interface';
import { ApiClientPiiClassifierService } from 'src/app/api/api-client-pii-classifier.service';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { PiiSystemResponse } from 'src/app/api/models/pii-classifier/pii-classifier.interface';

@Injectable({
    providedIn: 'root'
})
export class PiiSystemStore extends RxState<PIISystemState> {

    private readonly initState = {
        loading: true,
        instances: null,
        system: null,
        activeIntegrationId: 'all'
    } as PIISystemState;

    constructor(private router: Router,
        private systemsQuery: SystemsQuery,
        private systemsService: SystemsService,
        private content: ContentPipe,
        private apiClientPiiClassifierService: ApiClientPiiClassifierService
    ) {
        super();
        this.set({ ...this.initState }); // set the initial state of the store
    }

    resetState(): void {
        this.set({ ...this.initState });
    }

    // connect the state observables, will be called when loading the page
    initStateConnections(): void {
        this.initSystemConnection();
    }

    private initSystemConnection(): void {
        const path = this.router.url.split('/')[2];

        this.systemsQuery.selectLoading().pipe(
            filter(loading => !loading),
            switchMap(() => this.systemsService.getSystemInstanceByPath(path)),
            first(),
            tap(res => this.initInstancesData(res.integrationInstances)),
            map(system => ({ name: system.name, iconPath: system.iconPath, pathFriendlyId: system.pathFriendlyId, systemId: system.systemId })),
            tap(system => this.set({system: system})),
            switchMap(system => this.apiClientPiiClassifierService.getPiiSystem(system.systemId)),
            tap(piiData => this.updateAllInstanceInStore(piiData)),
            tap(() => this.set({ loading: false }))
        ).subscribe();
    }

    private initInstancesData(instances: IntegrationSystem[]): void {
        const instancesMap = new Map<string, PiiSystem>();
        instances.filter(instance => !!instance.piiEnabled)
            .forEach(instance => instancesMap.set(instance.integrationId, { name: instance.customName ?? instance.integrationId } as PiiSystem));
        this.set({ instances: instancesMap });
    }

    private updateAllInstanceInStore(piiData: PiiSystemResponse): void {
        const allItem = this.content.transform('pii-classifier.allInstancesItem');

        this.set(state => {
            const updatedInstances = new Map(state.instances);
            updatedInstances.set(allItem.id, {name: allItem.value, ...piiData});
            return { instances: updatedInstances };
        });
    }

}