export enum MenuItemId {
    Radar = 'radar',
    Tasks = 'tasks',
    DataInventory = 'inventory',
    Risk = 'riskAnlaysis',
    Discovery = 'discovery',
    Compliance = 'compliance',
    DsrHandling = 'dsrHandling',
    WhatsNew = 'whatsNew',
    Dashboard = 'dashboard',
}