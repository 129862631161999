import { Injectable, Signal } from "@angular/core";
import { Observable } from "rxjs";
import { PiiSystemStore } from "./pii-system.store";
import { SystemMetadata, PiiSystemInstance } from "./pii-system.interface";
import { PiiSystemResponse } from "src/app/api/models/pii-classifier/pii-classifier.interface";

@Injectable({
    providedIn: 'root'
})
export class PiiSystemQuery {

    constructor(
        private store: PiiSystemStore,
    ) { }

    setLoading(loading: boolean): void {
        this.store.set({ 'loading': loading });
    }

    getLoading(): Signal<boolean | undefined> {
        return this.store.signal('loading');
    }
    
    selectLoading(): Observable<boolean | undefined> {
        return this.store.select('loading');
    }

    getSystem(): Signal<SystemMetadata> {
        return this.store.signal('system');
    }
    
    selectSystem(): Observable<SystemMetadata> {
        return this.store.select('system');
    }

    getInstances(): Signal<Map<string, PiiSystemInstance>> {
        return this.store.signal('instances');
    }

    addInstanceToStore(instanceId: string, instance: PiiSystemResponse): void {
      const instances = this.getInstances()();
      const updatedInstances = new Map(instances);
      const currInstance = instances.get(instanceId);

      updatedInstances.set(instanceId, {name: currInstance.name, ...instance});
      this.store.set({ 'instances': updatedInstances });
    }

    getActiveIntegrationId(): Signal<string> {
        return this.store.signal('activeIntegrationId');
    }

    selectActiveIntegrationId(): Observable<string> {
        return this.store.select('activeIntegrationId');
    }
    
    setActiveIntegrationId(id: string): void {
        this.store.set({ 'activeIntegrationId': id });
    }
    
}