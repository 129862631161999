import { Injectable, Injector } from "@angular/core";

import { ContentPipe } from "src/app/services/content/content.pipe";
import { DropdownOption } from "src/app/shared/mine-dropdown/mine-dropdown.interface";
import { FieldUsedInEnum } from "src/app/api/models/company-settings/custom-fields.enum";
import { AddItemConfig } from "src/app/shared/mine-chips-input/mine-chips-input.interface";
import { CustomFieldsQuery } from "src/app/company-settings/state/custom-fields/custom-fields.query";
import { FeatureFlagQuery } from "src/app/feature-flag/state/feature-flag.query";
import { FeatureFlags } from "src/app/api/models/profile/profile-feature-flags.enum";
import { Risk } from "src/app/api/models/risks/risks.interface";
import { SystemsQuery } from "src/app/systems/state/systems.query";
import { DataTypesQuery } from "src/app/data-types/state/data-types.query";
import { CustomValuesQuery } from "src/app/company-settings/state/custom-values/custom-values.query";

import { AiAssessmentUuidPipe } from "../pipes/ai-assessment-uuid.pipe";
import { AiAssessmentEntityTypeEnum } from "src/app/api/models/ai-assessments/ai-assessments.enum";
import { AiAssessmentEntity, AiAssessmentInstance } from "src/app/api/models/ai-assessments/ai-assessments.interface";
import { AiAssessmentCustomComponentEnum, AiAssessmentLogicOperandEnum, AiAssessmentLogicTypeEnum, AiAssessmentsFormControlTypeEnum } from "../models/ai-assessments.enum";
import { AiAssessmentLogic, AiAssessmentLogicCondition, AiAssessmentLogicConditions, AiAssessmentsFormControl } from "../models/ai-assessments.interface";

import _ from 'lodash';

// Remove after migration
interface QuestionRefMigration {
    questionId: string;
    question: AiAssessmentsFormControl;
}

@Injectable({
    providedIn: 'root'
})
export class AssessmentsLogicManagerService {
    private readonly idDelimiter = '||';
    
    private aiAssessmentsUuidPipe = new AiAssessmentUuidPipe();

    constructor(
        private injector: Injector,
        private contentPipe: ContentPipe,
        private featureFlagQuery: FeatureFlagQuery,
        private customFieldsQuery: CustomFieldsQuery,
    ) {}

    generateControlUuid(control: AiAssessmentsFormControl): string {
        return this.aiAssessmentsUuidPipe.transform(`${control.type}-${control.name}`);
    }

    generateSectionUuid(section: AiAssessmentsFormControl): string {
        return this.aiAssessmentsUuidPipe.transform(`${section.type}-${section.name}`);
    }

    generateSectionsUuid(sections: AiAssessmentsFormControl[]): AiAssessmentsFormControl[] {
        return sections.map(section => {
            return {
                ...section,
                id: section.id ?? this.generateSectionUuid(section),
                controls: section.controls?.map(control => {
                    return {
                        ...control,
                        id: control.id ?? this.generateControlUuid(control),
                    };
                })
            };
        });
    }

    getQuestionById(sections: AiAssessmentsFormControl[], questionId: string): AiAssessmentsFormControl {
        if (!sections?.length || !questionId) return null;
        for (let section of sections) {
            if (section.controls?.length) {
                for (let control of section.controls) {
                    if (this.getSectionControlCombinedId(section.id, control.id) === questionId) {
                        return control;
                    }
                }
            }
        }
        return null;
    }

    isValidQuestionId(sections: AiAssessmentsFormControl[], questionId: string): boolean {
        if (!sections?.length || !questionId) return false;
        for (let section of sections) {
            if (section.controls?.length) {
                for (let control of section.controls) {
                    if (this.getSectionControlCombinedId(section.id, control.id) === questionId) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    getQuestionAddItemConfig(control: AiAssessmentsFormControl, options?: DropdownOption[]): AddItemConfig {
        if (!control) return null;
        let dropDownOptions: DropdownOption[] = [];
        if (control.type === AiAssessmentsFormControlTypeEnum.Dropdown) {
            dropDownOptions = control.options?.filter(option => !!option.id && !!option.value);
        }
        else if (!!options) {
            dropDownOptions = options?.filter(option => !!option.id && !!option.value);
        }
        return {
            dropDownOptions,
            inputPlaceHolder: this.contentPipe.transform('ropa.searchInputPlaceholder'),
            fixedOptions: true,
            hasCustomOption: false,
            width: '409px',
        } as AddItemConfig;
    }

    getQuestionRulesCount(currentLogic: AiAssessmentLogic, key: string): number {
        if (!currentLogic || !Object.keys(currentLogic)?.length || !key) return null;

        return currentLogic[key]?.conditions?.filter(condition => !!condition["questionId"])?.length ?? null;
    }

    canQuestionUseLogic(sections: AiAssessmentsFormControl[], sectionIndex: number, sectionId: string, controlIndex: number, controlId: string): boolean {
        if (!sections?.length || !sectionId || !controlId || !this.isValidIndex(sectionIndex) || !this.isValidIndex(controlIndex)) return false;

        const section = sections.find(sec => sec.id === sectionId);
        const control = section?.controls?.find(ctrl => ctrl.id === controlId);

        if (control) {
            return !!sections.find((sec, index) => sec.controls?.some((ctrl, i) => {
                if (ctrl.id === control.id) return false;
                if (sectionIndex < index) return false;
                if (sectionIndex === index) return i < controlIndex && this.isControlTypeAndNameIsLogicSupported(ctrl);
                if (sectionIndex > index) return this.isControlTypeAndNameIsLogicSupported(ctrl);
            }));
        }
        return false;
    }

    isQuestionUsedInOtherLogic(currentLogic: AiAssessmentLogic, sections: AiAssessmentsFormControl[], sectionId: string, controlId: string, deletedValue?: string[]): boolean {
        if (!currentLogic || !Object.keys(currentLogic)?.length || !sections?.length || !sectionId || !controlId) return false;
        try {
            for (let questionId of Object.keys(currentLogic)) {
                if (!this.getQuestionById(sections, questionId)) return false;

                const questionLogic = currentLogic[questionId];

                for (let i=0; i<questionLogic.conditions?.length; i++) {
                    const condition = questionLogic.conditions[i];
                    const selectedQuestionId = this.isDropdownOption(condition?.['questionId']) ? condition?.['questionId'].id : condition?.['questionId'];
                    
                    if (selectedQuestionId === this.getSectionControlCombinedId(sectionId, controlId)) {
                        if (!!deletedValue?.length) {
                            const questionValue: Array<any> = Array.isArray(condition?.['questionValue']) ? condition?.['questionValue'] : condition?.['questionValue'].value?.split(',') ?? [];
                            if (this.hasExactly(questionValue, deletedValue)) return true;
                        }
                        else {
                            return true;
                        }
                    }
                }
            }
            return false;
        }
        catch (err) {
            console.error('Could not parse instance logic');
        }
    }

    setQuestionLogic(currentLogic: AiAssessmentLogic, key: string, newConditionLogic: AiAssessmentLogicConditions): AiAssessmentLogic {
        if (!key) return currentLogic;
        try {            
            let updatedLogic: AiAssessmentLogic = currentLogic;
            if (!currentLogic ||!Object.keys(currentLogic)?.length) {
                updatedLogic = {} as AiAssessmentLogic;
            }
            if (!!updatedLogic[key] && !newConditionLogic) {
                delete updatedLogic[key];
            }
            else if (!!newConditionLogic) {
                updatedLogic[key] = newConditionLogic;
            }
    
            return updatedLogic;
        }
        catch (err) {
            console.error('Could not parse instance logic');
            return currentLogic;
        }
    }
    
    updateQuestionLogic(currentLogic: AiAssessmentLogic, key: string, field: keyof AiAssessmentLogicCondition, update: DropdownOption | string[], conditionIndex: number = 0): AiAssessmentLogic {
        if (!currentLogic || !Object.keys(currentLogic)?.length || !key || !field || !update) return currentLogic;
        try {            
            let updatedLogic: AiAssessmentLogic = currentLogic;
            const questionLogicConditions = updatedLogic[key].conditions;

            switch (field) {
                case "type":
                    questionLogicConditions[conditionIndex]["type"] = (update as DropdownOption).id;
                    break;

                case "questionId":
                    questionLogicConditions[conditionIndex]["questionId"] = (update as DropdownOption).id;
                    break;

                case "questionValue":
                    questionLogicConditions[conditionIndex]["questionValue"] = (update as string[]);
                    if (!questionLogicConditions[conditionIndex]["type"]) { // default value in case type is not set
                        questionLogicConditions[conditionIndex]["type"] = AiAssessmentLogicTypeEnum.HasExactly;
                    }
                    break;
            }
    
            return updatedLogic;
        }
        catch (err) {
            console.error('Could not parse instance logic');
            return currentLogic;
        }
    }

    updateOperand(currentLogic: AiAssessmentLogic, key: string, operand: AiAssessmentLogicOperandEnum): AiAssessmentLogic {
        if (!currentLogic || !Object.keys(currentLogic)?.length || !key || !operand) return currentLogic;
        try {            
            let updatedLogic: AiAssessmentLogic = currentLogic;
            updatedLogic[key].operand = operand;
    
            return updatedLogic;
        }
        catch (err) {
            console.error('Could not parse instance logic');
            return currentLogic;
        }
    }

    getQuestionLogicOptions(sections: AiAssessmentsFormControl[], sectionIndex: number, sectionId: string, controlIndex: number, controlId: string): DropdownOption[] {
        if (!sections?.length || !sectionId || !controlId || !this.isValidIndex(sectionIndex) || !this.isValidIndex(controlIndex)) return [];
        try {
            if (!controlId) {
                return [];
            }
            const options = [] as DropdownOption[];

            for (let index=0; index<sections.length; index++) {
                const sec = sections[index];

                for (let i=0; i<sec.controls?.length; i++) {
                    const ctrl = sec.controls[i];

                    if (this.isControlTypeAndNameIsLogicSupported(ctrl) && (ctrl.id !== controlId)) {
                        if (((sectionIndex === index) && (i < controlIndex)) || (sectionIndex > index)) {
                            const questionId = this.getSectionControlCombinedId(sec.id, ctrl.id);

                            options.push({
                                id: questionId,
                                value: `${index+1}.${(i+1)} ${ctrl.label}`
                            });
                        }
                    }
                }
            }
            return options;
        }
        catch (err) {
            console.error('Could not parse instance logic');
            return [];
        }
    }

    getOperandOptions(): DropdownOption[] {
        return [
            { 
                id: AiAssessmentLogicOperandEnum.OR, 
                value: this.contentPipe.transform('ai-assessments.logicAnyRule') 
            },
            { 
                id: AiAssessmentLogicOperandEnum.AND, 
                value: this.contentPipe.transform('ai-assessments.logicAllRules')  
            },
        ];
    }

    checkQuestionLogic(
        instance: AiAssessmentInstance, 
        sections: AiAssessmentsFormControl[], 
        sectionId: string, 
        controlId: string, 
        risks: Risk[],
        relatedEntities: AiAssessmentEntity[],
        formData: string,
        customControlNameToEntityTypeFn: (name: AiAssessmentCustomComponentEnum) => AiAssessmentEntityTypeEnum): boolean {

            if (!instance.logic) return true;
            const conditionalLogic = this.parseAiAssessmentLogic(sections, instance.logic);
            
            if (!conditionalLogic || !Object.keys(conditionalLogic)?.length) return true;

            const questionKey = this.getSectionControlCombinedId(sectionId, controlId);

            if (questionKey in conditionalLogic) {
                const conditionalLogicResults: boolean[] = [];
                const questionLogic = conditionalLogic[questionKey];
                const operand = questionLogic.operand;

                for (let i=0; i<questionLogic.conditions?.length; i++) {
                    const condition = questionLogic.conditions[i];         
                    const questionControl = this.getQuestionById(sections, condition['questionId']);
                    
                    if (questionControl) {
                        const customValuesQuery = this.injector.get(CustomValuesQuery);
                        const questionValue = this.getQuestionValue(questionControl, relatedEntities, risks, formData, customControlNameToEntityTypeFn);
                        let checkResult = true;
        
                        const mappedQuestionValue = questionValue.map(val => {
                            const customValue = customValuesQuery.getEntity(val);
                            return customValue?.name ?? val;
                        });
                        
                        let conditionValue = condition['questionValue'];
                        if (questionControl.name === AiAssessmentCustomComponentEnum.DataTypes) {
                            const dataTypesQuery = this.injector.get(DataTypesQuery);
                            conditionValue = conditionValue.map(val => dataTypesQuery.getDataTypeIdByName(val));
                        }

                        switch (condition['type']) {
                            case AiAssessmentLogicTypeEnum.HasSome: {
                                checkResult = this.hasSome(mappedQuestionValue, conditionValue);
                                break;
                            }
        
                            case AiAssessmentLogicTypeEnum.HasExactly:
                            default: {
                                checkResult = this.hasExactly(mappedQuestionValue, conditionValue);
                                break;
                            }
                        }
                        conditionalLogicResults.push(checkResult);
                    }
                }

                if (!conditionalLogicResults.length) return true;
                if (!operand) {
                    return conditionalLogicResults.some(r => r === true);
                }
                else if (operand) {
                    const result = operand === AiAssessmentLogicOperandEnum.AND ? 
                        conditionalLogicResults.every(r => r === true) :
                        conditionalLogicResults.some(r => r === true);
                        
                    if (!result) return false;
                }
            }
            return true;
    }

    private getQuestionValue(
        control: AiAssessmentsFormControl, 
        relatedEntities: AiAssessmentEntity[],
        risks: Risk[],
        formData: string,
        customControlNameToEntityTypeFn: (name: AiAssessmentCustomComponentEnum) => AiAssessmentEntityTypeEnum
    ): Array<string> {
        if (this.featureFlagQuery.getFlag(FeatureFlags.DevConditionsOnMineQuestions)) {
            try {
                const controlType = control.type;
                const data = JSON.parse(formData);
    
                if (controlType === AiAssessmentsFormControlTypeEnum.Dropdown || control.value) {
                    return Array.isArray(control.value) ? control.value : control.value?.split(',') ?? [];
                }
                if (control.name === AiAssessmentCustomComponentEnum.Risks) {
                    return risks?.map(risk => risk.name);
                }
                if (control.name === AiAssessmentCustomComponentEnum.PartiesWithAccess) {
                    for (let sectionName of Object.keys(data)) {
                        const ctrl = data[sectionName][control.name];
                        if (!!ctrl?.type) {
                            return [ctrl.type];
                        }
                    }
                    return [];
                }
                if (control.name === AiAssessmentCustomComponentEnum.DpiaNeeded) {
                    for (let sectionName of Object.keys(data)) {
                        const ctrl = data[sectionName][control.name];
                        if (ctrl && ('dpiaEnabled' in ctrl)) {
                            return [this.contentPipe.transform(`ropa.${ctrl['dpiaEnabled'] ? 'dpiaEnable' : 'dpiaDisable'}`)];
                        }
                    }
                    return [];
                }
    
                const entityType = customControlNameToEntityTypeFn(control.name as AiAssessmentCustomComponentEnum);
                const ids = relatedEntities?.filter(entity => entity.type === entityType)?.map(entity => entity.id);
                
                if (control.name === AiAssessmentCustomComponentEnum.DataSources && ids?.length) {
                    const systemsQuery = this.injector.get(SystemsQuery);
                    return ids.map(id => systemsQuery.getEntity(id)?.name).filter(Boolean);
                }
                return ids;
            }
            catch (error) {
                console.error(error);
                return [];
            }
        }
        return Array.isArray(control.value) ? control.value : control.value?.split(',') ?? [];
    }

    hasExactly(questionValues: string[], targetValues: string[]): boolean {
        const qValues = this.arrayToLowerCase(questionValues);
        const tValues = this.arrayToLowerCase(targetValues);
        return qValues?.every(value => tValues?.includes(value)) && qValues?.length === tValues?.length;
    }

    hasSome(questionValues: string[], targetValues: string[]): boolean {
        const qValues = this.arrayToLowerCase(questionValues);
        const tValues = this.arrayToLowerCase(targetValues);
        return qValues?.some(value => tValues?.includes(value));
    }

    private arrayToLowerCase(array: string[]): string[] {
        return array?.filter(Boolean)?.map(value => value?.toLowerCase()?.trim());
    }

    onRemoveDropdownOption(currentLogic: AiAssessmentLogic, sections: AiAssessmentsFormControl[], sectionId: string, controlId: string, optionIndex: number): AiAssessmentLogic {
        if (!currentLogic || !Object.keys(currentLogic)?.length || !sections?.length || !sectionId || !controlId || !this.isValidIndex(optionIndex)) return;
        try {
            const section = sections.find(sec => sec.id === sectionId);
            const control = section?.controls?.find(ctrl => ctrl.id === controlId);
            const optionToRemove = control?.options?.[optionIndex]; 
            
            if (!optionToRemove?.value) {
                return;
            }

            for (const key in currentLogic) {
                for (let i=0; i<currentLogic[key]?.conditions?.length; i++) {
                    const questionLogic = currentLogic[key].conditions[i];
                    if (!!questionLogic && questionLogic['questionValue']?.includes(optionToRemove.value)) {
                        questionLogic['questionValue'] = questionLogic['questionValue'].filter(option => option !== optionToRemove.value);
                        
                        // in case it was the only option selected remove condition
                        if (!questionLogic['questionValue'].length) {
                            currentLogic[key].conditions.splice(i, 1);
                            if (!currentLogic[key].conditions?.length) {
                                delete currentLogic[key];
                            }
                        }
                    }
                }
            }
            return;
        }
        catch (err) {
            console.error('Could not parse instance logic');
        }
    }

    getSectionControlCombinedId(sectionId: string, controlId: string): string {
        return `${sectionId}${this.idDelimiter}${controlId}`;
    }

    private isValidIndex(index: number | null | undefined): index is number {
        return index !== null && index !== undefined;
    }

    stringifyAiAssessmentLogic(currentLogic: AiAssessmentLogic): string {
        const clonedLogic = _.cloneDeep(this.cleanBeforeSubmit(currentLogic));
        return JSON.stringify(clonedLogic);
    }

    // Make adjustments to the current logic before sending to BE.
    // Move from reactive form control types to AiAssessmentLogic interface
    private cleanBeforeSubmit(currentLogic: AiAssessmentLogic): AiAssessmentLogic {
        if (!currentLogic || !Object.keys(currentLogic)?.length) return {};
        
        Object.keys(currentLogic).forEach(questionId => {
            if (!!currentLogic[questionId] && (!questionId || questionId === "undefined")) {
                delete currentLogic[questionId];
            }
            const questionLogic = currentLogic[questionId];
            if (!!currentLogic[questionId] && !questionLogic?.conditions?.length) {
                delete currentLogic[questionId];
            }

            for (let i=0; i<questionLogic?.conditions?.length; i++) {
                const condition = questionLogic.conditions[i];
                if (currentLogic[questionId] && (!condition || !condition?.['questionId'] || !condition?.['questionValue'])) {
                    delete currentLogic[questionId];
                }
                if (!!currentLogic[questionId] && !condition?.['type']) {
                    currentLogic[questionId].conditions[i]['type'] = AiAssessmentLogicTypeEnum.HasExactly;
                }
                if (!!currentLogic[questionId] && this.isDropdownOption(condition['type'])) {
                    currentLogic[questionId].conditions[i]['type'] = condition['type'].id;
                }
                if (!!currentLogic[questionId] && this.isDropdownOption(condition['questionId'])) {
                    currentLogic[questionId].conditions[i]['questionId'] = condition['questionId'].id;
                }
                if (!!currentLogic[questionId] && this.isDropdownOption(condition['questionValue'])) {
                    currentLogic[questionId].conditions[i]['questionValue'] = [condition['questionValue'].value];
                }
            }
        });

        return currentLogic;
    }

    isDropdownOption(option: DropdownOption | string): boolean {
        return typeof option === 'object' && option !== null && 'id' in option;
    }
    
    parseAiAssessmentLogic(sections: AiAssessmentsFormControl[], logic: string): AiAssessmentLogic {
        if (!sections?.length || !logic) return {};
        try {
            // remove conditions on null reference questions
            let parsedLogic = JSON.parse(logic);
            Object.keys(parsedLogic).forEach(questionId => {
                if (!this.isValidQuestionId(sections, questionId)) {
                    delete parsedLogic[questionId];
                }
                else {
                    parsedLogic[questionId].conditions = parsedLogic[questionId].conditions.filter(condition => {
                        return condition?.['questionId'] && this.isValidQuestionId(sections, condition['questionId']);
                    });
                }
            });
            return parsedLogic;
        }
        catch (err) {
            console.error('Could not parse instance logic');
            return {};
        }
    }

    isValidConditionalLogic(currentLogic: AiAssessmentLogic): boolean {
        if (!currentLogic || !Object.keys(currentLogic)?.length) return true;
        for (let questionId of Object.keys(currentLogic)) {
            if (currentLogic[questionId] && (!questionId || questionId === "undefined")) {
                return false;
            }
            const questionLogic = currentLogic[questionId];
            if (!questionLogic?.conditions?.length) {
                return false;
            }

            for (let i=0; i<questionLogic?.conditions?.length; i++) {
                const condition = questionLogic.conditions[i];
                if (!condition || !condition?.['questionId'] || !condition?.['questionValue']) {
                    return false;
                }
                if (condition && this.isDropdownOption(condition['questionValue']) && !Object.keys(condition['questionValue'])?.length) {
                    return false;
                }
                if (condition && Array.isArray(condition['questionValue']) && !condition['questionValue'].length) {
                    return false;
                }
            } 
        }
        return true;
    }

    // Remove this after migration
    migrateOldLogicSchema(sections: AiAssessmentsFormControl[]): string {
        if (!sections?.length) return null;
        try {
            let updatedLogic: AiAssessmentLogic = {};
            const customAssessmentFields = this.customFieldsQuery.getCustomFieldsByType(FieldUsedInEnum.Assessment);

            for (let section of sections) {
                if (section.controls?.length) {
                    for (let control of section.controls) {
                        if (!!control.logic) {
                            const questionRef = this.getQuestionRefForMigration(sections, control.logic.sectionName, control.logic.questionName, control.logic.questionId);
                            if (questionRef) {
                                let questionValue = Array.isArray(control.logic.questionValue) ? control.logic.questionValue : control.logic.questionValue.split(',') ?? [];                                
                                questionValue = questionValue.map(val => {
                                    const selectedOption = questionRef.question?.options?.find(option => option.id === val || option.value === val);
                                    let value = (selectedOption && this.isDropdownOption(selectedOption) ? selectedOption.value : selectedOption) ?? val;
                                    if (customAssessmentFields?.length) {
                                        const customFieldValue = customAssessmentFields.find(field => field.id === value);
                                        if (!!customFieldValue?.name) return customFieldValue.name;
                                    }
                                    return value;
                                });
    
                                updatedLogic[this.getSectionControlCombinedId(this.generateSectionUuid(section), this.generateControlUuid(control))] = {
                                    "conditions": [
                                        {
                                            "type": control.logic.type,
                                            "questionId": questionRef.questionId,
                                            "questionValue": questionValue,
                                        },
                                        null,
                                        null
                                    ]
                                };
                            }
                        }
                    }
                }
            }

            return Object.keys(updatedLogic).length ? 
                this.stringifyAiAssessmentLogic(updatedLogic) : 
                null;
        }
        catch (err) {
            console.error('Could not parse instance logic');
            return null;
        }
    }

    private isControlTypeAndNameIsLogicSupported(control: AiAssessmentsFormControl): boolean {
        switch (control.type) {
            case AiAssessmentsFormControlTypeEnum.Dropdown:
                return true;
            case AiAssessmentsFormControlTypeEnum.CustomComponent: {
                if (this.featureFlagQuery.getFlag(FeatureFlags.DevConditionsOnMineQuestions)) {
                    return [
                        AiAssessmentCustomComponentEnum.BusinessUnit,
                        AiAssessmentCustomComponentEnum.DataRole,
                        AiAssessmentCustomComponentEnum.DataSources,
                        AiAssessmentCustomComponentEnum.DataSubjects,
                        AiAssessmentCustomComponentEnum.DataTypes,
                        AiAssessmentCustomComponentEnum.DpiaNeeded,
                        AiAssessmentCustomComponentEnum.LegalBasis,
                        AiAssessmentCustomComponentEnum.PartiesWithAccess,
                        AiAssessmentCustomComponentEnum.Risks,
                        AiAssessmentCustomComponentEnum.TransferMechanism
                    ].includes(control.name as AiAssessmentCustomComponentEnum);
                }
                return false;
            }
            default: 
                return false;
        }
    }

    // Remove this after migration
    private getQuestionRefForMigration(sections: AiAssessmentsFormControl[], sectionName?: string, questionName?: string, questionId?: string): QuestionRefMigration {
        if (!sections?.length) return null;
        try {
            for (let section of sections) {
                if (!sectionName || (!!sectionName && section.name === sectionName)) {
                    for (let control of section.controls) {
                        if ((!!questionName && control.name === questionName) || (!!questionId && control.id === questionId)) {
                            return { 
                                question: control, 
                                questionId: this.getSectionControlCombinedId(this.generateSectionUuid(section), this.generateControlUuid(control)) 
                            };
                        }
                    }
                }
            }
            return null;
        }
        catch (err) {
            console.error('Could not parse instance logic');
            return null;
        }
    }
}