<app-confetti></app-confetti>

<!-- Add header for onboarding pages that use Website layout -->
<app-header-website *ngIf="showHeader" class="header u-display-block"></app-header-website>

<mine-snackbar class="snackbar"></mine-snackbar>

<app-elastic-sidenav *ngIf="isVerified && !showHeader && showSidenav" 
	class="sidenav u-display-block" [role]="role">
</app-elastic-sidenav>

<mineos-chatbot *ngIf="showMineChatbot()"></mineos-chatbot>

<google-copilot *ngIf="googleCopilotFF"></google-copilot>

<main class="main" 
	[ngClass]="{'main--padding': !showHeader && !disablePadding, 
				'main--onboarding-bg': showOnboardingBackground,
				'main--shrink': shrink && !disablePadding,
				'main--full-width-content': fullWidthMainContent,
				'main--shrink-full-width-content': fullWidthMainContent && shrink && !disablePadding }">

	<router-outlet></router-outlet>

</main>