import {Observable, filter, first, switchMap, map, firstValueFrom, shareReplay} from 'rxjs';
import { NgModule, APP_INITIALIZER, ErrorHandler, Injector } from '@angular/core';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { RouterModule, UrlSerializer } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';

// HTTP INTERCEPTORS  //
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpLoggerInterceptor } from './api/http-logger.interceptor';
import { HttpErrorsInterceptor } from './api/http-errors.interceptor';
import { HttpHeadersInterceptor } from './api/http-header.interceptor';

// APP //
import { environment } from '../environments/environment';
import { OldRoutesRoutingModule } from './old-routes-routing.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// MODULES //
import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { MineosChatbotModule } from './mineos-chatbot/mineos-chatbot.module';
import { ContentPipeModule } from './services/content/content-pipe.module';
import { CsvConvertorModule } from './csv-convertor/csv-convertor.module';
import { E2EModule } from '../../cypress/support/e2e.module';

// SHARED //
import { ConvertPiiTypeToDataTypePipe } from './shared/pipes/convert-pii-type-to-data-type.pipe';
import { LowerCaseFirstLetterPipe } from './shared/pipes/lower-case-first-letter.pipe';
import { MineSnackbarComponent } from './shared/mine-snackbar/mine-snackbar.component';
import { CamelCaseWithSpacesPipe } from './shared/pipes/camel-case-with-spaces.pipe';
import { SplitUpperCasePipe } from './shared/pipes/split-upper-case.pipe';
import { SentenceCasePipe } from './shared/pipes/sentence-case.pipe';
import { HtmlDecodePipe } from './shared/pipes/html-decode.pipe';

// SERVICES //
import { JL } from 'jsnlog';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { RoleEnum } from './core/models/permission-role.enum';
import { BootstrapService } from './services/bootstrap.service';
import { GlobalErrorHandler } from './services/global-error-handler';
import { FeatureFlagQuery } from './feature-flag/state/feature-flag.query';
import { FeatureFlags } from './api/models/profile/profile-feature-flags.enum';
import { CustomUrlSerializer } from './services/custom-url-serializer.service';
import { CompanySettingsService } from './company-settings/state/company-settings.service';
import { FrameworksService } from './company-settings/state/frameworks/frameworks.service';
import { CustomViewsService } from './custom-views/state/custom-views.service';
import { DataTypesService } from './data-types/services/data-types.service';
import { ProfileService } from './profile/state/profile.service';
import { PermissionsService } from './auth/permissions.service';
import { PusherService } from './mineos-chatbot/pusher.service';
import { ProfileQuery } from './profile/state/profile.query';
import { ChatService } from './chat/chat.service';
import { AuthService } from './auth/auth.service';
import { DsrPrivacyRightsService } from "./dsr/dsr-setup/dsr-privacy-rights/services/dsr-privacy-rights.service";
import { IntegrationIpaasService } from "./systems/services/integration-ipaas.service";
import { RegionsService } from './services/regions/regions.service';
import { LocaleCodeToDisplayNamePipe } from './shared/pipes/locale-code-to-display-name.pipe';
import { ProfileFeatureFlags } from "./api/models/profile/profile-feature-flags";
import { DsrTemplatesService } from "./dsr/dsr-setup/dsr-templates/services/dsr-templates.service";
import { FEATURE_FLAGS_API_SERVICE } from './feature-flag/feature-flag-injection-token';
import { USER_PROPERTIES_SERVICE } from './analytics/user-properties-injection-token';
import { ApiClientFeaturesService } from './api/api-client-features.service';
import { UserPropertiesService } from './analytics/user-properties.service';
import { ApiClientSystemsService } from './api/api-client-systems.service';
import { API_CLIENT_COMPANY_SETTINGS_SERVICE } from './api/injectors/api-client-company-settings.token';
import { ApiClientCompanySettingsService } from './api/api-client-company-settings.service';
import { API_CLIENT_SYSTEMS_SERVICE } from './api/injectors/api-client-systems-service.token';
import { ApiClientAiAssessmentsService } from './api/api-client-ai-assessments.service';
import { API_CLIENT_ASSESSMENTS_SERVICE } from './api/injectors/api-client-ai-assessments.token';
import { API_CLIENT_RISKS_SERVICE } from './api/injectors/api-client-risks.token';
import { API_CLIENT_LABELS_SERVICE } from './api/injectors/api-client-labels.token';
import { ApiClientRisksService } from './api/api-client-risks.service';
import { API_CLIENT_EMPLOYEES_SERVICE } from './api/injectors/api-client-employees.token';
import { ApiClientEmployeesService } from './api/api-client-employees.service';
import { GoogleCopilotComponent } from './shared/google-copilot/google-copilot.component';
import { ApiClientLabelsService } from './api/api-client-labels.service';

function initApp(bootstrapService: BootstrapService): () => Observable<void> {
	return () => bootstrapService.preLoad();
}

const pipes = [
	DatePipe,
	TitleCasePipe,
	HtmlDecodePipe,
	SentenceCasePipe,
	SplitUpperCasePipe,
	CamelCaseWithSpacesPipe,
	LowerCaseFirstLetterPipe,
	ConvertPiiTypeToDataTypePipe,
	LocaleCodeToDisplayNamePipe
];

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		[...pipes],
		CoreModule,
		CommonModule,
		BrowserModule,
		ReactiveFormsModule,
		ContentPipeModule,
		RouterModule,
		AuthModule,
		MineSnackbarComponent,
		OldRoutesRoutingModule,
		CsvConvertorModule,
		MonacoEditorModule.forRoot(),
		environment.production ? [] : AkitaNgDevtools.forRoot(),
		MineosChatbotModule,
		E2EModule,
		GoogleCopilotComponent,
		AppRoutingModule,
	],
	providers: [
		[...pipes],
		{ provide: 'JSNLOG', useValue: JL },
		{ provide: APP_INITIALIZER, useFactory: initApp, deps: [BootstrapService], multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpLoggerInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorsInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpHeadersInterceptor, multi: true },
		{ provide: FEATURE_FLAGS_API_SERVICE, useClass: ApiClientFeaturesService },
		{ provide: USER_PROPERTIES_SERVICE, useClass: UserPropertiesService },
		{ provide: API_CLIENT_SYSTEMS_SERVICE, useClass: ApiClientSystemsService },
		{ provide: API_CLIENT_COMPANY_SETTINGS_SERVICE, useClass: ApiClientCompanySettingsService },
		{ provide: API_CLIENT_ASSESSMENTS_SERVICE, useClass: ApiClientAiAssessmentsService },
		{ provide: API_CLIENT_RISKS_SERVICE, useClass: ApiClientRisksService },
		{ provide: API_CLIENT_LABELS_SERVICE, useClass: ApiClientLabelsService },
		{ provide: API_CLIENT_EMPLOYEES_SERVICE, useClass: ApiClientEmployeesService },
		{ provide: ErrorHandler, useClass: GlobalErrorHandler, },
		{ provide: UrlSerializer, useClass: CustomUrlSerializer },

	],
	bootstrap: [AppComponent]
})
export class AppModule {
	static injector: Injector;

	constructor (injector: Injector) {
		AppModule.injector = injector;
		return initModule(injector);
	}
}
export async function initModule(injector: Injector): Promise<void> {
	const authService = injector.get(AuthService);
	const featureFlagQuery = injector.get(FeatureFlagQuery);
	const permissionsService = injector.get(PermissionsService);
	const regionsService = injector.get(RegionsService);

	try {
		const isLoggedIn$ = authService.isLoggedIn$.pipe(
			filter(res => res),
		);

		await firstValueFrom(isLoggedIn$);
		await firstValueFrom(injector.get(ProfileService).getUserProfile());

        const featureFlagsLoading$ = featureFlagQuery.selectLoading().pipe(filter(loading => !loading), shareReplay());

		const chatService$ = featureFlagsLoading$.pipe(
			switchMap(() => featureFlagQuery.selectFlag(FeatureFlags.DevMineOSChatbot)),
			first(),
			map(flag => flag ? injector.get(PusherService).init() : injector.get(ChatService).init()),
		);

		let apis: Promise<void | boolean | Observable<void | boolean>>[] = [firstValueFrom(chatService$)];
		const userRole = await firstValueFrom(injector.get(ProfileQuery).selectAgentRole());
		
		if (!!userRole && userRole !== RoleEnum.Unknown) {
			apis = apis.concat(firstValueFrom(injector.get(DataTypesService).init()));
			const selectCustomViewsAllowed = permissionsService.selectIsAllowed([RoleEnum.Admin, RoleEnum.Agent, RoleEnum.DsrAgent, RoleEnum.DsrManager, RoleEnum.InventoryManager]);
			const selectCompanySettingsAllowed = permissionsService.selectIsAllowed([RoleEnum.Admin, RoleEnum.Agent, RoleEnum.DsrManager, RoleEnum.InventoryManager]);
			const isCustomViewsPermitted = await firstValueFrom(selectCustomViewsAllowed);
			const isCompanySettingsPermitted = await firstValueFrom(selectCompanySettingsAllowed);
			if (isCustomViewsPermitted) {
				apis = apis.concat([
					firstValueFrom(injector.get(CustomViewsService).init()),
				]);
			}

			if (isCompanySettingsPermitted) {
				apis = apis.concat([
					firstValueFrom(injector.get(CompanySettingsService).init()),
				]);
			}

            const privacyRightsData$ = injector.get(DsrPrivacyRightsService).initRights();

            apis = apis.concat(firstValueFrom(privacyRightsData$));

            const multiDataInit$ = featureFlagsLoading$.pipe(
                switchMap(() => featureFlagQuery.selectMultipleFlags([FeatureFlags.IPaasIntegration])),
				first(),
				map(flags => initMultiDataByFFs(flags)),
            );

            apis = apis.concat(firstValueFrom(multiDataInit$));

			apis = apis.concat(firstValueFrom(regionsService.getRegions()));
			AppModule.injector.get(DsrTemplatesService).initTemplates();

			await Promise.all(apis);
			
			if (isCustomViewsPermitted) {
				await firstValueFrom(injector.get(FrameworksService).init());
			}
		}
		else {
			await Promise.all(apis);
		}
	}
	catch (e) {
		console.error(e);
	}
}

function initMultiDataByFFs(flags: Partial<ProfileFeatureFlags>): void {
    if (flags[FeatureFlags.IPaasIntegration]) {
        AppModule.injector.get(IntegrationIpaasService).init();
    }
}