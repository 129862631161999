import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize, first, tap } from 'rxjs';

import { RoutesManager } from '../shared/models/routes.interfaces';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { CollaborationTokenService } from './services/collaboration-token.service';
import { CollaborationTokenStoreService } from './services/collaboration-token-store.service';

@Component({
  standalone: true,
  selector: 'collaboration-logout',
  template: '',
  imports: [ContentPipeModule],
})
export class CollaborationLogoutComponent implements OnInit {

    constructor(
        private collaborationTokenService: CollaborationTokenService,
        private collaborationTokenStore: CollaborationTokenStoreService,
        private activatedRoute: ActivatedRoute,
        private contentPipe: ContentPipe
    ) { }
    
    private redirectTo: string = `${window.location.origin}/${RoutesManager.collaboration}/${RoutesManager.collaborationExpiredLink}`;

    private title: string = this.contentPipe.transform('common.expiredLinkTitle');

    private text: string = '';

    ngOnInit(): void {
        this.activatedRoute.queryParams.pipe(
            first(),
            tap(params => this.handleQueryParams(params)),
            finalize(() => this.finalize())
        )
        .subscribe();
    }

    private handleQueryParams(params: any): void {
        if (params['redirectTo']) {
            this.redirectTo = params['redirectTo'];

            const assessmentId = this.collaborationTokenStore.getAssessmentId();

            if(assessmentId) {
                this.redirectTo = `${this.redirectTo}#assessmentId=${assessmentId}`;
            }
        }
        if (params['title']) {
            this.title = params['title'];
            this.collaborationTokenStore.setLogoutTitle(this.title);
        }
        if (params['text']) {
            this.text = params['text'];
            this.collaborationTokenStore.setLogoutText(this.text);
        }
    }

    private finalize(): void {
        this.collaborationTokenService.logout(this.redirectTo);
    }
}
