import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ApiClientTeammatesService } from 'src/app/api/api-client-teammates.service';
import { ProfileAgent } from 'src/app/api/models/profile/profile-agent';
import { ProfileStatus } from 'src/app/api/models/profile/profile-agent.enum';
import { InviteMemberResponse, ProfileInviteMember } from 'src/app/api/models/profile/profile-invite-member.interface';
import { EditTeammate } from 'src/app/api/models/teammates/teammates.interface';
import { ProfileService } from 'src/app/profile/state/profile.service';
import { CompanyGroupsService } from '../groups/services/groups.service';

@Injectable({
  providedIn: 'root'
})
export class TeammatesService {

  constructor(
    private apiClientTeammatesService: ApiClientTeammatesService,
    private profileService: ProfileService,
    private groupsService: CompanyGroupsService
  ) { }

  // userInfo can be either the user's ID or the user's email
  deactivateTeammate(userInfo: string): Observable<void> {
    return this.apiClientTeammatesService.deactivateTeammate(userInfo).pipe(
      tap(() => this.profileService.updateStatusInStore(userInfo, ProfileStatus.Inactive)),
    );
  }

  activateTeammate(userId: string): Observable<void> {
    return this.apiClientTeammatesService.activateTeammate(userId).pipe(
      tap(() => this.profileService.updateStatusInStore(userId, ProfileStatus.Active)),
    );
  }

  editTeammate(userId: string, editTeammate: EditTeammate): Observable<void> {
    return this.apiClientTeammatesService.editTeammate(userId, editTeammate).pipe(
      tap(() => this.profileService.updateRoleInStore(userId, editTeammate.role)),
      tap(() => this.profileService.updateAgentDepartmentInStore(userId, editTeammate.department)),
    );
  }

  editTeammateV2(userId: string, editTeammate: Partial<EditTeammate>): Observable<ProfileAgent> {
    return this.apiClientTeammatesService.editTeammateV2(userId, editTeammate).pipe(
      tap(agent => this.profileService.updateAgentInStore(agent)),
      tap(() => editTeammate.groupIds ? this.groupsService.updateMemberInGroups(userId, editTeammate.groupIds) : null)
    );
  }

  sendInvite(member: ProfileInviteMember): Observable<InviteMemberResponse> {
		return this.apiClientTeammatesService.inviteTeammate(member);
	}

  deleteInvitationServer(agentEmail: string): Observable<void> {
		return this.apiClientTeammatesService.deleteInvitation(agentEmail);
	}
}
