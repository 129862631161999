import { Location } from '@angular/common';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { Router, NavigationEnd, Scroll } from '@angular/router';
import { BehaviorSubject, filter, map, merge, share, tap } from 'rxjs';
import { LoggerService } from '../logger/logger.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
	providedIn: 'root'
})
export class RouterEventsListenerService {
	private readonly loggerName: string = 'RouterEventsListenerService';
	private history: string[] = [];
	
	private url = new BehaviorSubject<string>('');
	url$ = this.url.asObservable();

	private previousUrl = new BehaviorSubject<string>('');
	previousUrl$ = this.previousUrl.asObservable();

	private scrollPosition = new BehaviorSubject<[number, number]>([0, 0]);
	scrollPosition$ = this.scrollPosition.asObservable();

	private destroyRef = inject(DestroyRef);

	constructor(private router: Router,
				private location: Location,
				private logger: LoggerService) {

		this.listen();
	}

	private listen(): void {
		this.logger.debug(this.loggerName, 'listen()');
		const router$ = this.router.events.pipe(share());
		
		const end$ = router$.pipe(
			filter(event => event instanceof NavigationEnd),
			map((event: NavigationEnd) => event.urlAfterRedirects),
			tap((url) => this.handleNavigationEnd(url))
		);

		const scroll$ = router$.pipe(
			filter(event => event instanceof Scroll),
			map((event: Scroll) => event.position),
			tap(position => this.scrollPosition.next(position))
		);

		merge(end$, scroll$).pipe(
			takeUntilDestroyed(this.destroyRef)
		)
		.subscribe();
	}

	private handleNavigationEnd(urlAfterRedirects: string): void {
		const last = this.url.getValue();
		this.previousUrl.next(last);
		this.history.push(last);
		this.url.next(urlAfterRedirects);
	}

	back(url: string = null): void {
		if (!!url) {
			this.router.navigateByUrl(url);
			return;
		}

		this.history.pop();

		if (this.history.length) {
			this.location.back();
		}
		else {
			this.router.navigate(['/']);
		}
	}
}
