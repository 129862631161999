import { Pipe, PipeTransform } from '@angular/core';
import { ContentPipe } from 'src/app/services/content/content.pipe';

@Pipe({
  standalone: true,
  name: 'localeCodeToDisplayName'
})
export class LocaleCodeToDisplayNamePipe implements PipeTransform {
 
  localeMap = new Map<string, string>();

  constructor(
    private contentPipe: ContentPipe,
  ) {
    const supportedLanguages = this.contentPipe.transform('dsr-localization.languages');
    supportedLanguages.forEach(lang => this.localeMap.set(lang.localizationCode, lang.nameForDisplay));
  }

  transform(code: string): string {
      return this.localeMap.get(code);
  }

}
