import { computed, Injectable, Signal } from "@angular/core";
import { CompanyGroupsStore } from "./groups.store";
import { Observable } from "rxjs";
import { CompanyGroup } from "src/app/api/models/company-settings/groups.interface";
import { MineSort } from "src/app/shared/mine-sort/mine-sort.interface";
import { SortDirectionEnum } from "src/app/shared/sort-direction.enum";
import { CompanyGroupsTableColumns } from "../groups.enum";

@Injectable({ 
	providedIn: 'root' 
})
export class CompanyGroupsQuery {

    constructor(
        private store: CompanyGroupsStore,
    ) { }

    setLoading(loading: boolean): void {
        this.store.set({ 'loading': loading });
    }

    getLoading(): Signal<boolean | undefined> {
        return this.store.signal('loading');
    }

    selectLoading(): Observable<boolean | undefined> {
        return this.store.select('loading');
    }
    
    setGroups(groups: CompanyGroup[]): void {
        const companyGroupsMap = new Map();
        groups.forEach(group => companyGroupsMap.set(group.id, group));

        this.store.set({ 'groups': companyGroupsMap });
    }

    getAllGroupsAndSort(sort: MineSort): Signal<Map<string, CompanyGroup>> {
        const groupsSignal = this.getGroups();
        
        return computed(() => {
            const groups = groupsSignal();
            if (!groups) return new Map();
    
            const sortedArray = Array.from(groups.entries()).sort((a, b) => {
                const groupA = a[1]; //the group object
                const groupB = b[1];
    
                if (sort.active === CompanyGroupsTableColumns.Name) {
                    return this.sortByName(groupA.name, groupB.name, sort.direction);
                } 
                if (sort.active === CompanyGroupsTableColumns.Members) {
                    return this.sortByNumber(groupA.members?.length, groupB.members?.length, sort.direction);
                }
                return 0;
            });
    
            return new Map(sortedArray);
        });
    }
    
    private sortByName(name1: string, name2: string, direction: SortDirectionEnum): number {
        return direction === SortDirectionEnum.Asc
            ? name1.localeCompare(name2) 
            : name2.localeCompare(name1);
    }
    
    private sortByNumber(num1: number = 0, num2: number = 0, direction: SortDirectionEnum): number {
        return direction === SortDirectionEnum.Asc
            ? num1 - num2 
            : num2 - num1;
    }
    getGroups(): Signal<Map<string, CompanyGroup>> {
        return this.store.signal('groups');
    }

    selectGroups(): Observable<Map<string, CompanyGroup>> {
        return this.store.select('groups');
    }

    updateGroups(newGroups: Map<string, CompanyGroup>): void {
        this.store.set({'groups': newGroups});
    }

    getGroup(groupId: string): CompanyGroup {
        const groups = this.store.signal('groups')();
        return groups.get(groupId);
    }

    updateGroup(group: CompanyGroup): void {
        const currentGroups = this.store.get('groups');

        if (currentGroups.get(group.id)) {
            const newGroups = new Map(currentGroups);
            newGroups.set(group.id, group);
            
            this.store.set({'groups': newGroups});
        }
    }

    addGroup(group: CompanyGroup): void {
        const currentGroups = this.store.get('groups');
  
        const newGroups = new Map(currentGroups);
        newGroups.set(group.id, group);
        
        this.store.set({'groups': newGroups});

    }

    updateActiveGroupId(groupId: string): void {
        this.store.set({'activeGroupId': groupId});
    }

    getActiveGroupId(): Signal<string> {
        return this.store.signal('activeGroupId');
    }
}