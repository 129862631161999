export enum RequestsFilteringGroups {
    Status = 'status',
    Type = 'type',
    Source = 'source',
    Domain = 'domain',
    Country = 'country',
    ReadStatus = 'ReadStatus',
    Rights = 'rights',
    MinCreatedAt = 'minCreatedAt',
    Assignee = 'assignee'
}

export enum ReadStatusFilter {
    Unread = 'Unread'
}

export enum AssigneeFilter {
    None = 'none'
}