
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SystemSchemaState, SystemSchemaStore } from './system-schema.store';
import { FilterCategory, FilterOption } from 'src/app/core/models/filtering.interface';
import { MineSort } from 'src/app/shared/mine-sort/mine-sort.interface';
import { Observable } from 'rxjs';
import { PiiSchemaItem } from 'src/app/api/models/pii-classifier/pii-classifier.interface';
import { PiiSystemSchemaColumnsEnum, PiiSystemSchemaFiltersEnum, PiiSystemSchemaViolationOptions } from '../../pii-system-schema/schema-table-header/schema-table-header.enum';
import { DataTypesQuery } from 'src/app/data-types/state/data-types.query';
import { ConvertPiiTypeToDataTypePipe } from 'src/app/shared/pipes/convert-pii-type-to-data-type.pipe';
import { SortDirectionEnum } from 'src/app/shared/sort-direction.enum';

@Injectable({ 
	providedIn: 'root' 
})
export class SystemSchemaQuery extends QueryEntity<SystemSchemaState> {

	constructor(
		protected store: SystemSchemaStore,
		private dataTypesQuery: DataTypesQuery,
		private convertPiiTypeToDataTypePipe: ConvertPiiTypeToDataTypePipe,
	) {
		super(store);
	}

	selectSchemasByFilters(activeFilters: FilterCategory[], searchTerm: string, sort: MineSort): Observable<PiiSchemaItem[]> {
		const selectedFrameworks = activeFilters.find(f => f.id === PiiSystemSchemaFiltersEnum.Frameworks)?.options ?? [];
		const selectedDataTypes = activeFilters.find(f => f.id === PiiSystemSchemaFiltersEnum.DataTypes)?.options ?? [];
		const selectedViolations = activeFilters.find(f => f.id === PiiSystemSchemaFiltersEnum.Violations)?.options ?? [];

		// has filters or search term
		return this.selectAll({
			filterBy: [
				entity => this.filterByFrameworks(entity, selectedFrameworks) && // filter by frameworks
						  this.filterByDataTypes(entity.piiDataType, selectedDataTypes) && // filter by data types
						  this.filterByViolations(entity, selectedViolations) && // filter by violations
						  this.filterBySearchTerm(entity.piiDataType, searchTerm)
			],
			sortBy: (s1, s2) => sort?.active === PiiSystemSchemaColumnsEnum.DataType ? this.sortByDataType(s1, s2, sort.direction) :
								sort?.active === PiiSystemSchemaColumnsEnum.MatchesFound ? this.sortByMatchesFound(s1, s2, sort.direction) : 1,
		});
	}

	private filterByFrameworks(schemaItem: PiiSchemaItem, selectedFrameworks: FilterOption[]): boolean {
		if (selectedFrameworks.length === 0) {
			return true;
		}

		const selectedFrameworksIds = new Set<string>(selectedFrameworks.map(f => f.id));
		return !!schemaItem.dataType.frameworks.some(f => selectedFrameworksIds.has(f.id));
	}

	private filterByDataTypes(piiDataType: string, selectedDataTypes: FilterOption[]): boolean {
		if (selectedDataTypes.length === 0) {
			return true;
		}

		const selectedDataTypesIds = new Set<string>(selectedDataTypes.map(f => f.id));
		return selectedDataTypesIds.has(piiDataType);
	}

	private filterByViolations(schemaItem: PiiSchemaItem, selectedViolations: FilterOption[]): boolean {
		if (selectedViolations.length === 0) {
			return true;
		}

		const selectedViolationsIds = new Set<string>(selectedViolations.map(f => f.id));
		const isViolated = schemaItem.policyViolation;
		return selectedViolationsIds.has(isViolated ? PiiSystemSchemaViolationOptions.ViolationFound : PiiSystemSchemaViolationOptions.NoViolation);
	}

	private filterBySearchTerm(piiDataType: string, searchTerm: string): boolean {
		if (searchTerm === '') {
			return true;
		}

		const dataType = this.dataTypesQuery.getEntity(this.convertPiiTypeToDataTypePipe.transform(piiDataType));
		return dataType.name.toLowerCase().startsWith(searchTerm.toLowerCase());
	}

	private sortByDataType(s1: PiiSchemaItem, s2: PiiSchemaItem, direction: SortDirectionEnum): number {
		const getDataTypeName = (piiDataType: string) => 
			this.dataTypesQuery.getEntity(this.convertPiiTypeToDataTypePipe.transform(piiDataType)).name?.toLowerCase();
	  
		const name1 = getDataTypeName(s1.piiDataType);
		const name2 = getDataTypeName(s2.piiDataType);
	  
		return direction === SortDirectionEnum.Asc ?
			name1.localeCompare(name2) :
		  	name2.localeCompare(name1);
	  }

	private sortByMatchesFound(s1: PiiSchemaItem, s2: PiiSchemaItem, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ?
			s1.totalMatches - s2.totalMatches :
			s2.totalMatches - s1.totalMatches;
	}

	selectCountPolicyViolation(): Observable<number> {
		return this.selectCount(({ policyViolation }) => policyViolation);
	}
}
