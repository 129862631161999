import { Pipe, PipeTransform } from '@angular/core';
import { v5 as uuidv5 } from 'uuid';

@Pipe({
    standalone: true,
    name: 'aiAssessmentUuid'
})
export class AiAssessmentUuidPipe implements PipeTransform {
  // Default namespace for UUIDv5
  private readonly NAMESPACE = 'e6f8f5e7-4e75-4323-9c65-d91b8f2cdbb7';

  transform(value: string | number): string {
    if (!value) {
      return '';
    }
    // Generate the UUID using the provided string and a predefined namespace (UUIDv5)
    return uuidv5(''+value, this.NAMESPACE);
  }
}
