import { CanMatchFn, Route, Router, UrlSegment, UrlTree } from "@angular/router";
import {  filter, map, Observable, tap } from "rxjs";
import { inject } from "@angular/core";

import { RoutesManager } from "../shared/models/routes.interfaces";
import { ProfileQuery } from "../profile/state/profile.query";
import { LoggerService } from "../logger/logger.service";
import { AuthService } from "./auth.service";

const loggerName: string = 'AuthGuard';

const AuthGuardFn = (route: Route, segments: UrlSegment[]): boolean | UrlTree => {
    const authService = inject(AuthService);
    return authService.isAuthenticated() ? true : redirectUnauthorizedUsers();
};

const redirectUnauthorizedUsers = (): UrlTree => {
    const router = inject(Router);
    return router.parseUrl(RoutesManager.login);
};

const CompanyVerificationGuardFn = (route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> => {
    const router = inject(Router);
    const logger = inject(LoggerService);
    const profileQuery = inject(ProfileQuery);

    return profileQuery.select('verifiedToCompany').pipe(
        filter(verified => verified !== undefined),
        tap(response => logger.debug(loggerName, `Verified = ${response}`)),
        map(response => response ? true : router.parseUrl(`${RoutesManager.onboarding}/${RoutesManager.onboarding_user_without_company}`))
    );
};

export const AuthGuard: CanMatchFn = AuthGuardFn;
export const CompanyVerificationGuard: CanMatchFn = CompanyVerificationGuardFn;