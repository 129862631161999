import { Location } from '@angular/common';
import { Injectable, Type } from '@angular/core';
import { DialogConfig, DialogRef, DialogService } from '@ngneat/dialog';

import { ProfileMemberDialogComponent } from '../profile/profile-member-dialog/profile-member-dialog.component';
import { PreviewDialogData } from '../dsr/dsr-ticket/dsr-ticket-content/dsr-ticket-processing/dialogs/integrations-preview-dialog/integration-preview-dialog-data.interface';
import { IntegrationsPreviewDialogComponent } from '../dsr/dsr-ticket/dsr-ticket-content/dsr-ticket-processing/dialogs/integrations-preview-dialog/integrations-preview-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class DialogsManagerService {
	width: string = '620px';

	constructor(
		public dialog: DialogService,
		private location: Location,
	) {
		this.closeDialogOnUrlChange();
	}

	private closeDialogOnUrlChange(): void {
		this.location.onUrlChange(() => {
			if (this.dialog.dialogs.length > 0) {
				this.dialog.closeAll();
			};
		});
	}

	private getDialogConfig(width: string, data?: any, enableClose: boolean = true): Partial<DialogConfig> {
		const config = {
			enableClose: enableClose,
			backdrop: true,
			windowClass: 'app-general-dialog-style',
			width: width,
			height: 'auto',
			minHeight: 'auto',
			closeOnNavigation: true,
			data: data,
		};
		return config;
	}

	openMemberComponent(comp: Type<ProfileMemberDialogComponent>): DialogRef {
		const width = '403px';
		return this.open(comp, this.getDialogConfig(width));
	}

	openPreviewIntegration(data: PreviewDialogData): DialogRef {
		const width = '940px';
		return this.open(IntegrationsPreviewDialogComponent, this.getDialogConfig(width, data));
	}

	openDialogCommon<T>(component: T, data: any = null, width: string = this.width, enableClose?: boolean): DialogRef {
		return this.open(component, this.getDialogConfig(width, data, enableClose));
	}

	private open(component: any, config: Partial<DialogConfig>): DialogRef {
		const htmlElement = document.querySelector('html');
		htmlElement.style.overflowX = 'initial';

		try {
			const dialogRef = this.dialog.open(component, config);

			dialogRef?.afterClosed$.subscribe(() => {
				htmlElement.style.overflowX = '';
			});

			return dialogRef;
		} catch (e) {
			console.error('Error opening dialog:', e);
			return undefined;
		}
	}
}
