import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '../onboarding/models/company.interface';
import { HttpClientHelperService } from './http-client-helper.service';
import { Dpo } from './models/onboarding/dpo';

@Injectable({
  	providedIn: 'root'
})
export class ApiClientOnboardingService {

	private readonly controller = 'onboarding';

  	constructor(private httpClientHelper: HttpClientHelperService) { }
  	
	getDpoFromDomain(): Observable<Dpo> {
		return this.httpClientHelper.invokeGetAuth<Dpo>(`${this.controller}/getDpo`);
  	}

	//for connect with mine new flow  
	createCompanyV2(companyData: Company): Observable<Dpo> {
		return this.httpClientHelper.invokePostAuth<Dpo>(`${this.controller}/CreateCompany`, companyData);
  	}

	setDomainData(company: Company): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/SendVerificationEmail`, company);
	}

}
