import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Auth0Client, createAuth0Client } from "@auth0/auth0-spa-js";

import { environment } from "src/environments/environment";
import { ContentPipe } from "src/app/services/content/content.pipe";
import { RoutesManager } from "src/app/shared/models/routes.interfaces";
import { CollaborationTokenStoreService } from "./collaboration-token-store.service";

@Injectable({
    providedIn: 'root',
})
export class CollaborationTokenService {
    private readonly _redirectUri = `${window.location.origin}/${RoutesManager.collaborationAuthProxy}`;
    private readonly _audience = environment.collaboration.auth0.audience;
    private readonly _scope = 'openid profile email offline_access';
    private readonly _refreshTokenInterval = 60_000;

    private auth0TokenSub;
    private auth0: Auth0Client;
    private assessmentPageId: string;
    private agentEmail: string;
    private companyId: string;
    private companyName: string;
    private email: string;

    constructor(
        private router: Router,
        private contentPipe: ContentPipe,
        private activatedRoute: ActivatedRoute,
        private tokenStore: CollaborationTokenStoreService
    ) {}

    async initAuth0Client() {
        try {
            // Store query params
            const params = await firstValueFrom(this.activatedRoute.queryParams);
            this.handleQueryParams(params);

            if (!this.auth0) {
                this.auth0 = await createAuth0Client({
                    domain: environment.collaboration.auth0.domain,
                    clientId: environment.collaboration.auth0.clientId,
                    cacheLocation: 'localstorage',
                    useRefreshTokens: true,
                    useRefreshTokensFallback: true,
                    authorizationParams: {
                        redirect_uri: this._redirectUri,
                        audience: this._audience,
                        scope: this._scope,
                    },
                });
            }

            // Check if the redirect callback is present
            if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
                try {
                    const result = await this.auth0.handleRedirectCallback();

                    const targetRoute = result?.appState?.target;

                    const assessmentId = result?.appState?.assessmentId;

                    if(assessmentId) {
                        this.tokenStore.setAssessmentId(assessmentId);
                    }

                    if (targetRoute) {
                        this.router.navigateByUrl(targetRoute);
                    }
                    else {
                        this.navigateToExpiredLink();
                    }
                }
                catch (err) {
                    console.error(err);
                    this.navigateToExpiredLink();
                }
            }

            const isAuthenticated = await this.isAuthenticated();
            if (!isAuthenticated) {
                
                const assessmentId = this.tokenStore.getAssessmentId();

                await this.auth0.loginWithRedirect({
                    appState: {
                        target: `${window.location.origin}/${RoutesManager.collaboration}/${RoutesManager.collaborationAssessmentPage}/${assessmentId}`,
                        assessmentId
                    },
                    authorizationParams: {
                        connection: 'email',
                        login_hint: this.tokenStore.getEmail(),
                    }
                });
            }

            // Or asked to comment out for now
            // if (!this.auth0TokenSub) {
            //     this.auth0TokenSub = setInterval(async () => {
            //         await this.refreshToken();
            //     }, this._refreshTokenInterval);
            // }
        }
        catch (error) {
            console.error('Error during initAuth0Client:', error);
        }
    }

    async isAuthenticated(): Promise<boolean> {
        return await this.auth0.isAuthenticated();
    }

    private async refreshToken(): Promise<string> {
        const token = await this.auth0.getTokenSilently({
            cacheMode: 'off'
        });
        this.tokenStore.setAccessToken(token);
        return token;
    }

    async logout(returnUrl: string): Promise<void> {
        if (!this.auth0TokenSub) {
            clearInterval(this.auth0TokenSub);
        }
        return await this.auth0?.logout({
            logoutParams: {
                returnTo: returnUrl
            }
        });
    }

    async extractAccessToken(): Promise<string> {
        try {
            const hash = window.location.hash.substring(1);
            if (hash) {
                const hashParams = new URLSearchParams(hash);
                if (hashParams.get('access_token')) {
                    const accessToken = hashParams.get('access_token');
                    if (!!accessToken) {
                        this.tokenStore.setAccessToken(accessToken);
                        return accessToken;
                    }
                }
            }
            const token = await this.refreshToken();
            if (!token) {
                this.navigateToExpiredLink();
                return null;
            }
            return token;
        }
        catch (error) {
            console.error(error);
            this.navigateToExpiredLink();
            return null;
        }
    }
    
    private handleQueryParams(params: Params): void {
        if (params && Object.keys(params)?.length) {
            this.assessmentPageId = params['assessmentPageId'];
            this.agentEmail = params['agentEmail'];
            this.companyId = params['companyId'];
            this.companyName = params['companyName'];
            this.email = params['email'] ? params['email'].replace(/ /g, '+') : null;

            if (!!this.assessmentPageId) {
                this.tokenStore.setAssessmentId(this.assessmentPageId);
            }
            if (!!this.agentEmail) {
                this.tokenStore.setAgentEmail(this.agentEmail);
            }
            if (!!this.companyId) {
                this.tokenStore.setCompanyId(this.companyId);
            }
            if (!!this.companyName) {
                this.tokenStore.setCompanyName(this.companyName);
            }
            if (!!this.email) {
                this.tokenStore.setEmail(this.email);
            }
        }
    }

    navigateToAssessmentPage(): void {
        this.router.navigate([
            RoutesManager.collaboration,
            RoutesManager.collaborationAssessmentPage,
            this.tokenStore.getAssessmentId()
        ]);
    }

    navigateToExpiredLink(): void {
        this.router.navigate([RoutesManager.collaborationLogout], {
            queryParams: {
                redirectTo: `${window.location.origin}/${RoutesManager.collaboration}/${RoutesManager.collaborationExpiredLink}`,
                ...this.contentPipe.transform('ai-assessments-collaboration.collaborationAuthError') 
            }
        });
    }
}